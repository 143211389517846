import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import logo from "../img/logo_black.png";

import styles from "../css/style.module.css";
import Footer from "./components/Footer";
import Loading from "../../../components/Loading";

const Step30_Workshops = () => {
    const [user, setUser] = useState({
        salutation: "",
        firstname: "",
        lastname: "",
        company: "",
        function: "",
        email: "",
        vrMandate: ""
    });

    const theEvent = JSON.parse(sessionStorage.getItem("FUWEvent"));
    const remarks = JSON.parse(theEvent.remarks);
    const [orderId] = useState(sessionStorage.getItem("FUWOrderId"));
    const [bookedTickets, setBookedTickets] = useState([]);

    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState({});
    const [token] = useState(sessionStorage.getItem("token"));

    // fetching resources
    const { language } = useParams();

    // react hook for navigation
    let history = useHistory();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        requestResources();

    }, [language]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestOrderInformation();

    }, []);

    const requestResources = () => {
        axios
            .get(`fuw2023/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestOrderInformation = () => {
        setLoading(true);

        axios
            .get(`${baseUrl}/ShopBasket/Order/${orderId}`)
            .then((res) => {
                setBookedTickets(res.data.tickets);

                setTimeout(() => setLoading(false), 500);
            })
            .catch((error) => console.error(error.response.data));
    };

    const mapWorkshops = () => {
        let workshops = remarks["Workshops"];

        return (
            workshops &&
            workshops.length > 0 &&
            workshops.map(
                (ws, index) => (
                    <div key={ws.Id} className="mb-4">
                        <div className="row">
                            <div className="col-md-1 mt-4">
                                <b className={styles.textBlue}>{ws.Id}</b>
                            </div>
                            <div className="col-md-11 mt-4 ps-md-0">
                                <h5><b>{ws.Title}</b></h5>
                            </div>
                        </div>
                        {
                            ws.Speakers &&
                            ws.Speakers.length > 0 &&
                            ws.Speakers.map(
                                (spk, index2) => (
                                    <div key={index2 + "_speaker"} className="row mt-1">
                                        <div className="offset-md-1 col-md-11 ps-md-0">
                                            <small><b>{spk.Name}</b> {spk.Company}</small>
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                )
            )
        );
    };

    const mapTicketOptions = () => {
        let workshops = remarks["Workshops"];

        return (
            workshops &&
            workshops.length > 0 &&
            bookedTickets &&
            bookedTickets.length > 0 &&
            bookedTickets.map((bt, index) => (
                <div key={bt.id}>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <h5 className="text-uppercase">{bt.firstname} {bt.lastname}</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">{resources.translation.WorkshopsIChoose}</div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            {workshops.map(
                                (ws, index2) => (
                                    <button key={ws.Id + "_1"} className={"btn btn-light ps-4 pe-4 me-2 " + styles.workshopButton}>{index2 + 1}</button>
                                ))}
                        </div>
                    </div>

                    {remarks["NeedsContactQuestion"] &&
                        <div>
                            <div className="row">
                                <div className="col-md-12 mt-4">{resources.translation.WorkshopsNeedsContactQuestion}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    {workshops.map(
                                        (ws, index2) => (
                                            <button key={ws.Id + "_2"} className={"btn btn-light ps-4 pe-4 me-2 " + styles.workshopButton}>{index2 + 1}</button>
                                        ))}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            ))
        );
    };

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const addWorkshopsToTickets = () => {
        history.push(`/${language}/2023/fuw/confirmation`);
    }

    return (
        <div>
            {loading ? (
                <Loading alignment="center" color="#d3d3d3" bgColor="#fff" position="fixed" top="50%" left="50%" />
            ) : (
                <>
                    {resources.translation && (
                        <div className={"container pt-0 " + styles.wrapper}>
                            <div className="row mt-2">
                                <div className="col-md-4 text-center">
                                    <img src={logo} className="img-fluid" alt="FUW Logo"></img>
                                </div>
                            </div>
                            <div className="row mt-5 text-center">
                                <div className={"col-md-4"}>
                                    <p className={styles.navFUW}>{resources.translation._Buyer}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className={styles.navFUW}>{resources.translation._Tickets}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className={styles.navFUWActive}>{remarks["WorkshopsTitle"]}</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12 text-center">
                                    <h2 className={"text-uppercase " + styles.underline50}>{remarks["WorkshopsTitle"]}</h2>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 mb-4 text-center">
                                    <span className={styles.description}>
                                        {remarks["LeadText"]}
                                    </span>
                                </div>
                            </div>

                            {/* workshops */}

                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <h5 className="text-uppercase">{resources.translation._Workshops}</h5>
                                </div>
                            </div>

                            {mapWorkshops()}


                            {/* ticket options */}

                            {mapTicketOptions()}

                            {/* button next */}

                            <div className={"row mt-5"}>
                                <div className="offset-md-10 col-md-2 text-end">
                                    <button className={"btn form-control " + styles.btnDarkblue} onClick={addWorkshopsToTickets}>
                                        {resources.translation._Next}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={"container " + styles.wrapper}>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Footer language={language} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Step30_Workshops;