import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styles from "../css/style.module.css";
import Navbar from "./components/Navbar";

const PaymentForm = () => {
  const [payment, setPayment] = useState({
    currency: "CHF",
    paymentType: 0,
    deliveryType: 0,
  });

  const [show, setShow] = useState(0);
  const [orderId] = useState(sessionStorage.getItem("orderId"));
  const [token] = useState(sessionStorage.getItem("token"));
  const [completedOrder, setCompletedOrder] = useState([]);
  const [bookedTicketTypeInfo, setBookedTicketTypeInfo] = useState([]);
  const [isDevelopment, setIsDevelopment] = useState(false);
  const [errors, setErrors] = useState("");

  let baseUrl = process.env.REACT_APP_BASEURL_API;

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  useEffect(() => {
    requestCompletedOrder();
    requestFormSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  let history = useHistory();

  const requestFormSettings = () => {
    axios.get(`geologentag2022/formsettings`).then((res) => {
      setIsDevelopment(res.data.isDevelopment);
    });
  };

  const requestCompletedOrder = () => {
    axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`).then((res) => {
      setCompletedOrder(res.data.tickets);

      setPayment({...payment, deliveryType: res.data.deliveryMethodId, paymentType: res.data.paymentMethodId});
      res.data.tickets.map((t, index) =>
        requestBookedTicketTypeInfo(t.ticketTypeId, index)
      );
    });
  };

  const deleteBookedTicket = (id) => {
    let deleteBody = {
      ticketsToRemove: [id],
    };

    axios
      .delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
        headers: {
          Accept: "application/json",
        },
        data: deleteBody,
      })
      .then(() => requestCompletedOrder());
  };

  const requestBookedTicketTypeInfo = (ticketTypeId, index) => {
    axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`).then((res) => {
      setBookedTicketTypeInfo((current) => [
        ...current,
        res.data.ticketTypeInfos[0],
      ]);
      setShow(true);
    });
  };

  const changeDeliveryMethod = () => {
    axios
      .put(
        `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${payment.deliveryType}`
      )
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const changePaymentCurrency = () => {
    axios
      .put(
        `${baseUrl}/ShopBasket/Order/${orderId}/Currency/${payment.currency}`
      )
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const changePaymentType = () => {
    axios
      .put(
        `${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${payment.paymentType}`
      )
      .then(() => {
        let dataTransProviders = [1, 7, 8, 9, 10, 14];

        if (
          dataTransProviders.findIndex((d) => d === payment.paymentType) !== -1
        ) {
          requestDigitalSignature();
        } else {
          confirmOrder();
        }
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const requestDigitalSignature = () => {
    var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

    axios
      .get(baseRequestUrl)
      .then((res) => {
        let datatransFormId = "datatrans-" + new Date().getTime();
        var form = "<form class='datatrans' id='" + datatransFormId + "'></form>";

        let container = document.getElementById("datatrans-form-placeholder");

        container.innerHTML += form;

        let element = document.getElementById(datatransFormId);

        isDevelopment
          ? element.setAttribute("data-merchant-id", "1100004624")
          : element.setAttribute("data-merchant-id", res.data.merchantId);
        element.setAttribute("data-amount", res.data.amount);
        element.setAttribute("data-currency", res.data.currency);
        element.setAttribute("data-refno", res.data.referenceNumber);
        element.setAttribute("data-reqType", res.data.reqType);
        element.setAttribute(
          "data-upp-return-target",
          res.data.uppReturnTarget
        );
        element.setAttribute("data-paymentMethod", res.data.paymentMethod);
        element.setAttribute("data-sign", res.data.digitalSignature);

        for (const key in res.data.userInfo) {
          element.setAttribute(key, res.data.userInfo[key]);
        }

        for (const key in res.data.merchantSpecificParameters) {
          element.setAttribute(key, res.data.merchantSpecificParameters[key]);
        }

        // start datatrans -> call the payment form
        window.Datatrans.startPayment({
          form: "#" + datatransFormId,
        });
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const startDatatransPayment = () => {
    window.Datatrans.startPayment({
      form: "#paymentForm",
    });

    // when testing -> submitting the form would result in an error due to the invalid merchantId (current merchantId is for production only)
  };

  const confirmOrder = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const onPaymentSubmit = () => {
    changePaymentCurrency();
    changeDeliveryMethod();
    changePaymentType();
  };

  const displayBookedTickets = () => {
    return (
      <ol className={styles.listGroup}>
        {completedOrder ? (
          completedOrder.length > 0 ? (
            completedOrder.map((o, index) => (
              <li
                className={
                  styles.listGroupItem +
                  " d-flex justify-content-between align-items-start mb-3"
                }
                key={index}
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    {bookedTicketTypeInfo[index]
                      ? bookedTicketTypeInfo[index].name
                      : null}
                  </div>
                  {o.price} {o.currency}
                </div>

                <button
                  className={styles.button + " btn"}
                  onClick={() => deleteBookedTicket(o.id)}
                >
                  ×
                </button>
              </li>
            ))
          ) : (
            <div>
              <h3 className="text text-light fs-6 text-center">
                You currently have no tickets in the shop basket :(
              </h3>
            </div>
          )
        ) : null}
      </ol>
    );
  };

  // payment side validation
  var isPaymentValid = payment.paymentType !== 0 && payment.deliveryType !== 0;

  return (
    <div className={styles.pageContainer}>
      <div id="datatrans-form-placeholder"></div>
      <Navbar />
      <section className={styles.section}>
        <div className={styles.boxMain + " row"}>
          <div className={styles.firstHalf}>
            <h2 className="text text-light fs-2 fw-semibold text-center">
              Payment
            </h2>
          </div>
          <div className="container m-3 text-start col-md-12 col-lg-12">
            <h4 className="text text-light mb-3">Choose Currency</h4>
            <div className="mb-3 row">
              <div className="col-sm-8">
                <select
                  className={styles.selectNum + " salutation form-select"}
                  aria-label="Default select example"
                  name="currency"
                  value={payment.currency}
                  onChange={(e) =>
                    setPayment({ ...payment, currency: e.target.value })
                  }
                >
                  <option value="CHF">CHF</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                </select>
              </div>
            </div>
            <div className="mb-3 row">
              <h4 className="text text-light mb-3 mt-3">
                Choose a Payment Method
              </h4>
              <div>
                {completedOrder ? (
                  completedOrder.totalPrice === 0 ? (
                    <div className="form-check ps-0">
                      <input
                        className={styles.formCheckInput + " me-4"}
                        type="radio"
                        name="flexRadioDefault"
                        checked={payment.paymentType === 5 ? true : false}
                        onClick={() =>
                          setPayment({ ...payment, paymentType: 5 })
                        }
                        id="free"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Free Payment
                      </label>
                    </div>
                  ) : (
                    <div>
                      <div className="form-check ps-0">
                        <input
                          className={styles.formCheckInput + " me-4"}
                          type="radio"
                          name="flexRadioDefault"
                          checked={payment.paymentType === 1 ? true : false}
                          onClick={() =>
                            setPayment({ ...payment, paymentType: 1 })
                          }
                          id="mastercard"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Mastercard
                        </label>
                      </div>
                      <hr></hr>
                      <div className="form-check ps-0">
                        <input
                          className={styles.formCheckInput + " me-4"}
                          type="radio"
                          name="flexRadioDefault"
                          checked={payment.paymentType === 2 ? true : false}
                          onClick={() =>
                            setPayment({ ...payment, paymentType: 2 })
                          }
                          id="invoice"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Invoice
                        </label>
                      </div>
                      <hr></hr>
                      <div className="form-check ps-0">
                        <input
                          className={styles.formCheckInput + " me-4"}
                          type="radio"
                          name="flexRadioDefault"
                          checked={payment.paymentType === 15 ? true : false}
                          onClick={() =>
                            setPayment({ ...payment, paymentType: 15 })
                          }
                          id="external"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          External Payment
                        </label>
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            </div>

            <hr></hr>

            <div className="mb-3 row">
              <h4 className="text text-light mb-3 mt-3">
                Choose a Delivery Method
              </h4>
              <div>
                <div>
                  <div className="form-check ps-0">
                    <input
                      className={styles.formCheckInput + " me-4"}
                      type="radio"
                      name="flexRadioDefault1"
                      checked={payment.deliveryType === 1 ? true : false}
                      onClick={() =>
                        setPayment({ ...payment, deliveryType: 1 })
                      }
                      id="printAtHome"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Print At Home
                    </label>
                  </div>
                  <hr></hr>
                  <div className="form-check ps-0">
                    <input
                      className={styles.formCheckInput + " me-4"}
                      type="radio"
                      name="flexRadioDefault1"
                      checked={payment.deliveryType === 2 ? true : false}
                      onClick={() =>
                        setPayment({ ...payment, deliveryType: 2 })
                      }
                      id="postMail"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Post Mail
                    </label>
                  </div>
                  <hr></hr>
                  <div className="form-check ps-0">
                    <input
                      className={styles.formCheckInput + " me-4"}
                      type="radio"
                      name="flexRadioDefault1"
                      checked={payment.deliveryType === 3 ? true : false}
                      onClick={() =>
                        setPayment({ ...payment, deliveryType: 3 })
                      }
                      id="printAndHandoutManually"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Print And Handout Manually
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <hr></hr>

            <div className="mb-3 row">
              <h4 className="text text-light mb-3 mt-3">Zusammenfassung</h4>
              <div>{displayBookedTickets()}</div>
            </div>

            {errors !== "" && (
              <div className="row">
                <p className="text text-danger ps-2">{errors}*</p>
              </div>
            )}

            <div className="mb-3 container ps-0">
              <button
                className={styles.button + " btn me-3 mt-3"}
                onClick={() => {
                  history.push("/2022/LightRagaz/Form");
                }}
                id="backButton"
              >
                Zurück
              </button>
              <button
                type="submit"
                className={styles.button + " btn me-3 mt-3"}
                id="submitButton"
                disabled={!isPaymentValid}
                onClick={onPaymentSubmit}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentForm;
