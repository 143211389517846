import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import Header from "./components/Header";

const Confirmation = () => {
  let history = useHistory();

  const [resources, setResources] = useState({});
  const [orderId] = useState(sessionStorage.getItem("orderId"));
  const [organizerId, setOrganizerId] = useState();

  const [token] = useState(sessionStorage.getItem("token"));

  const [receiptFile, setReceiptFile] = useState("");
  const [ticketsFile, setTicketsFile] = useState("");

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  // fetching resources
  const { language } = useParams();

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    requestResources();
    requestFormSettings();

  }, [language]);

  const requestResources = () => {
    axios.get(`geologentag2022/resources/${language}`).then((res) => {
      setResources(res.data);
    });
  };

  const requestFormSettings = () => {
    axios.get(`geologentag2022/formsettings`).then((res) => {
      setOrganizerId(res.data.organizerId);
      confirmOrder();
    });
  };

  const onComplete = () => {
    history.push(`/${language}/2022/Geologentag/Home`);
  };  
  
  // confirm the order
  const confirmOrder = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const getReceiptOfOrder = () => {
    axios.get(`${baseUrl}/Order/${orderId}/Receipt?organizerId=${organizerId}`, {
      responseType: "blob"
    }).then((res) => {
      const data = window.URL.createObjectURL(res.data);
      var link = document.createElement('a');
      link.href = data;
      link.download="Receipt.pdf";
      link.click();
    });
  }

  const getPdfTicketsOfOrder = () => {
    axios.get(`${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`, {
      responseType: "blob"
    }).then((res) => {
      const data = window.URL.createObjectURL(res.data);
      var link = document.createElement('a');
      link.href = data;
      link.download="Tickets.pdf";
      link.click();
    });
  }

  return (
    <div className={styles.pageContainer}>
      {resources.translation && (
        <section className={styles.section} id="section-geology">
          <div className={styles.boxMain + " row"}>
            <Header language={language} />
            <hr></hr>
            <div>
              <p className="text fw-bold fs-5">
                {resources.translation.ThanksOrder}
              </p>
              <p className="text">{resources.translation.ConfirmationEmail}</p>
              <div className="row text-center mb-3">
                <button className={"col-md-12 mb-2 " + styles.button} onClick={() => getPdfTicketsOfOrder()}>
                {resources.translation.DownloadTicket}
                </button>
                <button className={"col-md-12 mb-2 " + styles.button} onClick={() => getReceiptOfOrder()}>
                {resources.translation.DownloadBill}
                </button>
              </div>
            </div>
            <hr></hr>
            <div>
              <p className="text fw-bold fs-5">
                {resources.translation.Stayover}
              </p>
              <p className="text">
                {resources.translation.StayoverOpportunities}
              </p>
              <p>{resources.translation.HotelTrafo}</p>
              <p>{resources.translation.HotelAtrium}</p>
            </div>
            <hr></hr>
            <div>
              <p className="text fw-bold fs-5">
                {resources.translation.Parking}
              </p>
              <p>{resources.translation.ParkingOpportunities}</p>
              <textarea className={styles.textArea}></textarea>
            </div>
            <div className="button-group mt-4 ms-2">
              <button className={styles.button + " btn"} onClick={onComplete}>
                {resources.translation.BackToStart}
              </button>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Confirmation;
