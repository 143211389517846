import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <p className="mt-5 text-center">
        <small style={{ color: "grey" }}>
          powered by{" "}
          <a
            style={{ color: "grey" }}
            target="_blank"
            href="https://organizer.ticketino.com/de"
          >
            TICKETINO
          </a>
        </small>
      </p>
    </div>
  );
};

export default Footer;
