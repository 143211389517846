import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import Reducer from "../../../utilities/Reducer";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "../../../components/Loading";

const Step10_Address = () => {
  const [token] = useState(sessionStorage.getItem("token"));
  const [eventId, setEventId] = useState();
  const [orderId] = useState(sessionStorage.getItem("ExpovinaOrderId"));
  const [orderInfo, setOrderInfo] = useState({});
  const [bookedTicketTypes, setBookedTicketTypes] = useState([]);
  const [bookedTicketTypeInfo, setBookedTicketTypeInfo] = useState([]);
  const [requested, setRequested] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [newsletter, setNewsletter] = useState();
  const [buyerInfo, setBuyerInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const [errors, setErrors] = useState("");
  const [promotionCode, setPromotionCode] = useState("");
  const [addedPromocodes, setAddedPromocodes] = useState([]);
  const [promoError, setPromoError] = useState("");
  const [loading, setLoading] = useState(true);

  // react hook for navigation
  let history = useHistory();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  // fetching resources
  const [resources, setResources] = useState({});

  const { language } = useParams();

  let languageId = 0;

  if (language === "de" || language === "DE") {
    languageId = 1;
  } else if (language === "fr" || language === "FR") {
    languageId = 2;
  } else if (language === "en" || language === "EN") {
    languageId = 3;
  } else if (language === "it" || language === "IT") {
    languageId = 4;
  } else {
    languageId = 0;
  }

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    if (JSON.parse(sessionStorage.getItem("AddedPromocodes")).length > 0) {
      setAddedPromocodes(JSON.parse(sessionStorage.getItem("AddedPromocodes")));
    }

    requestFormSettings();
  }, []); //only on first page load

  useEffect(() => {
    requestResources();
  }, [language]); //everytime language is changed

  useEffect(() => {
    if (requested === 0) {
      requestCompletedOrder();
    }

    requestAddressOfBuyer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestFormSettings = async () => {
    await axios.get(`expovina2023/formsettings`).then((res) => {
      setEventId(res.data.eventId);
    });
  };

  const requestResources = async () => {
    await axios
      .get(`expovina2023/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestCompletedOrder = async () => {
    await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`).then((res) => {
      setBookedTicketTypes(res.data.tickets);
      setOrderInfo(res.data);
      setTotalPrice(res.data.totalPrice);

      res.data.tickets.map((t) => requestBookedTicketTypeInfo(t.ticketTypeId));

      setTimeout(() => setLoading(false), 500);

      // prevent overwriting
      setRequested(requested + 1);
    });
  };

  const requestBookedTicketTypeInfo = async (ticketTypeId) => {
    await axios
      .get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`)
      .then((res) => {
        setBookedTicketTypeInfo((current) => [
          ...current,
          res.data.ticketTypeInfos.find((tti) => tti.languageId === languageId),
        ]);
      });
  };

  const requestAddressOfBuyer = async () => {
    await axios.get(`${baseUrl}/Order/${orderId}/Address`).then((res) => {
      setBuyerInfo({
        firstname: res.data.firstName,
        lastname: res.data.name,
        email: res.data.email,
      });

      setNewsletter(res.data.code1);
    });
  };

  const onInputChange = (e) => {
    setBuyerInfo({ ...buyerInfo, [e.target.name]: e.target.value });
  };

  const onChangeNewsletter = (e) => {
    setNewsletter(e.target.checked);
  };

  const addAddressToBasket = () => {
    let addressBody = {
      firstname: buyerInfo.firstname,
      name: buyerInfo.lastname,
      email: buyerInfo.email,
      countryId: 176,
      code1: newsletter,
    };

    axios
      .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
      .then((res) => {
        history.push(`/${language}/2023/expovina/checkout`);
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const applyPromotionCode = () => {
    axios
      .put(
        `${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotionCode}`
      )
      .then((res) => {
        changeDeliveryType();
        setPromoError("");
        setAddedPromocodes((current) => [...current, promotionCode]);

        let array = addedPromocodes;

        array.push(promotionCode);

        sessionStorage.setItem("AddedPromocodes", JSON.stringify(array));
        requestCompletedOrder();
      })
      .catch((error) => {
        setPromoError(resources.translation.PromocodeError);
      });
  };

  const removePromotionCode = (promo) => {
    axios
      .delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promo}`)
      .then((res) => {
        changeDeliveryType();
        setPromoError("");
        let indexPromo = addedPromocodes.findIndex((code) => code === promo);

        addedPromocodes.splice(indexPromo, 1);

        let array = addedPromocodes;

        sessionStorage.setItem("AddedPromocodes", JSON.stringify(array));
        requestCompletedOrder();
      })
      .catch((error) => {
        setPromoError(resources.translation.PromocodeError);
      });
  };

  const changeDeliveryType = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/1`)
      .then((res) => {
        changePaymentType();
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const changePaymentType = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/1`)
      .then((res) => {
        requestCompletedOrder();
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const promotionCodeElement = () => {
    return (
      <>
        <div className="col-md-8 col-12 pb-3">
          <h1 className="mb-2 fs-6 text-uppercase">
            {resources.translation.Promocode}
          </h1>
          <p className="m-0 fs-6 fw-light">
            {resources.translation.PromocodeText}
          </p>
        </div>
        <div className={"row pb-4 " + styles.boxBorder}>
          <div className="col-md-8 col-12 pb-3">
            <input
              id="inputCode"
              type="text"
              name="promotionCode"
              className={"form-control " + styles.customTextbox}
              placeholder={resources.translation.PromocodeEnter}
              value={promotionCode}
              onChange={(e) => setPromotionCode(e.target.value)}
            />
          </div>
          <div className="col-md-4 col-3 text-center">
            <button
              className={"text-uppercase " + styles.button}
              onClick={() => applyPromotionCode()}
              disabled={promotionCode === ""}
            >
              {resources.translation.Add}
            </button>
          </div>
        </div>
      </>
    );
  };

  const mapAddedPromotionCode = () => {
    return addedPromocodes.map((promocode, index) => (
      <div key={index} className={styles.promocodeBox}>
        <p
          className={
            "d-flex justify-content-between align-items-start mb-2 fs-6"
          }
        >
          {promocode}
          <span></span>
          <span onClick={() => removePromotionCode(promocode)}>
            <i class="bi bi-x-lg"></i>
          </span>
        </p>
      </div>
    ));
  };

  const mapBookedTickets = () => {
    return (
      <div>
        {bookedTicketTypes.map((ticket, index) => (
          <p
            className={
              "d-flex justify-content-between align-items-start mb-2 fs-6"
            }
            key={index}
          >
            {
              bookedTicketTypeInfo.find(
                (booked) => booked.ticketTypeId === ticket.ticketTypeId
              ).name
            }

            <span></span>
            <p className="m-0 col-2 text-end">
              {ticket.price + " " + ticket.currency}
            </p>
          </p>
        ))}
      </div>
    );
  };

  const totalBookedTickets = () => {
    return (
      <div>
        <p
          className={
            "d-flex justify-content-between align-items-start mb-2 fs-6"
          }
        >
          {resources.translation.TotalTax}

          <span></span>
          {totalPrice + " " + orderInfo.currency}
        </p>
      </div>
    );
  };

  const buyerInformationForm = () => {
    return (
      <div className="ms-4 me-4">
        <div class="mb-3 row">
          <label className="col-sm-2 col-form-label">
            {resources.translation.Firstname}:
          </label>
          <div class="col-sm-10">
            <input
              className={"form-control " + styles.customTextbox}
              name="firstname"
              id="inputFirstname"
              value={buyerInfo.firstname}
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label className="col-sm-2 col-form-label">
            {resources.translation.Lastname}:
          </label>
          <div class="col-sm-10">
            <input
              className={"form-control " + styles.customTextbox}
              name="lastname"
              id="inputLastname"
              value={buyerInfo.lastname}
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
        <div class="mb-5 row">
          <label className="col-sm-2 col-form-label">
            {resources.translation.Email}:
          </label>
          <div class="col-sm-10">
            <input
              className={"form-control " + styles.customTextbox}
              name="email"
              id="inputEmail"
              value={buyerInfo.email}
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
        <p>{resources.translation.Newsletter}</p>
        <div class="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={newsletter}
            id="flexCheckDefault"
            onClick={(e) => onChangeNewsletter(e)}
          />
          <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
            {resources.translation.NewsletterTextExpovina}
          </label>
        </div>
        <div className="row mt-4">
          <div className="text-start mb-3 col-6">
            <button
              className={"" + styles.button}
              onClick={() => history.push(`/${language}/2023/expovina/home`)}
            >
              {resources.translation.Back}
            </button>
          </div>
          <div className="text-end mb-3 col-6">
            <button
              className={"" + styles.button}
              onClick={addAddressToBasket}
              disabled={!validation}
            >
              {resources.translation.Next}
            </button>
          </div>
        </div>
      </div>
    );
  };

  // validating input fields
  let validation =
    /^([a-z A-Zöéàäèü - ]{1,70})$/.test(buyerInfo.firstname) &&
    /^([a-z A-Zöéàäèü - ]{1,70})$/.test(buyerInfo.lastname) &&
    /^\S+@\S+\.\S+$/.test(buyerInfo.email);

  return (
    <div className={"container " + styles.wrapper}>
      {loading ? (
        <Loading
          alignment="center"
          color="#e86e82"
          bgColor="#fff"
          position="fixed"
          top="50%"
        />
      ) : (
        <>
          <Header />
          {resources.translation && (
            <>
              <div className="mb-5">
                <h1 className="fs-6 mt-2 text-uppercase">
                  {resources.translation.Total}
                </h1>
                {mapBookedTickets()}
              </div>
              <div>{promotionCodeElement()}</div>
              <div>
                <p className="text-danger fs-6">
                  {promoError != "" && promoError}
                </p>
              </div>
              <div className="col-8">{mapAddedPromotionCode()}</div>
              <hr></hr>
              <div className="mb-5">{totalBookedTickets()}</div>
              <div>
                <h1 className="fs-6 mt-2 mb-3 text-uppercase">
                  {resources.translation.BuyerInformation}
                </h1>
                {buyerInformationForm()}
              </div>
            </>
          )}
          <Footer />
        </>
      )}
    </div>
  );
};

export default Step10_Address;
