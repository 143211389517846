import axios from "axios";
import React, { useEffect, useState } from "react";

import styles from "../../css/style.module.css";

const Header = (props) => {
  const [resources, setResources] = useState({});

  useEffect(() => {
    requestResources();
  }, [props.language]);

  const requestResources = () => {
    axios.get(`geologentag2022/resources/${props.language}`).then((res) => {
      setResources(res.data);
    });
  };

  return (
    <div>
      {resources.translation && (
        <div className={styles.firstHalf}>
          <div className="row group-items">
            <div className="col-md-6 col-12 text-start">
              <ul className={styles.list + " " + styles.ul}>
                <li>{resources.translation.HeaderLeft1}</li>
                <li>{resources.translation.HeaderLeft2}</li>
                <li>{resources.translation.HeaderLeft3}</li>
                <li className="text fw-bold mt-5">
                  {resources.translation.HeaderLeft4}
                </li>
                <li className="text fw-bold">
                  {resources.translation.HeaderLeft5}
                </li>
              </ul>
            </div>
            <div className="col-md-1 col-2 me-3"></div>
            <div className="col-md col-12">
              <ul className={styles.list + " " + styles.listRight}>
                <li className="text fw-bold">
                  {resources.translation.HeaderRight1}
                </li>
                <li>{resources.translation.HeaderRight2}</li>
                <li className="text fw-bold">
                  {resources.translation.Eveningpass}
                </li>
                <li>{resources.translation.HeaderRight3}</li>
                <li>{resources.translation.HeaderRight4}</li>
                <li className="text fw-bold">
                  {resources.translation.HeaderRight5}
                </li>
                <li>{resources.translation.HeaderRight6}</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
