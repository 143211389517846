import React from "react";

const Navbar = () => {
  return (
    <ul className="nav justify-content-center p-3 fs-6" id="nav">
      <li className="nav-item ms-2 me-2">
        <a className="nav-link" aria-current="page"></a>
      </li>
    </ul>
  );
};

export default Navbar;
