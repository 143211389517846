import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";

import styles from "../css/style.module.css";
import Navbar from "./components/Navbar";

const ShopTickets = () => {
  const [token] = useState(sessionStorage.getItem("token"));
  const [orderId] = useState(sessionStorage.getItem("orderId"));
  const [order] = useState([]);
  const [event, setEvent] = useState({});
  const [eventInfo, setEventInfo] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [ticketTypeInfos, setTicketTypeInfos] = useState([]);
  const [promocode, setPromocode] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [requested, setRequested] = useState(0);
  const [isNotValid, setIsNotValid] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState("");
  const [promoError, setPromoError] = useState("");

  const { eventId } = useParams();

  let history = useHistory();

  let baseUrl = process.env.REACT_APP_BASEURL_API;

  useEffect(() => {
    if (eventId) {
      requestEvent();

      if (requested === 0) {
        requestTicketTypes();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const requestEvent = () => {
    axios
      .get(`${baseUrl}/Event/${eventId}`)
      .then((res) => {
        setEvent(res.data);
        requestEventInfo(eventId);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const requestEventInfo = () => {
    axios
      .get(`${baseUrl}/Event/${eventId}/Infos`)
      .then((res) => {
        setEventInfo(res.data.eventInfos);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const requestTicketTypes = () => {
    axios
      .get(`${baseUrl}/Event/${eventId}/TicketTypes`)
      .then((res) => {
        setTicketTypes(res.data.ticketTypes);

        res.data.ticketTypes.map((t, index) =>
          requestTicketTypeInfos(t.id, index)
        );

        setRequested(requested + 1);

        setTimeout(() => setLoading(false), 200);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const requestTicketTypeInfos = (ticketTypeId, index) => {
    axios
      .get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`)
      .then((res) => {
        setTicketTypeInfos((current) => [
          ...current,
          res.data.ticketTypeInfos[0],
        ]);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const addTicketsToBasket = () => {
    order.forEach((o) => {
      if (o.quantity > 0) {
        axios
          .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
            ticketsToAdd: [
              {
                ticketTypeId: o.id,
                quantity: o.quantity,
              },
            ],
          })
          .then(() => {
            history.push("/2022/LightRagaz/Form");
          })
          .catch((error) => {
            setErrors(error.response.data);
          });
      }
    });
  };

  const onClick = () => {
    addTicketsToBasket();
  };

  const applyPromotionCode = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promocode}`)
      .catch((error) => {
        setPromoError(error.response.data);
      });
  };

  // quantity calculation
  const calculate = () => {
    let total = 0;
    order.forEach((o) => {
      total =
        total + o.quantity * ticketTypes.find((t) => t.id === o.id).price * 1;
    });

    setTotalPrice(total);
  };

  const onOrderChange = (e, id) => {
    let obj = {
      id: id,
      quantity: e.target.value,
    };

    let index = order.findIndex((o) => o.id === id);
    let length = order.length;

    if (length < 1) {
      order[0] = obj;
    } else {
      if (index !== -1) {
        order[index].quantity = e.target.value;
      } else {
        order[length] = obj;
      }
    }

    // quantity validation
    let totalQuantity = 0;

    order.forEach((o) => {
      totalQuantity = totalQuantity + o.quantity * 1;
    });

    if (totalQuantity > 0 && totalQuantity < event.maxTicketsProOrder + 1) {
      setIsNotValid(false);
    } else {
      setIsNotValid(true);
    }

    // calculate the total price
    calculate();
  };

  const onBackClick = () => {
    history.push("/2022/LightRagaz/Home");
  };

  const displayEventInfos = () => {
    let utcStart = "";
    let utcEnd = "";
    let utcStartTime = "";
    let utcEndTime = "";

    if (event.start) {
      utcStart = new Date(event.start.toString())
        .toUTCString()
        .substring(0, 16);
      utcEnd = new Date(event.end.toString()).toUTCString().substring(0, 16);
      utcStartTime = new Date(event.start.toString()).toLocaleTimeString();
      utcEndTime = new Date(event.end.toString()).toLocaleTimeString();
    }

    return (
      event &&
      eventInfo.length > 0 &&
      eventInfo.map(
        (info, index) =>
          info.languageId === 0 && (
            <div key={index}>
              <h2 className="text text-light fs-4 fw-semibold text-center">
                {info.name}
              </h2>
              <h2 className="text text-light text-center fs-5 fw-light mb-3">
                {info.organizerNameOnTickets}
              </h2>
              <h2 className="text text-light fs-6 text-center mb-4">
                {info.shortDescription}
              </h2>
              <h3 className="text text-light fs-6 text-center fw-light">
                {utcStartTime + " - " + utcEndTime}
              </h3>
              <h2 className="text text-light fs-6 text-center fw-light">
                {utcStart === utcEnd ? utcStart : utcStart + " - " + utcEnd}
              </h2>
            </div>
          )
      )
    );
  };

  const displayPromoCode = () => {
    return (
      <>
        <div className="mb-4 row">
          <div className="col-md-6 col-sm-8 col-5 me-2">
            <input
              type="text"
              className={styles.inputCode + " form-control"}
              id="inputCode"
              name="code"
              placeholder="Promocode"
              value={promocode}
              onChange={(e) => setPromocode(e.currentTarget.value)}
            />
          </div>
          <button
            htmlFor="inputPassword"
            className={styles.button + " col-sm-2 col-3 btn p-1 me-3"}
            onClick={applyPromotionCode}
          >
            Einlösen
          </button>
          {promoError && <p className="text text-danger mt-3">{promoError}</p>}
        </div>
      </>
    );
  };

  const mapTicketTypeInfos = () => {
    let maxTickets = [];

    if (event) {
      if (event.maxTicketsProOrder) {
        for (let index = event.maxTicketsProOrder; index > -1; index--) {
          maxTickets.unshift(index);
        }
      }
    }

    return (
      ticketTypeInfos.length > 0 &&
      ticketTypeInfos.map((t, index) => (
        <div className="mb-3 row" key={index}>
          <div className="col-sm-8 col-md-8 col-8">
            <p className="text text-light fw-light" id="inputCode">
              {t.name}
            </p>
          </div>
          <div className="col-sm-2 col-md-2 col-2 text-center">
            {ticketTypes && ticketTypes.length > 0 && (
              <p className="text text-light" id="inputCode">
                {
                  ticketTypes.find((type) => type.id === t.ticketTypeId)
                    .currency
                }{" "}
                {ticketTypes.find((type) => type.id === t.ticketTypeId).price}
              </p>
            )}
          </div>
          <div className="col-sm-2 col-md-2 col-2 mb-3">
            <select
              className={styles.selectNum + " form-select"}
              aria-label="Default select example"
              onChange={(e) => onOrderChange(e, t.ticketTypeId)}
            >
              {maxTickets.map((m, index) => (
                <option value={m} key={index}>
                  {m}
                </option>
              ))}
            </select>
          </div>
          <hr></hr>
        </div>
      ))
    );
  };

  const displayTicketTotal = () => {
    return (
      <div className="mb-3 row">
        <div className="col-sm-10 col-md-10 col-10">
          <p className="text text-light fs-6" id="inputCode">
            Total:
          </p>
        </div>
        <div className="col-sm-2 col-md-2 col-2 text-start">
          <p className="text text-light fs-6 fw-bolder" id="inputCode">
            CHF {totalPrice}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.pageContainer}>
      <Navbar />
      <section className={styles.section} id="section">
        <div className={styles.boxMain + " row col-lg-5 col-md-12 col-sm-12"}>
          <div className={styles.firstHalf}>
            <div>{displayEventInfos()}</div>
          </div>
          {loading ? (
            <Loading alignment="center" color="#e03c90" bgColor="#11172a" margin="85px" />
          ) : (
            <div className="container m-3 text-start col-md-12">
              <p className="text text-start fw-light fs-6">
                Kombiticket bitte ausgedruckt vorweisen
              </p>
              {displayPromoCode()}
              {mapTicketTypeInfos()}
              {displayTicketTotal()}

              {errors !== "" && (
                <div className="row">
                  <p className="text text-danger ps-0">{errors}*</p>
                </div>
              )}
              <div className="mb-3 row">
                <button
                  className={styles.button + " btn col-md-3 me-3 col-3"}
                  onClick={onBackClick}
                >
                  Zurück
                </button>
                <button
                  type="submit"
                  className={styles.button + " btn col-md-3 col-3 me-3"}
                  disabled={isNotValid}
                  onClick={onClick}
                >
                  Weiter
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ShopTickets;
