import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Reducer from "../../../utilities/Reducer";

import styles from "../css/style.module.css";
import Header from "./components/Header";

const PaymentForm = () => {
  const [paymentType, setPaymentType] = useState(0);
  const [deliveryType, setDeliveryType] = useState(0);
  const [errors, setErrors] = useState("");
  const [orderId] = useState(sessionStorage.getItem("orderId"));
  const [token] = useState(sessionStorage.getItem("token"));
  const [bookedTickets, setBookedTickets] = useState([]);
  const [bookedTicketsInfo, setBookedTicketsInfo] = useState([]);
  const [requested, setRequested] = useState(0);
  const [isDevelopment, setIsDevelopment] = useState(false);

  let history = useHistory();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  const [resources, setResources] = useState({});

  // fetching resources
  const { language } = useParams();

  const [languageInfo, dispatch] = useReducer(Reducer, {
    id: 0,
    language: language,
  });

  useEffect(() => {
    dispatch({ payload: language });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageInfo.id]);

  useEffect(() => {
    requestResources();
    requestFormSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const requestResources = () => {
    axios
      .get(`geologentag2022/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestFormSettings = () => {
    axios.get(`geologentag2022/formsettings`).then((res) => {
      setIsDevelopment(res.data.isDevelopment);
    });
  };

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    if (requested === 0) {
      requestOrderInformation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestOrderInformation = () => {
    axios
      .get(`${baseUrl}/ShopBasket/Order/${orderId}`)
      .then((res) => {
        setBookedTickets(res.data.tickets);

        setDeliveryType(res.data.deliveryMethodId);
        setPaymentType(res.data.paymentMethodId);
        
        res.data.tickets.map((ticket) =>
          requestTicketTypeInfo(ticket.ticketTypeId)
        );

        // prevent overwriting
        setRequested(requested + 1);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestTicketTypeInfo = (ticketId) => {
    axios
      .get(`${baseUrl}/TicketType/${ticketId}/Infos`)
      .then((res) => {
        setBookedTicketsInfo((current) => [
          ...current,
          res.data.ticketTypeInfos.find(
            (tti) => tti.languageId === languageInfo.id
          ),
        ]);
      })
      .catch((error) => console.error(error.response.data));
  };

  const changeDeliveryType = () => {
    axios
      .put(
        `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryType}`
      )
      .then(() => changePaymentType())
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const changePaymentType = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentType}`)
      .then(() => {
        let dataTransProviders = [1, 7, 8, 9, 10, 14];

        if (dataTransProviders.findIndex((d) => d === paymentType) !== -1) {
          requestDigitalSignature();
        } else {
          history.push(`/${language}/2022/Geologentag/Confirm`);
        }
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

    const requestDigitalSignature = async () => {

    var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

    await axios
      .get(baseRequestUrl)
      .then((res) => {
        let datatransFormId = "datatrans-" + new Date().getTime();
        var form = "<form class='datatrans' id='" + datatransFormId + "'></form>";

        let container = document.getElementById("datatrans-form-placeholder");

        container.innerHTML += form;

        let element = document.getElementById(datatransFormId);

        //element.setAttribute("data-merchant-id", "1100004624");
        element.setAttribute("data-merchant-id", res.data.merchantId);
        element.setAttribute("data-amount", res.data.amount);
        element.setAttribute("data-currency", res.data.currency);
        element.setAttribute("data-refno", res.data.referenceNumber);
        element.setAttribute("data-reqType", res.data.reqType);
        element.setAttribute(
          "data-upp-return-target",
          res.data.uppReturnTarget
        );
        element.setAttribute("data-paymentMethod", res.data.paymentMethod);
        element.setAttribute("data-sign", res.data.digitalSignature);

        let domain = "https://" + window.location.host;

        let successUrl = domain + "/geologentag2022/redirect/success";
        let errorUrl = domain + "/geologentag2022/redirect/error";
        let cancelUrl = domain + "/geologentag2022/redirect/cancel";

        element.setAttribute("data-success-url", successUrl);
        element.setAttribute("data-error-url", errorUrl);
        element.setAttribute("data-cancel-url", cancelUrl);

        for (const key in res.data.userInfo) {
          element.setAttribute(key, res.data.userInfo[key]);
        }

        for (const key in res.data.merchantSpecificParameters) {
          element.setAttribute(key, res.data.merchantSpecificParameters[key]);
        }

        // start datatrans -> call the payment form
        window.Datatrans.startPayment({
          form: "#" + datatransFormId,
        });
      })
      .then(() => {
        console.log("datatrans done");
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const mapSelectedTickets = () => {
    return (
      <ol className="col-md-12 ps-0">
        {bookedTicketsInfo.map((ticket, index) => (
          <li
            className={
              styles.geology +
              " list-group-item d-flex justify-content-between align-items-start"
            }
            key={index}
          >
            {ticket.name}

            <span></span>
            {bookedTickets.find(
              (booked) => booked.ticketTypeId === ticket.ticketTypeId
            ).price +
              " " +
              bookedTickets.find(
                (booked) => booked.ticketTypeId === ticket.ticketTypeId
              ).currency}
          </li>
        ))}
      </ol>
    );
  };

  const totalOfSelectedTickets = () => {
    let sum = 0;
    let currency = "";

    bookedTickets.forEach((ticket) => {
      sum = sum + ticket.price;
      currency = ticket.currency;
    });

    return (
      <ol className="col-md-12 ps-0">
        {bookedTickets.length > 0 && (
          <li
            className={
              styles.geology +
              " list-group-item d-flex justify-content-between align-items-start"
            }
          >
            {resources.translation && resources.translation.Total} <span></span>{" "}
            {sum} {currency}
          </li>
        )}
      </ol>
    );
  };

  const onSubmit = () => {
    changeDeliveryType();
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.pageContainer}>
      <div id="datatrans-form-placeholder"></div>
      {resources.translation && (
        <section className={styles.section} id="section-geology">
          <div className={styles.boxMain + " row p-3"}>
            <Header language={language} />
            <hr></hr>
            <div className="ps-3 pe-3">
              <p className="text fw-bold fs-5">
                {resources.translation.DeliveryMethod}
              </p>
              <div className={styles.formCheck + " mb-3"}>
                <input
                  className={styles.ticketCheckbox + " form-check-input"}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={deliveryType === 2 ? true : false}
                  onChange={() => setDeliveryType(2)}
                />
                <label
                  className={styles.formCheckLabel}
                  htmlFor="flexCheckDefault"
                >
                  {resources.translation.Mailing}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={styles.ticketCheckbox + " form-check-input"}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={deliveryType === 1 ? true : false}
                  onChange={() => setDeliveryType(1)}
                />
                <label
                  className={styles.formCheckLabel}
                  htmlFor="flexCheckDefault"
                >
                  {resources.translation.PrintAtHome}
                </label>
              </div>
            </div>
            <hr className="hr"></hr>
            <div className="ps-3 pe-3">
              <p className="text fw-bold fs-5">
                {resources.translation.PaymentType}
              </p>
              <div className="form-check mb-3">
                <input
                  className={styles.ticketCheckbox + " form-check-input"}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={paymentType === 2 ? true : false}
                  onChange={() => setPaymentType(2)}
                />
                <label
                  className={styles.formCheckLabel}
                  htmlFor="flexCheckDefault"
                >
                  {resources.translation.Invoice}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={styles.ticketCheckbox + " form-check-input"}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={paymentType === 11 ? true : false}
                  onChange={() => setPaymentType(11)}
                />
                <label
                  className={styles.formCheckLabel}
                  htmlFor="flexCheckDefault"
                >
                  {resources.translation.Immediate}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={styles.ticketCheckbox + " form-check-input"}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={paymentType === 1 ? true : false}
                  onChange={() => setPaymentType(1)}
                />
                <label
                  className={styles.formCheckLabel}
                  htmlFor="flexCheckDefault"
                >
                  {resources.translation.Mastercard}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={styles.ticketCheckbox + " form-check-input"}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={paymentType === 7 ? true : false}
                  onChange={() => setPaymentType(7)}
                />
                <label
                  className={styles.formCheckLabel}
                  htmlFor="flexCheckDefault"
                >
                  {resources.translation.Visa}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={styles.ticketCheckbox + " form-check-input"}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={paymentType === 8 ? true : false}
                  onChange={() => setPaymentType(8)}
                />
                <label
                  className={styles.formCheckLabel}
                  htmlFor="flexCheckDefault"
                >
                  {resources.translation.Amex}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={styles.ticketCheckbox + " form-check-input"}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={paymentType === 9 ? true : false}
                  onChange={() => setPaymentType(9)}
                />
                <label
                  className={styles.formCheckLabel}
                  htmlFor="flexCheckDefault"
                >
                  {resources.translation.Postfinance}
                </label>
              </div>
            </div>
            <hr className="hr mt-4"></hr>
            <div className="ps-3 pe-3">
              <p className="text fw-bold fs-5 mt-3">
                {resources.translation && resources.translation.Summary}
              </p>
              <div>
                {mapSelectedTickets()}
                <hr></hr>
                {totalOfSelectedTickets()}
              </div>
              {errors !== "" && (
                <div className="row">
                  <p className="text text-danger ps-2">{errors}*</p>
                </div>
              )}
            </div>
            <div className="button-group mt-4 ps-3 pe-3">
              <button className={styles.button + " me-3 btn"} onClick={onBack}>
                {resources.translation.Back}
              </button>

              <button className={styles.button + " btn"} onClick={onSubmit}>
                {resources.translation.Next}
              </button>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default PaymentForm;
