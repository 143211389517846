import { React, useEffect, useState } from "react";
import axios from "axios";
import logo from "../images/siemens.png";
import { useHistory, useParams } from "react-router-dom";

import styles from "../css/style.module.css";

function Home() {
  //only ticket that needs to be added
  const [ticketTypeToAdd, setTicketTypeToAdd] = useState();
  const maxTimeDiff = 10;
  let history = useHistory();

  const [token, setToken] = useState("");
  const [gender, setGender] = useState("");
  const [department, setDepartment] = useState("");
  const [firstname, setFirstname] = useState("");
  const [familyname, setFamilyname] = useState("");
  const [email, setEmail] = useState("");
  const [needTransportArrival, setNeedTransportArrival] = useState("");
  const [needTransportDeparture, setNeedTransportDeparture] = useState("");
  const [orderAddedTicket, setOrderAddedTicket] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [lastInteraction, setLastInteraction] = useState(new Date());
  const [errors, setErrors] = useState("");

  const [resources, setResources] = useState({});

  // fetching resources
  const { language } = useParams();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  useEffect(() => {
    requestFormSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    requestResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (token !== "") {
      startOrder();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const requestResources = () => {
    axios.get(`sommerfest2022/resources/${language}`).then((res) => {
      setResources(res.data);
    });
  };

  const requestFormSettings = () => {
    axios.get(`sommerfest2022/formsettings`).then((res) => {
      setTicketTypeToAdd(res.data.ticketTypeId);
      loadToken();
    });
  };

  const loadToken = () => {
    axios.get("token/get/sommerfest").then((res) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
      localStorage.setItem("token", res.data);
      setToken(res.data);
    });
  };

  const startOrder = () => {
    //create basket
    const shopbasketBody = {
      currency: "CHF",
      tenantId: 1,
      pointOfSaleId: 6585,
      paymentType: 5,
    };

    if (
      localStorage.getItem("sommerfestOrderId") === null ||
      localStorage.getItem("sommerfestOrderId") === "" ||
      localStorage.getItem("sommerfestOrderId") === 0
    ) {
      axios
        .post(`${baseUrl}/ShopBasket/Order`, shopbasketBody)
        .then((result) => {
          localStorage.setItem("sommerfestOrderId", result.data.id);

          setOrderId(result.data.id);

          addTicketToBasket(result.data.id);
        })
        .catch((error) => {
          alert("Please refresh the page");
        });
    } else {
      addTicketToBasketIfOrderExists();
    }
  };

  const addTicketToBasket = (orderId) => {
    axios
      .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
        ticketsToAdd: [
          {
            ticketTypeId: ticketTypeToAdd,
            quantity: 1,
          },
        ],
      })
      .then(() => {
        localStorage.setItem("ticketAdded", 1);

        setOrderAddedTicket(true);
        setLastInteraction(new Date());
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const addTicketToBasketIfOrderExists = () => {
    let orderIdStorage = localStorage.getItem("sommerfestOrderId");

    setOrderId(orderIdStorage);

    const ticketAddedValue = localStorage.getItem("ticketAdded");
    if (ticketAddedValue !== "1") {
      axios
        .post(`${baseUrl}/ShopBasket/Order/${orderIdStorage}/Tickets`, {
          ticketsToAdd: [
            {
              ticketTypeId: ticketTypeToAdd,
              quantity: 1,
            },
          ],
        })
        .then((result) => {
          orderAddedTicket(true);
          lastInteraction(new Date());

          localStorage.setItem("ticketsAdded", 1);
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  const addAddressToBasket = () => {
    const maxIdleTime = new Date(
      new Date(lastInteraction).getTime() + maxTimeDiff * 60000
    );

    if (new Date() < maxIdleTime) {
      let addressBody = {
        salutation: gender,
        firstname: firstname,
        name: familyname,
        email: email,
        code1: department,
        code2: needTransportArrival,
        code3: needTransportDeparture,
      };

      axios
        .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
        .then(() => changeDeliveryMethod())
        .catch((error) => {
          console.error(error);
          setErrors(error.response.data);
        });
    }
  };

  const changeDeliveryMethod = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/1`)
      .then(() => confirmOrder());
  };

  const confirmOrder = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
      .then((res) => {
        localStorage.setItem("ticketAdded", 0);
        localStorage.setItem("sommerfestOrderId", "");

        history.push(`/${language}/2022/Sommerfest/Confirm`);
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const isValid =
    gender !== "" &&
    needTransportArrival !== "" &&
    needTransportDeparture !== "" &&
    /^([a-z A-Zöéàäèü -]{1,70})$/.test(firstname) &&
    /^([a-z A-Zöéàäèü -]{1,70})$/.test(familyname) &&
    /^\S+@\S+\.\S+$/.test(email);

  return (
    <div className={styles.pageContainer + " " + styles.fontFace}>
      <img src={logo} className={styles.logo} alt="Siemens Logo"></img>

      {resources.translation && (
        <section className={styles.section} id="section">
          <div className={styles.boxMain + " row"}>
            <div className={styles.firstHalf}>
              <div>
                <h1 className="text text-light">
                  {resources.translation.PageTitle}
                </h1>
                <p className="text mt-4">
                  {resources.translation.RegisterMessage}
                </p>
              </div>
              <div className="mt-3 mb-4">
                <p className={styles.titleStyle + " text fw-bold"}>
                  {resources.translation.HintTitle}
                </p>
                <ul>
                  <li>{resources.translation.HintMessage1}</li>
                  <li>{resources.translation.HintMessage2}</li>
                  <li>{resources.translation.HintMessage3}</li>
                </ul>
              </div>
            </div>
            <div className={styles.container}>
              <div className="mb-4 row">
                <label className="col-sm-3 col-md-2 col-12 text text-light">
                  {resources.translation.GenderString}*
                </label>
                <div className="col-md-8 col-sm-8 col-8 ms-2 row">
                  <div className="col-md-4 col-sm-6">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={(event) => {
                        setGender("male");
                      }}
                    />
                    <label
                      className="form-check-label col-8"
                      htmlFor="flexCheckDefault"
                    >
                      {resources.translation.MaleString}
                    </label>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={(event) => {
                        setGender("female");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      {resources.translation.FemaleString}
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-4 row">
                <label className="col-sm-3 col-md-2 col-12 me-2 text text-light p-2">
                  {resources.translation.DepartmentString}
                </label>
                <div className="col-sm-9 col-md-9 col-12">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(event) => {
                      setDepartment(event.currentTarget.value);
                    }}
                  >
                    <option select></option>
                    <option value="1">
                      {resources.translation.DepartmentSelectionMessage1}
                    </option>
                    <option value="2">
                      {resources.translation.DepartmentSelectionMessage2}
                    </option>
                    <option value="3">
                      {resources.translation.DepartmentSelectionMessage3}
                    </option>
                    <option value="4">
                      {resources.translation.DepartmentSelectionMessage4}
                    </option>
                    <option value="5">
                      {resources.translation.DepartmentSelectionMessage5}
                    </option>
                    <option value="6">
                      {resources.translation.DepartmentSelectionMessage6}
                    </option>
                    <option value="7">
                      {resources.translation.DepartmentSelectionMessage7}
                    </option>
                    <option value="8">
                      {resources.translation.DepartmentSelectionMessage8}
                    </option>
                    <option value="9">
                      {resources.translation.DepartmentSelectionMessage9}
                    </option>
                  </select>
                </div>
              </div>
              <div className="mb-4 row">
                <label className="col-sm-3 col-md-2 col-12 me-2 text text-light p-2">
                  {resources.translation.FirstnameString}*
                </label>
                <div className="col-sm-9 col-md-9 col-12">
                  <input
                    type="text"
                    name="firstname"
                    className="form-control firstname"
                    id="inputCode"
                    onChange={(event) => {
                      setFirstname(event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-4 row">
                <label className="col-sm-3 col-md-2 col-12 me-2 text text-light p-2">
                  {resources.translation.FamilynameString}*
                </label>
                <div className="col-sm-9 col-md-9 col-12">
                  <input
                    type="text"
                    name="lastname"
                    className="form-control lastname"
                    id="inputCode"
                    onChange={(event) => {
                      setFamilyname(event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-4 row">
                <label className="col-sm-3 col-md-2 col-12 me-2 text text-light p-2">
                  {resources.translation.MailString}*
                </label>
                <div className="col-sm-9 col-md-9 col-12">
                  <input
                    type="text"
                    name="lastname"
                    className="form-control email"
                    id="inputCode"
                    placeholder="Example@gmail.com"
                    onChange={(event) => {
                      setEmail(event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-4 row">
                <label className="col-sm-3 col-md-2 col-12 me-2 text text-light p-2"></label>
                <div className="col-sm-9 col-md-9 col-12">
                  <label className="col-sm-12 col-md-12 col-12 me-2 text text-light p-2 text-center mb-3">
                    {resources.translation.TransportArrivalMessage}*
                  </label>
                  <div className="col-sm-12 col-md-12 col-12">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(event) => {
                        setNeedTransportArrival(event.currentTarget.value);
                      }}
                    >
                      <option select></option>
                      <option value="1">
                        {resources.translation.NeedTransportArrivalMessage0}
                      </option>
                      <option value="2">
                        {resources.translation.NeedTransportArrivalMessage1}
                      </option>
                      <option value="3">
                        {resources.translation.NeedTransportArrivalMessage2}
                      </option>
                      <option value="4">
                        {resources.translation.NeedTransportArrivalMessage3}
                      </option>
                      <option value="5">
                        {resources.translation.NeedTransportArrivalMessage4}
                      </option>
                      <option value="6">
                        {resources.translation.NeedTransportArrivalMessage5}
                      </option>
                      <option value="7">
                        {resources.translation.NeedTransportArrivalMessage6}
                      </option>
                      <option value="8">
                        {resources.translation.NeedTransportArrivalMessage7}
                      </option>
                      <option value="9">
                        {resources.translation.NeedTransportArrivalMessage8}
                      </option>
                      <option value="10">
                        {resources.translation.NeedTransportArrivalMessage9}
                      </option>
                      <option value="11">
                        {resources.translation.NeedTransportArrivalMessage10}
                      </option>
                      <option value="12">
                        {resources.translation.NeedTransportArrivalMessage11}
                      </option>
                      <option value="13">
                        {resources.translation.NeedTransportArrivalMessage12}
                      </option>
                      <option value="14">
                        {resources.translation.NeedTransportArrivalMessage13}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mb-4 row">
                <label className="col-sm-3 col-md-2 col-12 me-2 text text-light p-2"></label>
                <div className="col-sm-9 col-md-9 col-12">
                  <label className="col-sm-12 col-md-12 col-12 me-2 text text-light p-2 text-center mb-3">
                    {resources.translation.TransportReturnMessage}*
                  </label>
                  <div className="col-sm-12 col-md-12 col-12">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(event) => {
                        setNeedTransportDeparture(event.currentTarget.value);
                      }}
                    >
                      <option select></option>
                      <option value="1">
                        {resources.translation.NeedTransportDepartureMessage1}
                      </option>
                      <option value="2">
                        {resources.translation.NeedTransportDepartureMessage2}
                      </option>
                      <option value="3">
                        {resources.translation.NeedTransportDepartureMessage3}
                      </option>
                      <option value="4">
                        {resources.translation.NeedTransportDepartureMessage4}
                      </option>
                      <option value="5">
                        {resources.translation.NeedTransportDepartureMessage5}
                      </option>
                      <option value="6">
                        {resources.translation.NeedTransportDepartureMessage6}
                      </option>
                      <option value="7">
                        {resources.translation.NeedTransportDepartureMessage7}
                      </option>
                      <option value="8">
                        {resources.translation.NeedTransportDepartureMessage8}
                      </option>
                      <option value="9">
                        {resources.translation.NeedTransportDepartureMessage9}
                      </option>
                      <option value="10">
                        {resources.translation.NeedTransportDepartureMessage10}
                      </option>
                      <option value="11">
                        {resources.translation.NeedTransportDepartureMessage11}
                      </option>
                      <option value="12">
                        {resources.translation.NeedTransportDepartureMessage12}
                      </option>
                      <option value="13">
                        {resources.translation.NeedTransportDepartureMessage13}
                      </option>
                      <option value="14">
                        {resources.translation.NeedTransportDepartureMessage14}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mb-4 mt-5">
                <p className="text text-light text-center">
                  {resources.translation.AGBString}
                </p>
              </div>
              <div className="mb-4">
                <p className="text text-light text-start">
                  {resources.translation.NeededThingsMessage}
                </p>
              </div>
              {errors !== "" && (
                <div className="mb-3">
                  {typeof errors === `string` ? (
                    <p className="text text-danger">{errors}*</p>
                  ) : (
                    <p className="text text-danger">{errors.errors.Email}*</p>
                  )}
                </div>
              )}
              <div className="flex-end">
                <button
                  className={styles.button + " btn btn-light"}
                  disabled={!isValid}
                  onClick={addAddressToBasket}
                >
                  {resources.translation.ButtonSendString}
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Home;
