import React from "react";

import styles from "../../css/style.module.css";
import footerLogo from "../../images/footer_logo.png";

const Footer = () => {
  return (
    <div className={styles.footer + " text-center"}>
      <img
        src={footerLogo}
        alt="Turicum Gin Lab Logo"
        className={styles.footerLogo}
      ></img>
      <ul className={styles.footerList}>
        <li className="text-light">Turicum Distillery GmbH</li>
        <li className="text-light">Albisriederstrasse 253</li>
        <li className="text-light">8047 Zürich</li>
      </ul>
    </div>
  );
};

export default Footer;
