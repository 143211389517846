import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import logo from "../img/logo_black.png";

import styles from "../css/style.module.css";
import Footer from "./components/Footer";
import Loading from "../../../components/Loading";

const Step20_Participants = () => {

    const theEvent = JSON.parse(sessionStorage.getItem("FUWEvent"));
    const remarks = JSON.parse(theEvent.remarks);
    const [orderId] = useState(sessionStorage.getItem("FUWOrderId"));
    const orderAddress = JSON.parse(sessionStorage.getItem("FUWOrderAddress"));
    const [bookedTickets, setBookedTickets] = useState([]);
    const [ticketTypeInfos, setTicketTypeInfos] = useState([]);

    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState({});
    const [token] = useState(sessionStorage.getItem("token"));

    // fetching resources
    const { language } = useParams();

    // react hook for navigation
    let history = useHistory();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        requestResources();

    }, [language]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestOrderInformation();

    }, []);

    const requestResources = () => {
        axios
            .get(`fuw2023/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestOrderInformation = () => {
        setLoading(true);

        axios
            .get(`${baseUrl}/ShopBasket/Order/${orderId}`)
            .then((res) => {
                setBookedTickets(res.data.tickets);
                res.data.tickets.map((t) => requestTicketTypeInfo(t.ticketTypeId));

                setTimeout(() => setLoading(false), 500);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketTypeInfo = (ticketTypeId) => {
        axios
            .get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`)
            .then((res) => {
                setTicketTypeInfos((current) => [...current, res.data.ticketTypeInfos[0]]);
            })
            .catch((error) => console.log(error.response.data));
    };

    const onClickFillUserData = (ticketId) => {

        bookedTickets.forEach(t => {
            if (t.id === ticketId) {
                t.firstname = "test";
                t.lastname = t.id;
            }
        });

        setBookedTickets([...bookedTickets]);
    };

    const onInputChange = (e, ticketId) => {

        bookedTickets.forEach(t => {
            if (t.id === ticketId) {
                t[e.target.name] = e.target.value;
            }
        });

        setBookedTickets([...bookedTickets]);
    };

    const mapTicketsUserData = () => {

        return (
            ticketTypeInfos &&
            ticketTypeInfos.length > 0 &&
            bookedTickets &&
            bookedTickets.length > 0 &&
            bookedTickets.map(
                (bt, index) => (
                    <div key={bt.id} className="mb-5">
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <h5 className="text-uppercase">{ticketTypeInfos.find(tti => tti.ticketTypeId === bt.ticketTypeId).name}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <small className={styles.fillUserData} onClick={() => onClickFillUserData(bt.id)}>{resources.translation.ParticipantsGetBuyerInput}</small>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <select
                                    className={"form-control " + styles.inputUserInfo}
                                    aria-label={resources.translation.ShippingSalutation}
                                    name="salutation"
                                    value={bt.salutation}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required>
                                    <option>{resources.translation.ShippingSalutation + "*"}</option>
                                    <option value={resources.translation._Mr}>{resources.translation._Mr}</option>
                                    <option value={resources.translation._Mrs}>{resources.translation._Mrs}</option>
                                </select>

                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="firstname"
                                    className={"form-control " + styles.inputUserInfo}
                                    placeholder={resources.translation.ShippingFirstName + "*"}
                                    value={bt.firstname}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="lastname"
                                    className={"form-control " + styles.inputUserInfo}
                                    placeholder={resources.translation.ShippingLastName + "*"}
                                    value={bt.lastname}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="function"
                                    className={"form-control " + styles.inputUserInfo}
                                    placeholder={resources.translation.ShippingFunction + "*"}
                                    value={bt.function}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="vrMandate"
                                    className={"form-control " + styles.inputUserInfo}
                                    placeholder={resources.translation.ShippingVrMandate}
                                    value={bt.vrMandate}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="company"
                                    className={"form-control " + styles.inputUserInfo}
                                    placeholder={resources.translation.ShippingCompany + "*"}
                                    value={bt.company}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="email"
                                    name="email"
                                    className={"form-control " + styles.inputUserInfo}
                                    placeholder={resources.translation.ShippingEmail + "*"}
                                    value={bt.email}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                )
            )
        );
    }

    const addDataToTickets = () => {

        //check if booked ticket are workshop ticket types
        let workshopTicketTypeIds = remarks["WorkshopTicketTypeIds"];

        //Todo is there a better way to map this
        for (var i = 0; i < bookedTickets.length; i++) {

            for (var j = 0; j < workshopTicketTypeIds.length; j++) {
                if (bookedTickets[i].ticketTypeId === workshopTicketTypeIds[j]) history.push(`/${language}/2023/fuw/workshops`);
            }
        }
    }

    return (
        <div>
            {loading ? (
                <Loading alignment="center" color="#d3d3d3" bgColor="#fff" position="fixed" top="50%" left="50%" />
            ) : (
                <>
                    {resources.translation && (
                        <div className={"container pt-0 " + styles.wrapper}>
                            <div className="row mt-2">
                                <div className="col-md-4 text-center">
                                    <img src={logo} className="img-fluid" alt="FUW Logo"></img>
                                </div>
                            </div>
                            <div className="row mt-5 text-center">
                                <div className={"col-md-4"}>
                                    <p className={styles.navFUW}>{resources.translation._Buyer}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className={styles.navFUWActive}>{resources.translation._Tickets}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className={styles.navFUW}>{remarks["WorkshopsTitle"]}</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12 text-center">
                                    <h2 className={"text-uppercase " + styles.underline50}>{resources.translation._Tickets}</h2>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 mb-4 text-center">
                                    <span className={styles.description}>
                                        {resources.translation.ParticipantsPleaseFill}
                                    </span>
                                </div>
                            </div>

                            {/* user data */}

                            {mapTicketsUserData()}

                            {/* next button */}

                            <div className={"row mt-5"}>
                                <div className="offset-md-10 col-md-2 text-end">
                                    <button className={"btn form-control " + styles.btnDarkblue} onClick={addDataToTickets}>
                                        {resources.translation._Next}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={"container " + styles.wrapper}>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Footer language={language} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Step20_Participants;