import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../../css/footer.module.css";
import swissmap from "../../img/footer/swiss_map.png";

const Footer = (props) => {
    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [props.language]);

    const requestResources = () => {
        axios.get(`swissclassicalworld2023/resources/${props.language}`).then((res) => {
            setResources(res.data);
        });
    };

    return (
        <div>
            {resources.translation && (
                <div className={"row text-start"}>
                    <div className={"col-md-3 pe-4"}>
                        <div className={"p-2 " + styles.footerBox}>
                            <p><b>Information</b></p>

                            <p className="m-0"><a className={styles.footerUrl} href="https://www.swissclassicworld.ch/news">Presse</a></p>
                            <p className="m-0"><a className={styles.footerUrl} href="https://www.swissclassicworld.ch/kopie-von-datenschutz">Impressum</a></p>
                            <p className="m-0"><a className={styles.footerUrl} href="https://www.swissclassicworld.ch/datenschutz">Datenschutz</a></p>
                            <p><a className={styles.footerUrl} href="https://www.swissclassicworld.ch/kontakt">Kontakt</a></p>

                            <p><b>Kontakt</b></p>

                            <p className="m-0"> SWISS CLASSIC WORLD</p>
                            <p className="m-0">c/o MARKETINGLINK GmbH</p>
                            <p className="m-0">Lidostrasse 5</p>
                            <p className="m-0">CH-6006 Luzern</p>
                        </div>
                    </div>
                    <div className={"col-md-3 pe-4"}>
                        <div className={"p-2 " + styles.footerBox}>
                            <p><b>{resources.translation.FooterOpeningTime}</b></p>

                            <p className="m-0">Freitag, 2. Juni 2023</p>
                            <p>12:00 bis 19:00 Uhr*</p>

                            <p className="m-0">Samstag, 3. Juni 2023</p>
                            <p>9:00 bis 18:00 Uhr*</p>

                            <p className="m-0">Sonntag,4. Juni 2023</p>
                            <p>9:00 bis 17:00 Uhr*</p>

                            <p className="m-0">{resources.translation.FooterAnderungen}</p>
                        </div>
                    </div>
                    <div className={"col-md-3 pe-4"}>
                        <div className={"p-2 " + styles.footerBox}>
                            <p><b>Newsletter</b></p>

                            <p className="m-0">Wir freuen uns auf Ihre</p>
                            <p className="m-0">Anmeldung und Ihr</p>
                            <p>Feedback!</p>

                            <p className="m-0">Bitte senden Sie Ihr</p>
                            <p className="m-0">Feedback und Ihre</p>
                            <p className="m-0">Anregungen per mail auf</p>
                            <p className="m-0"><a href="mailto:info@swissclassicworld.ch">info@swissclassicworld.ch</a></p>
                        </div>
                    </div>
                    <div className={"col-md-3"}>
                        <div className={"p-2 " + styles.footerBox}>
                            <p><b>LOCATION</b></p>

                            <p>Luzern/Allmend</p>

                            <p><a href="https://www.swissclassicworld.ch/location"><img src={swissmap} className="img-fluid" alt="swissmap"></img></a></p>
                        </div>
                    </div>
                </div>
            )}
            <p className="mt-5">
                {resources.translation && (
                    <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                )}
            </p>
        </div>
    );
};

export default Footer;
