import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import Reducer from "../../../utilities/Reducer";
import { useHistory, useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import Footer from "./components/Footer";
import Loading from "../../../components/Loading";

const Home = () => {
    const [orderId, setOrderId] = useState("");
    const [eventInfo, setEventInfo] = useState({});
    const [ticketTypes, setTicketTypes] = useState([]);
    const [ticketTypeInfos, setTicketTypeInfos] = useState([]);
    const [ticketTypeNumberOfAvailability, setTicketTypeNumberOfAvailability] = useState([]);
    const [selectedTicketTypes] = useState([]);
    const [promoCode, setPromoCode] = useState("");
    const [promoError, setPromoError] = useState("");
    const [isNotValid, setIsNotValid] = useState(true);
    const [loading, setLoading] = useState(true);
    const [token] = useState(sessionStorage.getItem("token"));

    // react hook for navigation
    let history = useHistory();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    const { eventId } = useParams();

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    const [languageInfo, dispatch] = useReducer(Reducer, {
        id: 0,
        language: language
    });

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    const loadToken = () => {
        axios.get("token/get/FUW").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = () => {
        axios.get(`fuw2023/formsettings`).then((res) => {
            startOrder(res.data.posId);
        });
    };

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if (orderId !== "") {
            setLoading(true);

            requestEvent();
            if (ticketTypes.length < 1) requestTicketType();

            setTimeout(() => setLoading(false), 500);
        }

    }, [orderId]); //everytime orderId is changed

    const requestResources = () => {
        axios
            .get(`fuw2023/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const startOrder = (posId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId,
            abbreviation: ""
        };

        axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("FUWOrderId", res.data.id);

                setOrderId(res.data.id);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestEvent = () => {
        axios
            .get(`${baseUrl}/Event/${eventId}`)
            .then((res) => {
                sessionStorage.setItem("FUWEvent", JSON.stringify(res.data));

                requestEventInfo(eventId);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestEventInfo = () => {
        axios
            .get(`${baseUrl}/Event/${eventId}/Infos`)
            .then((res) => {
                // instead of setting it as an object, we were adding the object to an array!
                setEventInfo(res.data.eventInfos.find((infos) => infos.languageId === languageInfo.id));
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketType = () => {
        axios
            .get(`${baseUrl}/Event/${eventId}/TicketTypes`)
            .then((res) => {
                setTicketTypes((current) => [...current, res.data.ticketTypes]);

                res.data.ticketTypes.map((tt) => requestTicketTypeNumberOfAvailableTickets(tt.id));

                res.data.ticketTypes.map((tt) => requestTicketTypeInfo(tt.id));
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestTicketTypeNumberOfAvailableTickets = (ticketTypeId) => {
        axios
            .get(`${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}`)
            .then((res) => {
                setTicketTypeNumberOfAvailability((current) => [...current, { ticketTypeId: ticketTypeId, status: res.data.status, availableTickets: res.data.availableTickets }]);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestTicketTypeInfo = (ticketTypeId) => {

        axios
            .get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`)
            .then((res) => {
                setTicketTypeInfos((current) => [...current, res.data.ticketTypeInfos[0]]);
            })
            .catch((error) => console.log(error.response.data));
    };

    const onTicketTypeChange = (e, ticketTypeId) => {
        if (selectedTicketTypes.length < 1) {
            selectedTicketTypes[0] = {
                ticketTypeId: ticketTypeId,
                quantity: e.target.value
            };
        } else {
            selectedTicketTypes.map((t) => {
                const testSelectedTicketTypes = selectedTicketTypes.filter((f) => f.ticketTypeId === ticketTypeId);

                if (testSelectedTicketTypes.length === 1) {
                    selectedTicketTypes.find((f) => f.ticketTypeId === ticketTypeId).quantity = e.target.value;
                } else {
                    selectedTicketTypes[selectedTicketTypes.length] = {
                        ticketTypeId: ticketTypeId,
                        quantity: e.target.value
                    };
                }
            });
        }

        let totalQuantity = 0;

        selectedTicketTypes.forEach((stt) => {
            totalQuantity = totalQuantity + stt.quantity * 1;
        });

        if (totalQuantity > 0) {
            setIsNotValid(false);
        } else {
            setIsNotValid(true);
        }
    };

    const mapTicketsTypes = () => {
        let ticketType = [];
        if (ticketTypes.length > 0) {
            ticketType = ticketTypes.find((tt) => tt[0].eventId == eventId);
        }

        return (
            ticketTypes &&
            ticketTypes.length > 0 &&
            ticketType &&
            ticketType.length > 0 &&
            ticketType.map(
                (tt, index) => (
                    <div key={tt.id}>
                        {ticketTypeInfos &&
                            ticketTypeInfos.length > 0 &&
                            ticketTypeInfos.length >= ticketType.length &&
                            ticketTypeNumberOfAvailability.length >= ticketType.length &&
                            ticketTypeNumberOfAvailability.find((ttnoa) => ttnoa.ticketTypeId === tt.id).status === 0 && //filter out locked by promo ticket types
                            <div className={"row pt-3 pb-4 " + styles.boxBorder}>
                                <div className="col-md-9">
                                    <label>
                                        {ticketTypeInfos.find((tti) => tti.ticketTypeId === tt.id).name}
                                    </label>
                                </div>
                                <div className="col-md-2">
                                    <label>{tt.currency} {tt.price} (exkl. MwSt.)</label>
                                </div>
                                <div className="col-md-1">
                                    <select className="form-select" onChange={(e) => onTicketTypeChange(e, tt.id)}>
                                        {Array.from(Array(ticketTypeNumberOfAvailability.find((ttnoa) => ttnoa.ticketTypeId === tt.id).availableTickets + 1),
                                            (e, i) => {
                                                return <option key={i} value={i}>{i}</option>;
                                            })}
                                    </select>
                                </div>
                            </div>
                        }
                    </div>
                )
            )
        );
    };

    const applyPromotionCode = () => {
        setLoading(true);

        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promoCode}`)
            .then((res) => {
                setPromoError("");
                setTimeout(() => setLoading(false), 500);
            })
            .catch((error) => {
                setPromoError(error.response.data);
                setTimeout(() => setLoading(false), 500);
            });
    };

    const addTicketToBasket = () => {
        selectedTicketTypes.map((tt) => {
            axios
                .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    ticketsToAdd: [
                        {
                            ticketTypeId: tt.ticketTypeId,
                            quantity: tt.quantity
                        }
                    ]
                })
                .catch((error) => {
                    alert(error.response.data);
                });
        });

        history.push(`/${language}/2023/fuw/shipping`);
    };

    const onSubmit = () => {
        addTicketToBasket();
    };

    return (
        <div>
            {/* loop the ticket types */}
            {loading ? (
                <Loading alignment="center" color="#d3d3d3" bgColor="#fff" position="fixed" top="50%" left="50%" />
            ) : (
                <>
                    <div className={"container p-0 px-2 " + styles.wrapper}>
                        <img id="banner" className="img-fluid w-100" src={eventInfo && eventInfo.bannerImagePath} alt="banner" />
                    </div>

                    {resources.translation && (
                        <div className={"container pt-0 " + styles.wrapper}>
                            <div className="row mt-5">
                                <div className="col-md-12 text-center">
                                    <h2 className={"text-uppercase " + styles.underline50}>
                                        {eventInfo && eventInfo.name}
                                    </h2>
                                </div>
                            </div>

                            <div className="row mt-3 mb-5">
                                <div className={"col-md-12 mb-4 text-center " + styles.description}>
                                    {eventInfo &&
                                        <span dangerouslySetInnerHTML={{ __html: eventInfo.longDescription }} className={styles.description}></span>
                                    }
                                </div>
                            </div>

                            {mapTicketsTypes()}

                            {/* promoCode */}
                            <div className={"row pt-3 pb-4 " + styles.boxBorder}>
                                <div className="col-md-10">
                                    <input
                                        id="inputCode"
                                        type="text"
                                        name="promotionCode"
                                        className="form-control border-0 mb-1"
                                        placeholder="Promotion-code"
                                        onChange={(e) => setPromoCode(e.currentTarget.value)}
                                        value={promoCode}
                                    />
                                    <span className={styles.errorText}>{promoError}</span>
                                </div>
                                <div className="col-md-2 text-end">
                                    <button className={"btn btn-lightblue form-control " + styles.btnLightblue} onClick={applyPromotionCode} disabled={promoCode === ""}>
                                        {resources.translation.HomeRendeem}
                                    </button>

                                </div>
                            </div>

                            <div className={"row pt-3 " + styles.boxBorder}>
                                <div className="offset-md-9 col-md-3 text-end">
                                    <button className={"btn form-control " + styles.btnDarkblue} onClick={onSubmit} disabled={isNotValid}>
                                        {resources.translation.HomeAddTobasket}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={"container " + styles.wrapper}>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Footer language={language} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Home;