import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";

import styles from "../css/style.module.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "../../../components/Loading";

const Step10_Shipping = () => {
  const [user, setUser] = useState({
    salutation: "",
    firstname: "",
    lastname: "",
    street: "",
    place: "",
    city: "",
    country: "176",
    email: "",
    confirmemail: "",
  });

  const [orderId] = useState(
    sessionStorage.getItem("SwissClassicalWorldOrderId")
  );

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(""); //Todo how can i handle these errors
  const [countries, setCountries] = useState([]);
  const [resources, setResources] = useState({});
  const [token] = useState(sessionStorage.getItem("token"));

  // fetching resources
  const { language } = useParams();

  // react hook for navigation
  let history = useHistory();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    requestResources();
    requestCountries();
  }, [language]);

  const requestResources = () => {
    axios
      .get(`fuw2023/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.log(error.response.data));
  };

  const requestCountries = () => {
    var language = window.navigator.userLanguage || window.navigator.language;

    setLoading(true);

    axios
      .get(`${baseUrl}/Countries?languageCode=${language.slice(0, 2)}`)
      .then((res) => {
        let countries = res.data;

        //first add switzerland, germany and austria
        const indexCH = countries.findIndex((c) => c.id === 176);
        const ch = countries[indexCH];
        countries.splice(indexCH, 1);

        var indexDE = res.data.findIndex((c) => c.id === 229);
        const de = countries[indexDE];
        countries.splice(indexDE, 1);

        var indexAU = res.data.findIndex((c) => c.id === 228);
        const au = countries[indexAU];
        countries.splice(indexAU, 1);

        countries.unshift({ id: "", name: "---------" });
        countries.unshift(au);
        countries.unshift(de);
        countries.unshift(ch);

        setCountries(res.data);
      })
      .catch((error) => console.log(error.response.data));

    setTimeout(() => setLoading(false), 500);
  };

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // validating input fields
  let validation =
    /^([a-z A-Zöéàäèü]{1,70})$/.test(user.salutation) &&
    /^([a-z A-Zöéàäèü - ]{1,70})$/.test(user.firstname) &&
    /^([a-z A-Zöéàäèü - ]{1,70})$/.test(user.lastname) &&
    /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.street) &&
    /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.place) &&
    /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.city) &&
    /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.country) &&
    /^\S+@\S+\.\S+$/.test(user.email) &&
    /^\S+@\S+\.\S+$/.test(user.confirmemail) &&
    user.email === user.confirmemail;

  const addAddressToBasket = () => {
    let addressBody = {
      salutation: user.salutation,
      firstname: user.firstname,
      name: user.lastname,
      street: user.street,
      postCode: user.place,
      city: user.city,
      countryId: user.country,
      email: user.email,
    };

    axios
      .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
      .then((res) => {
        history.push(`/${language}/2023/SwissClassicWorld/checkout`);
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  return (
    <div>
      {loading ? (
        <Loading
          alignment="center"
          color="#d3d3d3"
          bgColor="#fff"
          position="fixed"
          top="50%"
          left="50%"
        />
      ) : (
        <>
          {/* header */}
          <div className={styles.headerDiv}>
            <div className="row">
              <div className="col-md-12">
                <Header language={language} />
              </div>
            </div>
          </div>

          {resources.translation && (
            <div className={"container pt-0 " + styles.wrapper}>
              {/* promoCode */}
              <div className={"mt-5"}>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="fs-5">Adresse</h1>
                  </div>
                </div>
              </div>

              {/* user data */}

              <div className="row mt-4">
                <div className="col-md-12">
                  <select
                    className={"form-control " + styles.customTextbox}
                    aria-label={resources.translation.ShippingSalutation}
                    name="salutation"
                    value={user.salutation}
                    onChange={(e) => onInputChange(e)}
                    required
                  >
                    <option>
                      {resources.translation.ShippingSalutation + "*"}
                    </option>
                    <option value={resources.translation._Mr}>
                      {resources.translation._Mr}
                    </option>
                    <option value={resources.translation._Mrs}>
                      {resources.translation._Mrs}
                    </option>
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="firstname"
                    className={"form-control " + styles.customTextbox}
                    placeholder={resources.translation.ShippingFirstName + "*"}
                    value={user.firstname}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="lastname"
                    className={"form-control " + styles.customTextbox}
                    placeholder={resources.translation.ShippingLastName + "*"}
                    value={user.lastname}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="street"
                    className={"form-control " + styles.customTextbox}
                    placeholder={resources.translation.ShippingStreet + "*"}
                    value={user.street}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="place"
                    className={"form-control " + styles.customTextbox}
                    placeholder={resources.translation.ShippingPlace + "*"}
                    value={user.place}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="city"
                    className={"form-control " + styles.customTextbox}
                    placeholder={resources.translation.ShippingCity + "*"}
                    value={user.city}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <select
                    className={"form-control " + styles.customTextbox}
                    aria-label="Land"
                    name="country"
                    value={user.country}
                    onChange={(e) => onInputChange(e)}
                  >
                    {countries.map((c, index) => (
                      <option value={c.id} key={index}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    type="email"
                    name="email"
                    className={"form-control " + styles.customTextbox}
                    placeholder={resources.translation.ShippingEmail + "*"}
                    value={user.email}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    type="email"
                    name="confirmemail"
                    className={"form-control " + styles.customTextbox}
                    placeholder={
                      resources.translation.ShippingEmail + " bestätigen*"
                    }
                    value={user.confirmemail}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>

              {/* next button */}

              <div className={"row mt-5"}>
                <div className="offset-md-10 col-md-2 text-end">
                  <button
                    className={"btn " + styles.customButton}
                    onClick={addAddressToBasket}
                    disabled={!validation}
                  >
                    {resources.translation._Next}
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* footer */}
          <div className={"mt-5 " + styles.footerBgColor}>
            <div className={"container " + styles.wrapper}>
              <div className="row">
                <div className="col-md-12 text-center">
                  <Footer language={language} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Step10_Shipping;
