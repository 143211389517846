import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styles from "../css/style.module.css";
import Navbar from "./components/Navbar";

const Formular = () => {
  const [client, setClient] = useState({
    salutation: "",
    firstname: "",
    name: "",
    company: "",
    street: "",
    plz: "",
    city: "",
    country: "176",
    email: "",
  });

  const [checkboxes, setCheckboxes] = useState({
    cb1: false,
    cb2: false,
    cb3: false,
  });

  const [token] = useState(sessionStorage.getItem("token"));
  const [countries, setCountries] = useState([]);
  const [orderId] = useState(sessionStorage.getItem("orderId"));
  const [eventId] = useState(sessionStorage.getItem("eventId"));
  const [errors, setErrors] = useState("");

  let baseUrl = process.env.REACT_APP_BASEURL_API;

  let history = useHistory();
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  useEffect(() => {
    requestCountries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestCountries = () => {
    var language = window.navigator.userLanguage || window.navigator.language;

    axios
      .get(`${baseUrl}/Countries?languageCode=${language.slice(0, 2)}`)
      .then((res) => {
        let countries = res.data;

        //first add switzerland, germany and austria
        const indexCH = countries.findIndex((c) => c.id === 176);
        const ch = countries[indexCH];
        countries.splice(indexCH, 1);

        var indexDE = res.data.findIndex((c) => c.id === 229);
        const de = countries[indexDE];
        countries.splice(indexDE, 1);

        var indexAU = res.data.findIndex((c) => c.id === 228);
        const au = countries[indexAU];
        countries.splice(indexAU, 1);

        countries.unshift({ id: 0, name: "---------" });
        countries.unshift(au);
        countries.unshift(de);
        countries.unshift(ch);

        setCountries(res.data);
      });
  };

  const addAddressToBasket = () => {
    let addressBody = {
      salutation: client.salutation,
      firstname: client.firstname,
      name: client.name,
      company: client.company,
      street: client.street,
      postCode: client.plz,
      city: client.city,
      countryId: client.country,
      email: client.email,
    };

    axios
      .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
      .then((res) => {
        history.push("/2022/LightRagaz/Payment");
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const onInputChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

  const onCheckboxChange = (e) => {
    setCheckboxes({ ...checkboxes, [e.target.name]: e.target.checked });
  };

  // user information validation
  const userIsValid =
    client.salutation !== "" &&
    /^([a-z A-Zöéàäèü -]{1,70})$/.test(client.firstname) &&
    /^([a-z A-Zöéàäèü -]{1,70})$/.test(client.name) &&
    /^([a-z A-Z0-9öéàäèü -]{1,70})$/.test(client.company) &&
    /^\S+@\S+\.\S+$/.test(client.email) &&
    /^([a-z A-Z0-9öéàäèü -]{1,70})$/.test(client.street) &&
    /^([a-z A-Z0-9öéàäèü -]{1,20})$/.test(client.plz) &&
    /^([a-z A-Zöéàäèü -]{1,70})$/.test(client.city) &&
    client.country !== "" &&
    checkboxes.cb3 !== false;

  return (
    <div className={styles.pageContainer}>
      <Navbar />
      <section className={styles.section}>
        <div className={styles.boxMain + " row " + styles.kontaktBox}>
          <div className={styles.firstHalf}>
            <h2 className="text text-light fs-2 fw-semibold  text-center">
              Kontaktangaben
            </h2>
          </div>
          <div className="container m-3 text-start col-md-9">
            <div className="mb-3 row">
              <label className="col-sm-2 col-md-2 col-3 text text-light p-2">
                Anrede
              </label>
              <div className="col-sm-10 col-md-10 col-9">
                <select
                  className={
                    styles.selectNum + " salutation form-select text text-light"
                  }
                  aria-label="Default select example"
                  name="salutation"
                  value={client.salutation}
                  onChange={(e) => onInputChange(e)}
                >
                  <option defaultValue="--- Bitte wählen --">
                    --- Bitte wählen ---
                  </option>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                </select>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-2 col-md-2 col-3 text text-light p-2">
                Vorname
              </label>
              <div className="col-sm-10 col-md-10 col-9">
                <input
                  type="text"
                  name="firstname"
                  className={styles.inputCode + " form-control"}
                  id="inputCode"
                  value={client.firstname}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-2 col-md-2 col-3 text text-light p-2">
                Nachname
              </label>
              <div className="col-sm-10 col-md-10 col-9">
                <input
                  type="text"
                  name="name"
                  className={styles.inputCode + " form-control"}
                  id="inputCode"
                  value={client.name}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-2 col-md-2 col-3 text text-light p-2">
                Email
              </label>
              <div className="col-sm-10 col-md-10 col-9">
                <input
                  type="email"
                  name="email"
                  className={styles.inputCode + " form-control"}
                  id="inputCode"
                  value={client.email}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-2 col-md-2 col-3 text text-light p-2">
                Firma
              </label>
              <div className="col-sm-10 col-md-10 col-9">
                <input
                  type="text"
                  name="company"
                  className={styles.inputCode + " form-control"}
                  id="inputCode"
                  value={client.company}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-2 col-md-2 col-3 text text-light p-2">
                Strasse
              </label>
              <div className="col-sm-10 col-md-10 col-9">
                <input
                  type="text"
                  name="street"
                  className={styles.inputCode + " form-control"}
                  id="inputCode"
                  value={client.street}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-2 col-md-2 col-3 text text-light p-2">
                PLZ/Stadt
              </label>
              <div className="col">
                <input
                  type="text"
                  className={styles.inputCode + " form-control"}
                  id="inputCode"
                  name="plz"
                  value={client.plz}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className={styles.inputCode + " form-control"}
                  id="inputCode"
                  name="city"
                  value={client.city}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-2 col-md-2 col-3 text text-light p-2">
                Land
              </label>
              <div className="col-sm-10 col-md-10 col-9">
                <select
                  className={
                    styles.selectNum +
                    " salutation form-select form-select text text-light"
                  }
                  aria-label="Default select example"
                  name="country"
                  value={client.country}
                  onChange={(e) => onInputChange(e)}
                >
                  {countries.map((c, index) => (
                    <option value={c.id} key={index} className="options">
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <hr className="solid mt-4 mb-4"></hr>

            <div className="form-check mb-3 p-0">
              <input
                className={styles.formCheckInput + " cb1 check-input"}
                name="cb1"
                type="checkbox"
                checked={checkboxes.cb1}
                value={checkboxes.cb1}
                id="check-input"
                onChange={(e) => onCheckboxChange(e)}
              />
              <label
                className="form-check-label text text-light fw-light ms-4"
                htmlFor="flexCheckDefault"
              >
                Ich bin einverstanden, dass mir die Light Ragaz AG individuelle
                Informationen zukommen lassen kann.
              </label>
            </div>

            <div className="form-check mb-3 p-0">
              <input
                className={styles.formCheckInput + " cb2 check-input"}
                name="cb2"
                type="checkbox"
                checked={checkboxes.cb2}
                value={checkboxes.cb2}
                id="check-input"
                onChange={(e) => onCheckboxChange(e)}
              />
              <label
                className="form-check-label text text-light fw-light ms-4"
                htmlFor="flexCheckDefault"
              >
                Ich bin einverstanden, dass mir die PostAuto AG individuelle
                Informationen zukommen lassen kann.
              </label>
            </div>

            <div className="form-check mb-5 p-0">
              <input
                className={styles.formCheckInput + " cb3 check-input"}
                name="cb3"
                type="checkbox"
                checked={checkboxes.cb3}
                value={checkboxes.cb3}
                id="check-input"
                onChange={(e) => onCheckboxChange(e)}
              />
              <label
                className="form-check-label text text-light fw-light ms-4"
                htmlFor="flexCheckDefault"
              >
                Ich akzeptiere die allgemeinen Geschäftsbedingungen.
              </label>
            </div>

            {errors !== "" && (
              <div className="row">
                {typeof errors === `string` ? (
                  <p className="text text-danger ps-0">{errors}*</p>
                ) : (
                  <p className="text text-danger ps-0">
                    {errors.errors.Email}*
                  </p>
                )}
              </div>
            )}

            <div className="mb-3 row">
              <button
                className={styles.button + " btn col-md-3 col-3 me-3"}
                onClick={() => {
                  history.push(`/2022/LightRagaz/Shop/${eventId}`);
                }}
              >
                Zurück
              </button>
              <button
                type="submit"
                className={styles.button + " btn col-md-3 col-3"}
                disabled={!userIsValid}
                onClick={addAddressToBasket}
              >
                Bestellen
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Formular;
