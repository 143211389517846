import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import logo from "../images/siemens.png";

const Home = () => {
  const [token, setToken] = useState("");
  const [orderId, setOrderId] = useState("");
  const [ticketType, setTicketType] = useState([]);
  const [errors, setErrors] = useState("");

  const [team, setTeam] = useState({
    salutation: "",
    name: "",
    leadFirstname: "",
    leadLastname: "",
    countTeammates: 5,
  });

  const [players, setPlayers] = useState({
    player1: "",
    player2: "",
    player3: "",
    player4: "",
    player5: "",
    player6: "",
    player7: "",
    player8: "",
    player9: "",
    player10: "",
  });

  // react hook for navigation
  let history = useHistory();

  // fetching resources
  const [resources, setResources] = useState({});

  const { language } = useParams();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  useEffect(() => {
    requestResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const requestResources = () => {
    axios.get(`wildtrophy2022/resources/${language}`).then((res) => {
      setResources(res.data);
    });
  };

  const requestFormSettings = () => {
    axios.get(`wildtrophy2022/formsettings`).then((res) => {
      requestTicketType(res.data.eventId);
    });
  };

  useEffect(() => {
    loadToken();
  }, []);

  useEffect(() => {
    if (token !== "") {
      startOrder();
      requestFormSettings();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const loadToken = () => {
    axios.get("token/get/Wildtrophy").then((res) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

      sessionStorage.setItem("token", res.data);
      setToken(res.data);
    });
  };

  const startOrder = () => {
    let order = {
      affiliateShopId: null,
      currency: "CHF",
      tenantId: 1,
      pointOfSaleId: 6585,
      abbreviation: "",
    };

    axios
      .post(`${baseUrl}/ShopBasket/Order`, order)
      .then((res) => {
        sessionStorage.setItem("orderId", res.data.id);

        setOrderId(res.data.id);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const requestTicketType = (id) => {
    axios
      .get(`${baseUrl}/Event/${id}/TicketTypes`)
      .then((res) => {
        setTicketType(res.data.ticketTypes[0]);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const addTicketToBasket = () => {
    axios
      .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
        ticketsToAdd: [
          {
            ticketTypeId: ticketType.id,
            quantity: team.countTeammates + 1,
          },
        ],
      })
      .then(() => requestOrder())
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const requestOrder = () => {
    axios
      .get(`${baseUrl}/ShopBasket/Order/${orderId}`)
      .then((res) => {
        res.data.tickets.map((t, index) =>
          updateAddressOfTicket(t.id, index + 1)
        );

        addAddressToBasket();
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const addAddressToBasket = () => {
    let body = {
      salutation: team.salutation,
      code1: team.name,
      firstName: team.leadFirstname,
      name: team.leadLastname,
    };

    axios
      .put(`${baseUrl}/Order/${orderId}/Address`, body)
      .then(() => {
        confirmOrder();
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const updateAddressOfTicket = (ticketTypeId, index) => {
    let name = players["player" + index];
    let space = name.indexOf(" ");

    let firstname = "";
    let lastname = "";

    if (index > team.countTeammates) {
      firstname = team.leadFirstname;
      lastname = team.leadLastname;
    } else {
      firstname = name.substring(0, space);
      lastname = name.substring(space + 1, name.length);
    }

    axios
      .put(`${baseUrl}/Ticket/${ticketTypeId}/Address`, {
        firstName: firstname,
        name: lastname,
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const confirmOrder = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
      .then(() => {
        history.push(`/${language}/2022/WildTrophy/Confirm`);
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const mapPlayerInputFields = () => {
    let players = [];

    for (let index = team.countTeammates; index > 0; index--) {
      let value = "player" + index;

      players.unshift(
        <div className="mb-4 row" key={index}>
          <label className="col-sm-3 col-md-3 col-12 text text-light">
            {resources.translation
              ? resources.translation["Member" + index] + "*"
              : ""}
          </label>
          <div className="col-sm-9 col-md-9 col-12">
            <input
              type="text"
              name={"player" + index}
              className="form-control player"
              id="inputCode"
              value={players[value]}
              onChange={(elem) => onInputChange(elem, value)}
            />
          </div>
        </div>
      );
    }

    return players;
  };

  const onInputChange = (elem, player) => {
    let value = elem.currentTarget.value;

    setPlayers({ ...players, [player]: value });
  };

  const onSubmit = () => {
    addTicketToBasket();
  };

  // validating the input fields
  let isValidSum = 0;

  for (let index = 1; index < team.countTeammates + 1; index++) {
    const element = players["player" + index];

    if (
      /^([a-z A-Zöéàäèü 0-9]{1,70})$/.test(element) &&
      element !== undefined
    ) {
      isValidSum = isValidSum + 1;
    }
  }

  let validation =
    /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(team.name) &&
    /^([a-z A-Zöéàäèü - ]{1,70})$/.test(team.leadFirstname) &&
    /^([a-z A-Zöéàäèü - ]{1,70})$/.test(team.leadLastname) &&
    team.salutation !== "" &&
    isValidSum >= team.countTeammates;

  return (
    <div className={styles.pageContainer + " " + styles.fontFace}>
      <img src={logo} alt="Siemens Logo" className={styles.logo}></img>
      <section className={styles.section} id="section">
        <div className={styles.boxMain + " row"}>
          <div className={styles.firstHalf}>
            <div>
              <h1 className="text text-start text-light">
                {resources.translation ? resources.translation.Title : ""}
              </h1>
              <p className="text mt-4 text-light">
                {resources.translation ? resources.translation.Description : ""}
              </p>
            </div>
            <div className="mb-3">
              <p className="text text-light">
                {resources.translation ? resources.translation.Note : ""}
              </p>
            </div>
          </div>
          <div className="">
            <div className="mb-4 row">
              <label className="col-sm-3 col-md-3 col-12 text text-light">
                {resources.translation ? resources.translation.Salutation : ""}*
              </label>

              <div className="col-md-9 col-sm-9 col-8 row ms-1">
                <div className="col-md-3 col-sm-12">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={team.salutation === "Herr" ? true : false}
                    onChange={() => setTeam({ ...team, salutation: "Herr" })}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Herr
                  </label>
                </div>
                <div className="col-md-3 col-sm-12">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={team.salutation === "Frau" ? true : false}
                    onChange={() => setTeam({ ...team, salutation: "Frau" })}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Frau
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4 row">
              <label className="col-sm-3 col-md-3 col-12 text text-light">
                {resources.translation
                  ? resources.translation.TeamName + "*"
                  : ""}
              </label>
              <div className="col-sm-9 col-md-9 col-12">
                <input
                  type="text"
                  name="teamName"
                  className="form-control teamName"
                  id=""
                  value={team.name}
                  onChange={(elem) =>
                    setTeam({ ...team, name: elem.currentTarget.value })
                  }
                />
              </div>
            </div>
            <div className="mb-4 row">
              <label className="col-sm-3 col-md-3 col-12 text text-light">
                {resources.translation
                  ? resources.translation.TeamLeadFirstName + "*"
                  : ""}
              </label>
              <div className="col-sm-9 col-md-9 col-12">
                <input
                  type="text"
                  name="teamLeadFirstName"
                  className="form-control teamLeadFirstName"
                  id="inputCode"
                  value={team.leadFirstname}
                  onChange={(elem) =>
                    setTeam({
                      ...team,
                      leadFirstname: elem.currentTarget.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="mb-4 row">
              <label className="col-sm-3 col-md-3 col-12 text text-light">
                {resources.translation
                  ? resources.translation.TeamLeadLastName + "*"
                  : ""}
              </label>
              <div className="col-sm-9 col-md-9 col-12">
                <input
                  type="text"
                  name="teamLeadLastName"
                  className="form-control teamLeadLastName"
                  id="inputCode"
                  value={team.leadLastname}
                  onChange={(elem) =>
                    setTeam({ ...team, leadLastname: elem.currentTarget.value })
                  }
                />
              </div>
            </div>
            <div className="mb-4 row">
              <label className="col-sm-3 col-md-3 col-12 text text-light">
                {resources.translation ? resources.translation.CountMember : ""}
              </label>
              <div className="col-sm-9 col-md-9 col-12">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={team.countTeammates}
                  onChange={(elem) =>
                    setTeam({
                      ...team,
                      countTeammates: elem.currentTarget.value,
                    })
                  }
                >
                  <option defaultValue value="5">
                    5
                  </option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>

            <>{mapPlayerInputFields()}</>

            <div className="mb-4 mt-5">
              <p className="text text-light text-center">
                {resources.translation
                  ? resources.translation.TermsAndConditions
                  : ""}
              </p>
            </div>
            <div className="mb-4">
              <p className="text text-light text-start">
                {resources.translation ? resources.translation.Reminder : ""}
              </p>
            </div>
            {errors !== "" && (
              <div className="mb-3">
                <p className="text text-danger">{errors}*</p>
              </div>
            )}
            <div className="flex-end">
              <button
                className={styles.button + " btn"}
                style={{
                  pointerEvents: validation ? `` : `none`,
                  backgroundColor: validation ? `` : `#a0a1b0`,
                  border: validation ? `` : `1px solid #000028`,
                  color: validation ? `` : `#000028`,
                }}
                onClick={onSubmit}
              >
                {resources.translation ? resources.translation.ButtonSend : ""}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
