import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Reducer from "../../../utilities/Reducer";

const Step20_Checkout = () => {
  const [show, setShow] = useState(0);
  const [resources, setResources] = useState({});
  const [orderId] = useState(
    sessionStorage.getItem("SwissClassicalWorldOrderId")
  );
  const [token] = useState(sessionStorage.getItem("token"));
  const [order, setOrder] = useState([]);
  const [orderInfo, setOrderInfo] = useState({});
  const [bookedTicketTypeInfo, setBookedTicketTypeInfo] = useState([]);
  const [requested, setRequested] = useState(0);
  const [insurance, setInsurance] = useState(true);
  const [insuranceTotalAmount, setInsuranceTotalAmount] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [promotionSuccess] = useState(
    sessionStorage.getItem("promotionSuccess")
  );
  const [promotionCode] = useState(sessionStorage.getItem("promotionCode"));

  // react hook for navigation
  let history = useHistory();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  // fetching resources
  const { language } = useParams();

  const [languageInfo, dispatch] = useReducer(Reducer, {
    id: 0,
    language: language,
  });

  const [payment, setPayment] = useState({
    currency: "CHF",
    paymentType: 0,
    deliveryType: 0,
  });

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  useEffect(() => {
    if (requested === 0) {
      requestCompletedOrder();
    }

    setOrderInsuranceTotalAmount();

    if (orderInfo && orderInfo.totalInsuranceAmount > 0) {
      setInsurance(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    requestResources();
  }, [language]);

  const requestResources = () => {
    axios
      .get(`swissclassicalworld2023/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.log(error.response.data));
  };

  const requestCompletedOrder = () => {
    axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`).then((res) => {
      setOrder(res.data.tickets);
      setOrderInfo(res.data);
      setTotalPrice(res.data.totalPrice);

      setPayment({
        ...payment,
        deliveryType: res.data.deliveryMethodId,
        paymentType: res.data.paymentMethodId,
      });
      res.data.tickets.map((t) => requestBookedTicketTypeInfo(t.ticketTypeId));

      // prevent overwriting
      setRequested(requested + 1);
    });
  };

  const setTotalAmountWithInsurance = (condition) => {
    setInsurance(!condition);

    if (!condition) {
      setTotalPrice(totalPrice + insuranceTotalAmount);
    } else {
      setTotalPrice(totalPrice - insuranceTotalAmount);
    }
  };

  const requestBookedTicketTypeInfo = (ticketTypeId) => {
    axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`).then((res) => {
      setBookedTicketTypeInfo((current) => [
        ...current,
        res.data.ticketTypeInfos.find(
          (tti) => tti.languageId === languageInfo.id
        ),
      ]);
      setShow(true);
    });
  };

  const changeDeliveryType = (deliveryType) => {
    axios
      .put(
        `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryType}`
      )
      .then((res) => {
        setPayment({ ...payment, deliveryType: deliveryType });
        setOrderInfo(res.data);
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const changePaymentType = (paymentType) => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentType}`)
      .then((res) => {
        setPayment({ ...payment, paymentType: paymentType });
        setOrderInfo(res.data);
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const setOrderInsurance = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/Insurance`)
      .then((res) => {
        setInsurance(true);
        setOrderInfo(res.data);

        let total = orderInfo.totalPrice;

        if (orderInfo.totalInsuranceAmount === 0 && insurance) {
          total = total + insuranceTotalAmount;
        }

        total = Math.round(total * 100) / 100;

        let price = total.toFixed(2) + " CHF";

        sessionStorage.setItem("totalPrice", price);

        let dataTransProviders = [1, 7, 8, 9, 10, 14, 11];

        if (
          dataTransProviders.findIndex((d) => d === payment.paymentType) !== -1
        ) {
          startDatatrans();
        } else {
          history.push(`/${language}/2023/SwissClassicWorld/confirmation`);
        }
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const setOrderInsuranceTotalAmount = () => {
    let amount = 0.0;

    order.map((ticket) => {
      if (ticket.price < 50) {
        amount = amount + 0.7;
      } else if (ticket.price > 50 && ticket.price < 75) {
        amount = amount + 0.95;
      }
    });

    setInsuranceTotalAmount(Math.round(100 * amount) / 100);
  };

  // calling the popup
  const startDatatrans = () => {
    var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

    axios
      .get(baseRequestUrl)
      .then((res) => {
        let datatransFormId = "datatrans-" + new Date().getTime();
        var form =
          "<form class='datatrans' id='" + datatransFormId + "'></form>";

        let container = document.getElementById("datatrans-form-placeholder");

        container.innerHTML += form;

        let element = document.getElementById(datatransFormId);

        // merchantId for testing
        // element.setAttribute("data-merchant-id", "1100004624");
        element.setAttribute("data-merchant-id", res.data.merchantId);
        element.setAttribute("data-amount", res.data.amount);
        element.setAttribute("data-currency", res.data.currency);
        element.setAttribute("data-refno", res.data.referenceNumber);
        element.setAttribute("data-reqType", res.data.reqType);
        element.setAttribute(
          "data-upp-return-target",
          res.data.uppReturnTarget
        );
        element.setAttribute("data-paymentMethod", res.data.paymentMethod);
        element.setAttribute("data-sign", res.data.digitalSignature);

        let domain = "https://" + window.location.host;

        let successUrl = domain + "/swissclassicalworld2023/redirect/success";
        let errorUrl = domain + "/swissclassicalworld2023/redirect/error";
        let cancelUrl = domain + "/swissclassicalworld2023/redirect/cancel";

        if (window.location.host === "forms.ticketino.com") {
          successUrl = domain + "swissclassicalworldticketino/redirect";
        }

        element.setAttribute("data-success-url", successUrl);
        element.setAttribute("data-error-url", errorUrl);
        element.setAttribute("data-cancel-url", cancelUrl);

        for (const key in res.data.userInfo) {
          element.setAttribute(key, res.data.userInfo[key]);
        }

        for (const key in res.data.merchantSpecificParameters) {
          element.setAttribute(key, res.data.merchantSpecificParameters[key]);
        }

        // start datatrans -> call the payment form
        window.Datatrans.startPayment({
          form: "#" + datatransFormId,
        });
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  // // confirm the order
  // const confirmOrder = () => {
  //   axios
  //     .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
  //     .then(() => {
  //       history.push(`/${language}/2023/SwissClassicWorld/confirmation`);
  //     })
  //     .catch((error) => {
  //       alert(error.response.data);
  //     });
  // };

  const finishOrder = () => {
    if (insurance) {
      setOrderInsurance();
    } else {
      let total = orderInfo.totalPrice;

      if (orderInfo.totalInsuranceAmount === 0 && insurance) {
        total = total + insuranceTotalAmount;
      }

      total = Math.round(total * 100) / 100;

      let price = total.toFixed(2) + " CHF";

      sessionStorage.setItem("totalPrice", price);
      let dataTransProviders = [1, 7, 8, 9, 10, 14, 11];

      if (
        dataTransProviders.findIndex((d) => d === payment.paymentType) !== -1
      ) {
        startDatatrans();
      } else {
        history.push(`/${language}/2023/SwissClassicWorld/confirmation`);
      }
    }
  };

  const mapSelectedTickets = () => {
    return (
      <div>
        {order.map((ticket, index) => (
          <p
            className={
              "d-flex justify-content-between align-items-start mb-2 fs-6"
            }
            key={index}
          >
            {bookedTicketTypeInfo.find(
              (btti) => btti.ticketTypeId === ticket.ticketTypeId
            ) &&
              bookedTicketTypeInfo.find(
                (btti) => btti.ticketTypeId === ticket.ticketTypeId
              ).name}

            <span></span>
            {ticket.price + " " + ticket.currency}
          </p>
        ))}
      </div>
    );
  };

  const mapExtraCosts = () => {
    return (
      <div>
        <p
          className={
            "d-flex justify-content-between align-items-start mb-2 fs-6"
          }
        >
          Kosten für Lieferung und Zahlung
          <span></span>
          {orderInfo.deliverPaymentFee.toFixed(2) + " " + orderInfo.currency}
        </p>
      </div>
    );
  };

  const mapInsuranceOfTickets = () => {
    let length = bookedTicketTypeInfo.length;

    return (
      <div>
        <p
          className={
            "d-flex justify-content-between align-items-start mb-2 fs-6"
          }
        >
          Ticketversicherung
          <span></span>
          {insuranceTotalAmount.toFixed(2) + " " + orderInfo.currency}
        </p>
      </div>
    );
  };

  const totalSelectedTickets = () => {
    let total = orderInfo.totalPrice;

    if (orderInfo.totalInsuranceAmount === 0 && insurance) {
      total = total + insuranceTotalAmount;
    }

    total = Math.round(total * 100) / 100;

    return (
      <div>
        <p className={"d-flex justify-content-between align-items-start mb-2"}>
          <p className="fw-bold fs-6">Total</p>
          <span></span>
          <p className="fw-bold fs-6">
            {orderInfo && total.toFixed(2) + " " + orderInfo.currency}
          </p>
        </p>
      </div>
    );
  };

  return (
    <div>
      <div id="datatrans-form-placeholder"></div>

      {/* header */}
      <div className={styles.headerDiv}>
        <div className="row">
          <div className="col-md-12">
            <Header language={language} />
          </div>
        </div>
      </div>

      {resources.translation && (
        <div className={"container pt-0 " + styles.wrapper}>
          <div className={"mt-5"}>
            <div className="row">
              <div className="col-md-12 mb-2">
                <h1 className="fs-5">Lieferart</h1>
              </div>
            </div>
            <div className="mb-5">
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-3 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault1"
                  checked={payment.deliveryType === 1 ? true : false}
                  onClick={() => changeDeliveryType(1)}
                  id="printAtHome"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  Print At Home
                  <p className="pt-1 m-0">
                    Keine Versandkosten, Sie drucken Ihr Ticket selber aus.
                  </p>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-3 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault1"
                  checked={payment.deliveryType === 2 ? true : false}
                  onClick={() => changeDeliveryType(2)}
                  id="postMail"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  Postversand
                  <p className="pt-1 m-0">
                    Es fallen geringe Versandkosten an.
                  </p>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <h1 className="fs-5">Zahlungsart</h1>
              </div>
            </div>
            <div className="mb-5">
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-3 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault"
                  checked={payment.paymentType === 11 ? true : false}
                  onClick={() => changePaymentType(11)}
                  id="sofort"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  SOFORT Überweisung
                  <p className="pt-1 m-0">Keine Zusatzgebühr</p>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-3 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault"
                  checked={payment.paymentType === 10 ? true : false}
                  onClick={() => changePaymentType(10)}
                  id="paypal"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  PayPal
                  <p className="pt-1 m-0">
                    Zusatzgebühr von CHF + 4.9%; EUR + 3.9%
                  </p>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-3 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault"
                  checked={payment.paymentType === 1 ? true : false}
                  onClick={() => changePaymentType(1)}
                  id="mastercard"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  Mastercard
                  <p className="pt-1 m-0">Keine Zusatzgebühr</p>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-3 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault"
                  checked={payment.paymentType === 7 ? true : false}
                  onClick={() => changePaymentType(7)}
                  id="visa"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  Visa
                  <p className="pt-1 m-0">Keine Zusatzgebühr</p>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-3 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault"
                  checked={payment.paymentType === 8 ? true : false}
                  onClick={() => changePaymentType(8)}
                  id="amex"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  American Express
                  <p className="pt-1 m-0">Keine Zusatzgebühr</p>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-3 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault"
                  checked={payment.paymentType === 9 ? true : false}
                  onClick={() => changePaymentType(9)}
                  id="postfinance"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  PostFinance
                  <p className="pt-1 m-0">
                    Zusatzgebühr von 2% für den Ticketkäufer
                  </p>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-3 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault"
                  checked={payment.paymentType === 14 ? true : false}
                  onClick={() => changePaymentType(14)}
                  id="twint"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  Twint
                  <p className="pt-1 m-0">
                    Zusatzgebühr von 1.3% für den Ticketkäufer
                  </p>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <h1 className="fs-5">Ticketversicherung</h1>
              </div>
            </div>
            <div class="form-check mb-5">
              <input
                className={"form-check-input me-4 " + styles.formCheckInput}
                type="checkbox"
                onClick={() => setTotalAmountWithInsurance(insurance)}
                checked={insurance}
              />
              <label className="form-check-label fs-6" for="inlineCheckbox1">
                Mit dem Abschluss der Ticketversicherung{" "}
                <span className="fw-bold">
                  ({insuranceTotalAmount.toFixed(2) + " CHF"})
                </span>{" "}
                erhalten Sie im Verhinderungsfall den Ticketpreis zurück
                erstattet.
              </label>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <h1 className="fs-5">Zusammenfassung</h1>
              </div>
            </div>
            <div>
              {mapSelectedTickets()}
              {insurance && mapInsuranceOfTickets()}
              {orderInfo.deliverPaymentFee > 0 && mapExtraCosts()}
              <hr></hr>
              {totalSelectedTickets()}
              {promotionSuccess == "true" && (
                <p className="text text-success fs-6">
                  Der Promocode wurde erfolgreich eingelöst{" "}
                  {"[ " + promotionCode + " ]"}
                </p>
              )}
            </div>
          </div>
          {/* next button */}
          <div className={"row mt-5"}>
            <div className="offset-md-10 col-md-2 text-end">
              <button
                className={"btn form-control " + styles.customButton}
                onClick={() => finishOrder()}
                disabled={
                  payment.paymentType === 0 && payment.deliveryType === 0
                }
              >
                Bestellen
              </button>
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div className={"mt-5 " + styles.footerBgColor}>
        <div className={"container " + styles.wrapper}>
          <div className="row">
            <div className="col-md-12 text-center">
              <Footer language={language} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step20_Checkout;
