import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import Header from "./components/Header";

const Home = () => {
  const [orderId, setOrderId] = useState("");

  const [user, setUser] = useState({
    salutation: "",
    firstname: "",
    name: "",
    company: "",
    street: "",
    postCode: "",
    region: "",
    email: "",
    allergies: "",
  });

  const [errors, setErrors] = useState("");

  // react hook for navigation
  let history = useHistory();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  // fetching resources
  const [resources, setResources] = useState({});

  const { language } = useParams();

  useEffect(() => {
    requestResources();
  }, [language]);

  const requestResources = () => {
    axios
      .get(`geologentag2022/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.error(error.response.data));
  };

  useEffect(() => {
    loadToken();
  }, []);

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const loadToken = () => {
    axios.get("token/get/Geologentag").then((res) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

      sessionStorage.setItem("token", res.data);

      requestFormSettings();
    });
  };

  const requestFormSettings = () => {
    axios.get(`geologentag2022/formsettings`).then((res) => {
      startOrder(res.data.posId);
    });
  };

  const startOrder = (posId) => {
    let order = {
      affiliateShopId: null,
      currency: "CHF",
      tenantId: 1,
      pointOfSaleId: posId,
      abbreviation: "",
    };

    axios
      .post(`${baseUrl}/ShopBasket/Order`, order)
      .then((res) => {
        sessionStorage.setItem("orderId", res.data.id);

        setOrderId(res.data.id);
      })
      .catch((error) => console.error(error.response.data));
  };

  const addAddressToBasket = () => {
    let body = {
      salutation: user.salutation,
      firstname: user.firstname,
      name: user.name,
      company: user.company,
      street: user.street,
      postCode: user.postCode,
      city: user.region,
      email: user.email,
      code1: user.allergies,
      countryId: 176,
    };

    axios
      .put(`${baseUrl}/Order/${orderId}/Address`, body)
      .then(() => {
        history.push(`/${language}/2022/Geologentag/Shop`);
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
      });
  };

  const onClickNextButton = () => {
    addAddressToBasket();
  };

  // validating input fields
  let validation =
    /^([a-z A-Zöéàäèü]{1,70})$/.test(user.salutation) &&
    /^([a-z A-Zöéàäèü - ]{1,70})$/.test(user.firstname) &&
    /^([a-z A-Zöéàäèü - ]{1,70})$/.test(user.name) &&
    /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.company) &&
    /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.street) &&
    /^([0-9]{4,70})$/.test(user.postCode) &&
    /^([a-z A-Zöéàäèü - ]{1,70})$/.test(user.region) &&
    /^([a-z A-Zöéàäèü 0-9 - ]{0,70})$/.test(user.allergies) &&
    /^\S+@\S+\.\S+$/.test(user.email);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.languageSelection}>
        <button
          className={styles.buttonLanguage + " btn"}
          onClick={() => history.push(`/de/2022/Geologentag/Home`)}
        >
          {resources.translation && resources.translation.German}
        </button>
        <div className={styles.vr + " vr ms-2 me-2 mt-2"}></div>
        <button
          className={styles.buttonLanguage + " btn"}
          onClick={() => history.push(`/fr/2022/Geologentag/Home`)}
        >
          {resources.translation && resources.translation.French}
        </button>
      </div>
      <section className={styles.section} id="section-geology">
        <div className={styles.boxMain + " row"}>
          <Header language={language} />
          <hr className="hr"></hr>
          {resources.translation && (
            <>
              <p className="text fw-bold fs-4  ps-3">
                {resources.translation.Register}
              </p>
              <div className="ps-4 pe-4">
                <div className="mb-3 row">
                  <label className="col-sm-3 col-md-2 col-3 text p-2">
                    {resources.translation.Salutation}*
                  </label>
                  <div className="col-sm-9 col-md-10 col-9">
                    <select
                      className="salutation form-select text geology"
                      aria-label="Default select example"
                      name="salutation"
                      value={user.salutation}
                      onChange={(e) => onInputChange(e)}
                    >
                      <option defaultValue="--- Bitte wählen --">
                        {resources.translation.PleaseChoose}
                      </option>
                      <option value="Herr">
                        {resources.translation.SalutationMan}
                      </option>
                      <option value="Frau">
                        {resources.translation.SalutationWoman}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-md-2 col-3 text p-2">
                    {resources.translation.Firstname}*
                  </label>
                  <div className="col-sm-9 col-md-10 col-9">
                    <input
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="inputCode"
                      value={user.firstname}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-md-2 col-3 text p-2">
                    {resources.translation.Name}*
                  </label>
                  <div className="col-sm-9 col-9 col-md-10">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="inputCode"
                      value={user.name}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-md-2 col-3 text p-2">
                    {resources.translation.Company}*
                  </label>
                  <div className="col-sm-9 col-9 col-md-10">
                    <input
                      type="text"
                      name="company"
                      className="form-control"
                      id="inputCode"
                      value={user.company}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-md-2 col-3 text p-2">
                    {resources.translation.Street}*
                  </label>
                  <div className="col-sm-9 col-9 col-md-10">
                    <input
                      type="text"
                      name="street"
                      className="form-control"
                      id="inputCode"
                      value={user.street}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-md-2 col-3 text p-2">
                    {resources.translation.PostCode} /{" "}
                    {resources.translation.Region}*
                  </label>
                  <div className="col-sm col col-md">
                    <input
                      type="text"
                      className="form-control"
                      id="inputCode"
                      name="postCode"
                      value={user.postCode}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                  <div className="col-sm col col-md">
                    <input
                      type="text"
                      className="form-control"
                      id="inputCode"
                      name="region"
                      value={user.region}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-md-2 col-3 text p-2">
                    {resources.translation.Email}*
                  </label>
                  <div className="col-sm-9 col-9 col-md-10">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="inputCode"
                      value={user.email}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-md-2 col-3 text p-2">
                    {resources.translation.Allergies}
                  </label>
                  <div className="col-sm-9 col-9 col-md-10">
                    <input
                      type="text"
                      name="allergies"
                      className="form-control"
                      id="inputCode"
                      value={user.allergies}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>

                {errors !== "" && (
                  <div className="row">
                    <p className="text text-danger ps-2">{errors.Email}*</p>
                  </div>
                )}
              </div>
              <div className="mt-2 ps-3">
                <button
                  className={styles.button}
                  onClick={onClickNextButton}
                  disabled={!validation}
                >
                  {resources.translation && resources.translation.Next}
                </button>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default Home;
