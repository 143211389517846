import axios from "axios";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { useHistory } from "react-router-dom";

import "../css/calendar.css";
import styles from "../css/style.module.css";
import Navbar from "./components/Navbar";

const Home = () => {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(new Date());
  const [currentDate] = useState(new Date());
  const [showTime, setShowTime] = useState(false);
  const [events, setEvents] = useState([]);

  // react hook for navigation
  let history = useHistory();

  let baseUrl = process.env.REACT_APP_BASEURL_API;

  useEffect(() => {
    loadToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadToken = () => {
    axios.get("token/get/Lightragaz").then((res) => {
      sessionStorage.setItem("token", res.data);
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

      requestFormSettings();
    });
  };

  const requestFormSettings = () => {
    axios.get(`lightragaz2022/formsettings`).then((res) => {
      startOrder(res.data.posId);
      requestEvents(res.data.organizerId);
    });
  };

  // starting the order
  const startOrder = (posId) => {
    let orderBody = {
      affiliateShopId: null,
      currency: "CHF",
      tenantId: 1,
      pointOfSaleId: posId,
      abbreviation: "",
    };

    axios
      .post(`${baseUrl}/ShopBasket/Order`, orderBody)
      .then((res) => {
        sessionStorage.setItem("orderId", res.data.id);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  // fetching the events (to display it ont he sidebar)
  const requestEvents = (id) => {
    // get current date
    let date = new Date().toISOString().substring(0, 10);

    axios
      .get(`${baseUrl}/Events?organizerId=${id}&filterText=&fromDate=${date}`)
      .then((res) => setEvents(res.data.events))
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const showSidebar = () => {
    setShow(true);
    setShowTime(true);
  };

  const onClickTime = (eventId) => {
    sessionStorage.setItem("eventId", eventId);
    history.push(`/2022/LightRagaz/Shop/${eventId}`);
  };

  // sidebar components (sidebar, times)
  const sidebar = (date) => {
    return (
      <nav className={styles.sidebar + " sidebar-dark p-3"}>
        <ul className={styles.sideList}>
          <button
            className={styles.sidebarButton}
            onClick={() => setShow(false)}
          >
            ×
          </button>
          <li>
            <p className="text text-light m-1 fs-1 fw-bold">{date}</p>
            <p className="text text-light m-1 fw-bold fs-1"></p>
            <hr className={styles.sidehr}></hr>
          </li>
          <li className="timeButton">
            <div className="container">{showTime && times(date)}</div>
          </li>
        </ul>
      </nav>
    );
  };

  const times = (date) => {
    return (
      events.length &&
      events.map((e, index) => (
        <div key={index}>
          {e.openDoor.substring(0, 10).includes(date) && (
            <button
              className={styles.button + " btn mb-3 fs-4 fw-bold p-3"}
              onClick={() => onClickTime(e.id)}
            >
              {e.openDoor.substring(11, 16)}
            </button>
          )}
        </div>
      ))
    );
  };

  date.setDate(date.getDate() + 1);
  let sidebarDate = date.toISOString().substring(0, 10);

  return (
    <div className={styles.pageContainer}>
      <Navbar />
      {show && sidebar(sidebarDate)}
      <div className="container">
        <div className={styles.flexContainer + " m-3"}>
          <div className="mb-4">
            <h2 className="text text-light fs-2 text-center">Einzeltickets</h2>
            <h2 className="text text-light text-center fs-4 fw-light">
              Datum und Uhrzeit wählen
            </h2>
          </div>
          <div className={styles.calendarContainer + " mb-5"}>
            <Calendar
              minDate={currentDate}
              value={date}
              onChange={setDate}
              onClickDay={showSidebar}
            />
          </div>
          <div className="container text-center">
            <button className={styles.button + " btn me-3"}>PREISE</button>
            <button className={styles.button + " btn"}>GUTSCHEINE</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
