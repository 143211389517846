import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import logo from "../img/logo_black.png";

import styles from "../css/style.module.css";
import Footer from "./components/Footer";
import Loading from "../../../components/Loading";

const Step50_Confirmation = () => {

    const theEvent = JSON.parse(sessionStorage.getItem("FUWEvent"));
    const remarks = JSON.parse(theEvent.remarks);

    const [resources, setResources] = useState({});

    // fetching resources
    const { language } = useParams();


    useEffect(() => {
        requestResources();

    }, [language]);

    const requestResources = () => {
        axios
            .get(`fuw2023/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    return (
        <div>

            {resources.translation && (
                <div className={"container pt-0 " + styles.wrapper}>
                    <div className="row mt-2">
                        <div className="col-md-4 text-center">
                            <img src={logo} className="img-fluid" alt="FUW Logo"></img>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12 mt-5">
                            <div dangerouslySetInnerHTML={{ __html: remarks["ConfirmationText"] }} className={styles.description}></div>
                        </div>
                    </div>
                </div>
            )}

            <div className={"container " + styles.wrapper}>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <Footer language={language} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step50_Confirmation;