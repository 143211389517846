import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";

import Reducer from "../../../utilities/Reducer";
import styles from "../css/style.module.css";
import Header from "./components/Header";

const ShopEvents = () => {
  const [eventInfos, setEventInfos] = useState([]);
  const [currEventId, setCurrEventId] = useState("0");
  const [eventIds, setEventIds] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [token] = useState(sessionStorage.getItem("token"));
  const [loading, setLoading] = useState(true);

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  let history = useHistory();

  const [resources, setResources] = useState({});

  // fetching resources
  const { language } = useParams();

  const [languageInfo, dispatch] = useReducer(Reducer, {
    id: 0,
    language: language,
  });

  useEffect(() => {
    dispatch({ payload: language });
  }, [languageInfo.id]);

  useEffect(() => {
    requestResources();
    requestFormSettings();
  }, [language]);

  const requestResources = () => {
    axios
      .get(`geologentag2022/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestFormSettings = () => {
    axios.get(`geologentag2022/formsettings`).then((res) => {
      setEventIds(res.data.eventIds);
    });
  };

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }, []);

  useEffect(() => {
    if (eventInfos.length < 1) {
      requestEventInfo();
    }
  }, [eventIds]);

  const requestEventInfo = () => {
    eventIds.forEach((eventId) => {
      axios
        .get(`${baseUrl}/Event/${eventId}/Infos`)
        .then((res) => {
          setEventInfos((current) => [
            ...current,
            res.data.eventInfos.find(
              (infos) => infos.languageId === languageInfo.id
            ),
          ]);
        })
        .catch((error) => console.error(error.response.data));
    });

    setTimeout(() => setLoading(false), 100);
  };

  const mapEventInfos = () => {
    return (
      eventInfos &&
      eventInfos.length > 0 &&
      eventInfos.map((info, index) => (
        <div className={styles.formCheck + " mb-3"} key={index}>
          <input
            className={styles.ticketCheckbox + " form-check-input"}
            type="checkbox"
            id="flexCheckDefault1"
            checked={checkboxes["checkbox" + info.eventId]}
            onChange={() => {
              setCheckboxes({ ["checkbox" + (index + 1)]: true });
              setCurrEventId(info.eventId);
            }}
          />
          <label className={styles.formCheckLabel} htmlFor="flexCheckDefault">
            {info.name}
          </label>
        </div>
      ))
    );
  };

  const onSubmit = () => {
    history.push(`/${language}/2022/Geologentag/Tickets/${currEventId}`);
  };

  const onBack = () => {
    history.push(`/${language}/2022/Geologentag/Home`);
  };

  return (
    <div className={styles.pageContainer}>
      <section className={styles.section} id="section-geology">
        <div className={styles.boxMain + " row"}>
          <div className="mb-3">
            <Header language={language} />
            <hr className="mb-4"></hr>
            {loading ? (
              <Loading alignment="center" color="#e77c3d" bgColor="#fff" margin="85px" />
            ) : (
              <>
                <p className="text fw-bold fs-4">
                  {resources.translation && resources.translation.Participant}
                </p>
                <>{mapEventInfos()}</>
                <div className={styles.btnGroup + " mt-4"}>
                  <button className={styles.button + " me-3"} onClick={onBack}>
                    {resources.translation && resources.translation.Back}
                  </button>

                  <button className={styles.button} onClick={onSubmit}>
                    {resources.translation && resources.translation.Next}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShopEvents;
