import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import logo from "../images/siemens.png";
import "../css/style.module.css";

function Confirmation() {
  const [resources, setResources] = useState({});

  // fetching resources
  const { language } = useParams();

  useEffect(() => {
    requestResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const requestResources = () => {
    axios.get(`sommerfest2022/resources/${language}`).then((res) => {
      setResources(res.data);
    });
  };

  return (
    <div className="page-container-sommerfest">
      <img className="logo" src={logo} alt="Siemens Logo"></img>

      {resources.translation && (
        <section className="section" id="section">
          <div className="box-main-sommerfest row">
            <div className="firstHalf-sommerfest">
              <div>
                <h1 className="text titleStyle text-start text-light">
                  {resources.translation.ThankyouMessage}
                </h1>
                <p className="text text-start mt-4">
                  {resources.translation.ReceiveMailMessage}
                </p>
                <p className="text text-start mt-4">
                  {resources.translation.TicketInfoMessage}
                </p>
                <p className="text text-start mt-4">
                  {resources.translation.TeamRegistrationMessage}
                </p>
                <div className="flex-end mt-4">
                  <a
                    className="btn button btn-light"
                    href="https://localhost:44443/"
                  >
                    {resources.translation.ButtonString}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
export default Confirmation;
