import React from "react";

import styles from "../../css/style.module.css";
import banner from "../../images/Eventbanner.jpg";
import flyer from "../../images/Eventflyer.jpg";

const Header = () => {
  return (
    <div>
      <div className="container">
        <img
          className={"text-center " + styles.flyer}
          src={flyer}
          alt="expovina flyer"
        ></img>
      </div>
      <div className="row">
        <div className={"col-12 " + styles.fill}>
          <img src={banner} className="img-fluid" alt="expovina banner"></img>
        </div>
      </div>
    </div>
  );
};

export default Header;
