import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import Header from "./components/Header";
import Reducer from "../../../utilities/Reducer";
import Footer from "./components/Footer";

const Step20_Checkout = () => {
  const [show, setShow] = useState(0);
  const [resources, setResources] = useState({});
  const [orderId] = useState(sessionStorage.getItem("ExpovinaOrderId"));
  const [token] = useState(sessionStorage.getItem("token"));
  const [order, setOrder] = useState([]);
  const [orderInfo, setOrderInfo] = useState({});
  const [bookedTicketTypeInfo, setBookedTicketTypeInfo] = useState([]);
  const [requested, setRequested] = useState(0);
  const [insurance, setInsurance] = useState(false);
  const [insuranceTotalAmount, setInsuranceTotalAmount] = useState();
  const [totalPrice, setTotalPrice] = useState(0);

  // react hook for navigation
  let history = useHistory();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  // fetching resources
  const { language } = useParams();

  let languageId = 0;

  if (language === "de" || language === "DE") {
    languageId = 1;
  } else if (language === "fr" || language === "FR") {
    languageId = 2;
  } else if (language === "en" || language === "EN") {
    languageId = 3;
  } else if (language === "it" || language === "IT") {
    languageId = 4;
  } else {
    languageId = 0;
  }

  const [payment, setPayment] = useState({
    currency: "CHF",
    paymentType: 0,
    deliveryType: 0,
  });

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  useEffect(() => {
    if (requested === 0) {
      requestCompletedOrder();
    }

    setOrderInsuranceTotalAmount();

    if (orderInfo && orderInfo.totalInsuranceAmount > 0) {
      setInsurance(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    requestResources();
  }, [language]);

  const requestResources = () => {
    axios
      .get(`expovina2023/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestCompletedOrder = () => {
    axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`).then((res) => {
      setOrder(res.data.tickets);
      setOrderInfo(res.data);
      setTotalPrice(res.data.totalPrice);

      setPayment({
        ...payment,
        deliveryType: res.data.deliveryMethodId,
        paymentType: res.data.paymentMethodId,
      });
      res.data.tickets.map((t) => requestBookedTicketTypeInfo(t.ticketTypeId));

      // prevent overwriting
      setRequested(requested + 1);
    });
  };

  const setTotalAmountWithInsurance = (condition) => {
    setInsurance(!condition);

    if (!condition) {
      setTotalPrice(totalPrice + insuranceTotalAmount);
    } else {
      setTotalPrice(totalPrice - insuranceTotalAmount);
    }
  };

  const requestBookedTicketTypeInfo = (ticketTypeId) => {
    axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`).then((res) => {
      setBookedTicketTypeInfo((current) => [
        ...current,
        res.data.ticketTypeInfos.find((tti) => tti.languageId === languageId),
      ]);
      setShow(true);
    });
  };

  const changeDeliveryType = (deliveryType) => {
    axios
      .put(
        `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryType}`
      )
      .then((res) => {
        setPayment({ ...payment, deliveryType: deliveryType });
        setOrderInfo(res.data);
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const changePaymentType = (paymentType) => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentType}`)
      .then((res) => {
        setPayment({ ...payment, paymentType: paymentType });
        setOrderInfo(res.data);
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const setOrderInsurance = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/Insurance`)
      .then((res) => {
        setInsurance(true);
        setOrderInfo(res.data);

        let total = orderInfo.totalPrice;

        if (orderInfo.totalInsuranceAmount === 0 && insurance) {
          total = total + insuranceTotalAmount;
        }

        total = Math.round(total * 100) / 100;

        let price = total.toFixed(2) + " CHF";

        sessionStorage.setItem("totalPrice", price);

        let dataTransProviders = [1, 7, 8, 9, 10, 14, 11];

        if (
          dataTransProviders.findIndex((d) => d === payment.paymentType) !== -1
        ) {
          startDatatrans();
        } else {
          history.push(`/${language}/2023/Expovina/confirmation/${orderId}`);
        }
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const setOrderInsuranceTotalAmount = () => {
    let amount = 0.0;

    order.map((ticket) => {
      if (ticket.price < 50 && ticket.price > 0) {
        amount = amount + 0.7;
      } else if (ticket.price > 50 && ticket.price < 75) {
        amount = amount + 0.95;
      } else {
        amount = amount + 0.0;
      }
    });

    setInsuranceTotalAmount(Math.round(100 * amount) / 100);
  };

  // calling the popup
  const startDatatrans = () => {
    var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

    axios
      .get(baseRequestUrl)
      .then((res) => {
        let datatransFormId = "datatrans-" + new Date().getTime();
        var form =
          "<form class='datatrans' id='" + datatransFormId + "'></form>";

        let container = document.getElementById("datatrans-form-placeholder");

        container.innerHTML += form;

        let element = document.getElementById(datatransFormId);

        // merchantId for testing
        // element.setAttribute("data-merchant-id", "1100004624");
        element.setAttribute("data-merchant-id", res.data.merchantId);
        element.setAttribute("data-amount", res.data.amount);
        element.setAttribute("data-currency", res.data.currency);
        element.setAttribute("data-refno", res.data.referenceNumber);
        element.setAttribute("data-reqType", res.data.reqType);
        element.setAttribute(
          "data-upp-return-target",
          res.data.uppReturnTarget
        );
        element.setAttribute("data-paymentMethod", res.data.paymentMethod);
        element.setAttribute("data-sign", res.data.digitalSignature);

        let domain = "https://" + window.location.host;

        let successUrl =
          domain + `/expovina2023/redirect/${language}/success/${orderId}`;
        let errorUrl =
          domain + `/expovina2023/redirect/${language}/error/${orderId}`;
        let cancelUrl =
          domain + `/expovina2023/redirect/${language}/cancel/${orderId}`;

        element.setAttribute("data-success-url", successUrl);
        element.setAttribute("data-error-url", errorUrl);
        element.setAttribute("data-cancel-url", cancelUrl);

        for (const key in res.data.userInfo) {
          element.setAttribute(key, res.data.userInfo[key]);
        }

        for (const key in res.data.merchantSpecificParameters) {
          element.setAttribute(key, res.data.merchantSpecificParameters[key]);
        }

        // start datatrans -> call the payment form
        window.Datatrans.startPayment({
          form: "#" + datatransFormId,
        });
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  const finishOrder = () => {
    if (insurance) {
      setOrderInsurance();
    } else {
      let total = orderInfo.totalPrice;

      if (orderInfo.totalInsuranceAmount === 0 && insurance) {
        total = total + insuranceTotalAmount;
      }

      if (total > 0) {
        total = Math.round(total * 100) / 100;

        let price = total.toFixed(2) + " CHF";

        sessionStorage.setItem("totalPrice", price);
        let dataTransProviders = [1, 7, 8, 9, 10, 14, 11];

        if (
          dataTransProviders.findIndex((d) => d === payment.paymentType) !== -1
        ) {
          startDatatrans();
        } else {
          history.push(`/${language}/2023/expovina/confirmation/${orderId}`);
        }
      } else {
        sessionStorage.setItem("totalPrice", "0 CHF");
        changePaymentTypeToFree();
      }
    }
  };

  const changePaymentTypeToFree = () => {
    axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`).then(() => {
      history.push(`/${language}/2023/expovina/confirmation/${orderId}`);
    })
  }

  const mapSelectedTickets = () => {
    return (
      <div>
        {order.map((ticket, index) => (
          <p
            className={
              "d-flex justify-content-between align-items-start mb-2 fs-6"
            }
            key={index}
          >
            {bookedTicketTypeInfo.length > 0 &&
              bookedTicketTypeInfo.find(
                (booked) => booked.ticketTypeId === ticket.ticketTypeId
              ) &&
              bookedTicketTypeInfo.find(
                (booked) => booked.ticketTypeId === ticket.ticketTypeId
              ).name}

            <span></span>
            <p className="m-0 col-2 text-end">{ticket.price + " " + ticket.currency}</p>
          </p>
        ))}
      </div>
    );
  };

  const mapExtraCosts = () => {
    return (
      <div>
        <p
          className={
            "d-flex justify-content-between align-items-start mb-2 fs-6"
          }
        >
          Kosten für Lieferung und Zahlung
          <span></span>
          {orderInfo.deliverPaymentFee.toFixed(2) + " " + orderInfo.currency}
        </p>
      </div>
    );
  };

  const mapInsuranceOfTickets = () => {
    let length = bookedTicketTypeInfo.length;

    return (
      <div>
        <p
          className={
            "d-flex justify-content-between align-items-start mb-2 fs-6"
          }
        >
          {resources.translation.TicketInsurance}
          <span></span>
          {insuranceTotalAmount.toFixed(2) + " " + orderInfo.currency}
        </p>
      </div>
    );
  };

  const totalSelectedTickets = () => {
    let total = orderInfo.totalPrice;

    if (orderInfo.totalInsuranceAmount === 0 && insurance) {
      total = total + insuranceTotalAmount;
    }

    total = Math.round(total * 100) / 100;

    return (
      <div>
        <p className={"d-flex justify-content-between align-items-start mb-2"}>
          <p className="fw-bold fs-6">{resources.translation.TotalTax}</p>
          <span></span>
          <p className="fw-bold fs-6">
            {orderInfo && total.toFixed(2) + " " + orderInfo.currency}
          </p>
        </p>
      </div>
    );
  };

  // calculate total price
  let total = orderInfo.totalPrice;

  if (orderInfo.totalInsuranceAmount === 0 && insurance) {
    total = total + insuranceTotalAmount;
  }

  total = Math.round(total * 100) / 100;

  return (
    <div>
      <div id="datatrans-form-placeholder"></div>

      {resources.translation && (
        <div className={"container pt-0 " + styles.wrapper}>
          <Header />
          <div className={"mt-5"}>
            <div className="row">
              <div className={"col-md-12 mb-2 pt-2 pb-2 " + styles.customBox}>
                <h1 className="fs-5">{resources.translation.DeliveryType}</h1>
              </div>
            </div>
            <div className="mt-2 mb-4">
              <div className="form-check mb-3">
                <input
                  className={"form-check-input me-2 " + styles.formCheckInput}
                  type="radio"
                  name="flexRadioDefault1"
                  checked={payment.deliveryType === 1 ? true : false}
                  onClick={() => changeDeliveryType(1)}
                  id="printAtHome"
                />
                <label
                  className="form-check-label fs-6"
                  htmlFor="flexRadioDefault1"
                >
                  {resources.translation.PrintAtHome}
                  <p className="pt-1 m-0 text-secondary">
                    {resources.translation.PrintAtHomeDesc}
                  </p>
                </label>
              </div>
            </div>

            {total > 0 && (
              <>
                <div className="row">
                  <div
                    className={"col-md-12 mb-2 pt-2 pb-2 " + styles.customBox}
                  >
                    <h1 className="fs-5">
                      {resources.translation.PaymentType}
                    </h1>
                  </div>
                </div>
                <div className="mt-2 mb-5">
                  <div className="form-check mb-3">
                    <input
                      className={
                        "form-check-input me-2 " + styles.formCheckInput
                      }
                      type="radio"
                      name="flexRadioDefault"
                      checked={payment.paymentType === 11 ? true : false}
                      onClick={() => changePaymentType(11)}
                      id="sofort"
                    />
                    <label
                      className="form-check-label fs-6"
                      htmlFor="flexRadioDefault1"
                    >
                      {resources.translation.Sofort}
                      <p className="pt-1 m-0 text-secondary">
                        {resources.translation.MasterCardDesc}
                      </p>
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className={
                        "form-check-input me-2 " + styles.formCheckInput
                      }
                      type="radio"
                      name="flexRadioDefault"
                      checked={payment.paymentType === 10 ? true : false}
                      onClick={() => changePaymentType(10)}
                      id="paypal"
                    />
                    <label
                      className="form-check-label fs-6"
                      htmlFor="flexRadioDefault1"
                    >
                      {resources.translation.PayPal}
                      <p className="pt-1 m-0 text-secondary">
                        {resources.translation.PayPalDesc}
                      </p>
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className={
                        "form-check-input me-2 " + styles.formCheckInput
                      }
                      type="radio"
                      name="flexRadioDefault"
                      checked={payment.paymentType === 1 ? true : false}
                      onClick={() => changePaymentType(1)}
                      id="mastercard"
                    />
                    <label
                      className="form-check-label fs-6"
                      htmlFor="flexRadioDefault1"
                    >
                      {resources.translation.MasterCard}
                      <p className="pt-1 m-0 text-secondary">
                        {resources.translation.MasterCardDesc}
                      </p>
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className={
                        "form-check-input me-2 " + styles.formCheckInput
                      }
                      type="radio"
                      name="flexRadioDefault"
                      checked={payment.paymentType === 7 ? true : false}
                      onClick={() => changePaymentType(7)}
                      id="visa"
                    />
                    <label
                      className="form-check-label fs-6"
                      htmlFor="flexRadioDefault1"
                    >
                      {resources.translation.Visa}
                      <p className="pt-1 m-0 text-secondary">
                        {resources.translation.MasterCardDesc}
                      </p>
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className={
                        "form-check-input me-2 " + styles.formCheckInput
                      }
                      type="radio"
                      name="flexRadioDefault"
                      checked={payment.paymentType === 8 ? true : false}
                      onClick={() => changePaymentType(8)}
                      id="amex"
                    />
                    <label
                      className="form-check-label fs-6"
                      htmlFor="flexRadioDefault1"
                    >
                      {resources.translation.AmericanExpress}
                      <p className="pt-1 m-0 text-secondary">
                        {resources.translation.MasterCardDesc}
                      </p>
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className={
                        "form-check-input me-2 " + styles.formCheckInput
                      }
                      type="radio"
                      name="flexRadioDefault"
                      checked={payment.paymentType === 9 ? true : false}
                      onClick={() => changePaymentType(9)}
                      id="postfinance"
                    />
                    <label
                      className="form-check-label fs-6"
                      htmlFor="flexRadioDefault1"
                    >
                      {resources.translation.PostFinance}
                      <p className="pt-1 m-0 text-secondary">
                        {resources.translation.TwintDesc1}
                      </p>
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className={
                        "form-check-input me-2 " + styles.formCheckInput
                      }
                      type="radio"
                      name="flexRadioDefault"
                      checked={payment.paymentType === 14 ? true : false}
                      onClick={() => changePaymentType(14)}
                      id="twint"
                    />
                    <label
                      className="form-check-label fs-6"
                      htmlFor="flexRadioDefault1"
                    >
                      {resources.translation.Twint}
                      <p className="pt-1 m-0 text-secondary">
                        {resources.translation.TwintDesc}
                      </p>
                    </label>
                  </div>
                </div>
              </>
            )}
            {totalPrice > 0 && (
              <>
                <div className="row">
                  <div
                    className={"col-md-12 mb-2 pt-2 pb-2 " + styles.customBox}
                  >
                    <h1 className="fs-5">
                      {resources.translation.TicketInsurance}
                    </h1>
                  </div>
                </div>
                <div class="form-check mb-4">
                  <input
                    className={"form-check-input me-3 " + styles.formCheckInput}
                    type="checkbox"
                    onClick={() => setTotalAmountWithInsurance(insurance)}
                    checked={insurance}
                  />
                  <label
                    className="col-11 form-check-label fs-6"
                    for="inlineCheckbox1"
                  >
                    {resources.translation.TicketInsuranceText}
                    <b> {resources.translation.TicketInsuranceText1} </b>
                    {resources.translation.TicketInsuranceText2}
                    <a
                      href={resources.translation.TicketInsuranceLink}
                      target="_blank"
                    >
                      {resources.translation.TicketInsuranceText3}
                    </a>
                  </label>
                </div>
              </>
            )}

            <div className="row">
              <div className={"col-md-12 mb-2 pt-2 pb-2 " + styles.customBox}>
                <h1 className="fs-5">{resources.translation.Summary}</h1>
              </div>
            </div>
            <div>
              {mapSelectedTickets()}
              {insurance && mapInsuranceOfTickets()}
              {orderInfo.deliverPaymentFee > 0 && mapExtraCosts()}
              <hr></hr>
              {totalSelectedTickets()}
            </div>
          </div>
          <div className="row mt-2">
            <p>
              {resources.translation.AGBText}{" "}
              <a href={resources.translation.AGBLink} target="_blank">
                {resources.translation.AGBText2}
              </a>
            </p>
          </div>
          {/* next button */}
          <div className="row mt-5 mb-3">
            <div className="col-4 text-start">
              <button
                className={"text-end me-2 " + styles.button}
                onClick={() =>
                  history.push(`/${language}/2023/Expovina/address`)
                }
              >
                {resources.translation.Back}
              </button>
            </div>
            <div className="col-8 text-end">
              <button
                className={"text-end " + styles.button}
                onClick={() => finishOrder()}
                disabled={
                  payment.paymentType === 0 && payment.deliveryType === 0
                }
              >
                {resources.translation.Buy}
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Step20_Checkout;
