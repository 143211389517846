import React from "react";

import styles from "../index.module.css";

const Loading = (props) => {
  return (
    <div className={"d-flex justify-content-" + props.alignment}>
      <div
        className={styles.spinner + " spinner-border"}
        role="status"
        style={{ 
            color: props.color, 
            backgroundColor: props.bgColor, 
            margin: props.margin, 
            position: props.position, 
            top: props.top, 
            left: props.left, 
            width: "3rem", 
            height: "3rem"
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
