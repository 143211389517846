import React from "react";

import image from "../images/ticketino.png";
import styles from "../index.module.css";

const PageNotFound = () => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.logoDiv}>
        <img className={styles.logo} src={image}></img>
      </div>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="text-center">
          <p className="text text-dark fw-light fs-4 mb-0">
            Page Not Found
            <span className={styles.textNotFound + " ms-2"}>404</span>
          </p>
          <p className="text text-dark fw-light fs-6">
            the current route doesnt exist
            <span className={styles.textNotFound}>.</span> Try again
            <span className={styles.textNotFound}>.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
