import axios from "axios";
import React, { useEffect, useState } from "react";

import styles from "../../css/header.module.css";
import logo from "../../img/header/logo.png";
import carPicture from "../../img/header/car_picture.png";

const Header = (props) => {
    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [props.language]);

    const requestResources = () => {
        axios.get(`swissclassicalworld2023/resources/${props.language}`).then((res) => {
            setResources(res.data);
        });
    };

    return (
        <div className={styles.header}>
            {resources.translation && (
                <div className={"row text-center " + styles.navbar}> 
                    <div className={"col-2 p-0 " + styles.imageFluid}>
                        <img src={logo} className="img-fluid ps-5" alt="logo"></img>
                    </div>
                    <div className="row col p-0">
                        <div className={"col " + styles.navElement}>
                            <a href="https://www.swissclassicworld.ch/home">home</a>
                        </div>
                        <div className={"col " + styles.navElement + " " + styles.dropdown}>
                            <a href="https://www.swissclassicworld.ch/messe">messe</a>
                            <div className={styles.dropdownContent}>
                                <li><a href="https://www.swissclassicworld.ch/team">unser team</a></li>
                                <li><a href="https://www.swissclassicworld.ch/location">location</a></li>
                            </div>
                        </div>
                        <div className={"col " + styles.navElement + " " + styles.dropdown}>
                            <a href="https://www.swissclassicworld.ch/aussteller">aussteller</a>
                            <div className={styles.dropdownContent}>
                                <li><a href="https://www.swissclassicworld.ch/anmeldung">anmeldung</a></li>
                                <li><a href="https://www.swissclassicworld.ch/download">download-bereich</a></li>
                            </div>
                        </div>
                        <div className={"col " + styles.navElement + " " + styles.dropdown}>
                            <a href="https://www.swissclassicworld.ch/besucher">besucher</a>
                            <div className={styles.dropdownContent}>
                                <li><a href="https://www.swissclassicworld.ch/tickets">tickets</a></li>
                            </div>
                        </div>
                        <div className={"col " + styles.navElement + " " + styles.dropdown}>
                            <a href="https://www.swissclassicworld.ch/medien">medien</a>
                            <div className={styles.dropdownContent}>
                                <li><a href="https://www.swissclassicworld.ch/akkreditierung">akkreditierung</a></li>
                                <li><a href="https://www.swissclassicworld.ch/newsblog">newsblog</a></li>
                                <li><a href="https://www.swissclassicworld.ch/social-media">social media</a></li>
                                <li><a href="https://www.swissclassicworld.ch/presseberichte">mitteilungen/newsletter/download</a></li>
                            </div>
                        </div>
                        <div className={"col " + styles.navElement}>
                            <a href="https://www.swissclassicworld.ch/kontakt">kontakt</a>
                        </div>
                    </div>
            </div>
            )}
            <div className="row">
                <div className={"col-md-12 " + styles.fill}>
                    <img src={carPicture} className="img-fluid" alt="car picture"></img>
                </div>
            </div>
        </div>
    );
};

export default Header;
