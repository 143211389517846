import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";

import Reducer from "../../../utilities/Reducer";
import styles from "../css/style.module.css";
import Header from "./components/Header";

const ShopBasket = () => {
  const [ticketTypes, setTicketTypes] = useState([]);
  const [ticketTypeInfos, setTicketTypeInfos] = useState([]);
  const [selectedTicketTypes, setSelectedTicketTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState("");
  const [orderId] = useState(sessionStorage.getItem("orderId"));
  const [token] = useState(sessionStorage.getItem("token"));

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  const { currEventId } = useParams();

  let history = useHistory();

  const [resources, setResources] = useState({});

  // fetching resources
  const { language } = useParams();

  const [languageInfo, dispatch] = useReducer(Reducer, {
    id: 0,
    language: language,
  });

  useEffect(() => {
    dispatch({ payload: language });
  }, [languageInfo.id]);

  useEffect(() => {
    requestResources();
  }, [language]);

  const requestResources = () => {
    axios
      .get(`geologentag2022/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.error(error.response.data));
  };

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    if (ticketTypes.length < 1) {
      requestTicketType();
    }
  }, []);

  const requestTicketType = () => {
    axios
      .get(`${baseUrl}/Event/${currEventId}/TicketTypes`)
      .then((res) => {
        setTicketTypes((current) => [...current, res.data.ticketTypes]);

        res.data.ticketTypes.map((t) => requestTicketTypeInfo(t.id));

        setTimeout(() => setLoading(false), 100);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestTicketTypeInfo = (ticketId) => {
    axios
      .get(`${baseUrl}/TicketType/${ticketId}/Infos`)
      .then((res) => {
        setTicketTypeInfos((current) => [
          ...current,
          res.data.ticketTypeInfos.find(
            (tti) => tti.languageId === languageInfo.id
          ),
        ]);
      })
      .catch((error) => console.error(error.response.data));
  };

  const addTicketToBasket = () => {
    selectedTicketTypes.map((t) => {
      axios
        .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
          ticketsToAdd: [
            {
              ticketTypeId: t,
              quantity: 1,
            },
          ],
        })
        .then(() => {
          history.push(`/${language}/2022/Geologentag/Payment`);
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    });
  };

  const onSubmit = () => {
    addTicketToBasket();
  };

  const onBack = () => {
    history.push(`/${language}/2022/Geologentag/Shop/Events`);
  };

  const onCheckBoxChange = (ticketTypeId, checked) => {
    if (checked) {
      setSelectedTicketTypes((current) => [...current, ticketTypeId]);
    } else {
      setSelectedTicketTypes((current) =>
        current.filter((selected) => selected !== ticketTypeId)
      );
    }
  };

  const mapTicketsDay = () => {
    let ticketType = ticketTypes.find((type) => type[0].eventId == currEventId);

    return (
      ticketTypes &&
      ticketTypes.length > 0 &&
      ticketType &&
      ticketType.length > 0 &&
      ticketType.map(
        (type, index) =>
          new Date(type.start).toTimeString().substring(0, 2) < 16 && (
            <div
              className={
                styles.formCheck + "align-self-start col-md-12 order-first mb-3"
              }
              key={index}
            >
              <input
                className={styles.ticketCheckbox + " form-check-input me-3"}
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onChange={(elem) =>
                  onCheckBoxChange(type.id, elem.currentTarget.checked)
                }
              />
              <label
                className={styles.formCheckLabel}
                htmlFor="flexCheckDefault"
              >
                {ticketTypeInfos.length > 0 &&
                  ticketTypeInfos.find((t) => t.ticketTypeId === type.id) &&
                  ticketTypeInfos.find((t) => t.ticketTypeId === type.id).name +
                    ", " +
                    new Date(type.start).toLocaleDateString()}
              </label>
            </div>
          )
      )
    );
  };

  const mapTicketsEvening = () => {
    let ticketType = [];
    ticketType = ticketTypes.find((type) => type[0].eventId == currEventId);

    return (
      ticketTypes &&
      ticketTypes.length > 0 &&
      ticketType &&
      ticketType.length > 0 &&
      ticketType.map(
        (type, index) =>
          new Date(type.start).toTimeString().substring(0, 2) > 16 && (
            <div
              className={
                styles.formCheck + "align-self-start col-md-12 order-first mb-3"
              }
              key={index}
            >
              <input
                className={styles.ticketCheckbox + " form-check-input me-3"}
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onChange={(elem) =>
                  onCheckBoxChange(type.id, elem.currentTarget.checked)
                }
              />
              <label
                className={styles.formCheckLabel}
                htmlFor="flexCheckDefault"
              >
                {ticketTypeInfos.length > 0 &&
                  ticketTypeInfos.find((t) => t.ticketTypeId === type.id) &&
                  ticketTypeInfos.find((t) => t.ticketTypeId === type.id).name +
                    ", " +
                    new Date(type.start).toLocaleDateString()}
              </label>
            </div>
          )
      )
    );
  };

  const mapSelectedTickets = () => {
    let ticketType = ticketTypes.find((type) => type[0].eventId == currEventId);

    return (
      <ol className="col-md-12 ps-0">
        {selectedTicketTypes.map((selected, index) => (
          <li
            className={
              styles.geology +
              " list-group-item d-flex justify-content-between align-items-start"
            }
            key={index}
          >
            {ticketTypeInfos.find((t) => t.ticketTypeId === selected).name}

            <span></span>
            {ticketType.find((t) => t.id === selected).price +
              " " +
              ticketType.find((t) => t.id === selected).currency}
          </li>
        ))}
      </ol>
    );
  };

  const totalOfSelectedTickets = () => {
    let ticketType = ticketTypes.find((type) => type[0].eventId == currEventId);

    let sum = 0;
    let currency = "";

    selectedTicketTypes.map((selected) => {
      sum = sum + ticketType.find((t) => t.id === selected).price;
      currency = ticketType.find((t) => t.id === selected).currency;
    });

    return (
      <ol className="col-md-12 ps-0">
        {selectedTicketTypes.length > 0 && (
          <li
            className={
              styles.geology +
              " list-group-item d-flex justify-content-between align-items-start"
            }
          >
            {resources.translation && resources.translation.Total} <span></span>{" "}
            {sum} {currency}
          </li>
        )}
      </ol>
    );
  };

  return (
    <div className={styles.pageContainer}>
      <section className={styles.section} id="section-geology">
        <div className={styles.boxMain + " row"}>
          <div className="mb-3">
            <Header language={language} />
            <hr className="mb-4"></hr>
            {loading ? (
              <Loading alignment="center" color="#e77c3d" bgColor="#fff" margin="85px" />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <p className="text fw-bold fs-5">
                      {resources.translation && resources.translation.Daypass}
                    </p>
                    {mapTicketsDay()}
                  </div>
                  <div className="col-lg-6 col-12">
                    <p className="text fw-bold fs-5">
                      {resources.translation &&
                        resources.translation.Eveningpass}
                    </p>
                    {mapTicketsEvening()}
                  </div>
                  <hr className="hr mt-4"></hr>
                  <div className="">
                    <p className="text fw-bold fs-5 mt-3">
                      {resources.translation && resources.translation.Summary}
                    </p>
                    <div>
                      {mapSelectedTickets()}
                      <hr></hr>
                      {totalOfSelectedTickets()}
                    </div>
                  </div>
                  {errors !== "" && (
                    <div className="row">
                      <p className="text text-danger ps-2">{errors}*</p>
                    </div>
                  )}
                </div>
                <div className="button-group mt-4">
                  <button
                    className={styles.button + " btn me-3"}
                    onClick={onBack}
                  >
                    {resources.translation && resources.translation.Back}
                  </button>

                  <button className={styles.button + " btn"} onClick={onSubmit}>
                    {resources.translation && resources.translation.Next}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShopBasket;
