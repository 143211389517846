import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import Reducer from "../../../utilities/Reducer";
import { useHistory, useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "../../../components/Loading";
import CustomMap from "../../../utilities/CustomMap";

const Home = () => {
  const [orderId, setOrderId] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventInfo, setEventInfo] = useState({});
  const [ticketTypes, setTicketTypes] = useState([]);
  const [ticketTypeInfos, setTicketTypeInfos] = useState([]);
  const [ticketTypeNumberOfAvailability, setTicketTypeNumberOfAvailability] =
    useState([]);
  const [selectedTicketTypes] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [promoError, setPromoError] = useState("");
  const [sum, setSum] = useState(0);
  const [isNotValid, setIsNotValid] = useState(true);
  const [loading, setLoading] = useState(true);
  const [displayMoreInfosArray, setDisplayMoreInfos] = useState({});

  // react hook for navigation
  let history = useHistory();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  // fetching resources
  const [resources, setResources] = useState({});

  const { language } = useParams();

  const [languageInfo, dispatch] = useReducer(Reducer, {
    id: 0,
    language: language,
  });

  useEffect(() => {
    loadToken();
  }, []); //only on first page load

  const loadToken = () => {
    axios.get("token/get/Swissclassicalworld").then((res) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

      sessionStorage.setItem("token", res.data);

      if (sessionStorage.getItem("promotionCode")) {
        sessionStorage.setItem("promotionCode", "");
      }

      if (sessionStorage.getItem("promotionSuccess")) {
        sessionStorage.setItem("promotionSuccess", false);
      }

      requestFormSettings();
    });
  };

  const requestFormSettings = () => {
    axios.get(`swissclassicalworld2023/formsettings`).then((res) => {
      startOrder(res.data.posId, res.data.eventId);
      setEventId(res.data.eventId);
    });
  };

  useEffect(() => {
    requestResources();
  }, [language]); //everytime language is changed

  const requestResources = () => {
    axios
      .get(`swissclassicalworld2023/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.error(error.response.data));
  };

  const startOrder = (posId, eventId) => {
    let order = {
      affiliateShopId: null,
      currency: "CHF",
      tenantId: 1,
      pointOfSaleId: posId,
      abbreviation: "",
      paymentType: 1,
    };

    axios
      .post(`${baseUrl}/ShopBasket/Order`, order)
      .then((res) => {
        sessionStorage.setItem("SwissClassicalWorldOrderId", res.data.id);

        setOrderId(res.data.id);
        setLoading(true);

        requestEvent(eventId);
        if (ticketTypes.length < 1) requestTicketType(eventId, res.data.id, 0);

        setTimeout(() => setLoading(false), 500);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestEvent = (id) => {
    axios
      .get(`${baseUrl}/Event/${id}`)
      .then((res) => {
        sessionStorage.setItem(
          "SwissClassicalWorldEvent",
          JSON.stringify(res.data)
        );

        requestEventInfo(id);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestEventInfo = (id) => {
    axios
      .get(`${baseUrl}/Event/${id}/Infos`)
      .then((res) => {
        // instead of setting it as an object, we were adding the object to an array!
        setEventInfo(
          res.data.eventInfos.find(
            (infos) => infos.languageId === languageInfo.id
          )
        );
      })
      .catch((error) => console.error(error.response.data));
  };

  const removePromotionCode = () => {
    setPromoCode("");
    setPromoError("");
    requestTicketType(eventId, orderId, 0);
  };

  const requestTicketType = (id, orderId, promocode) => {
    axios
      .get(`${baseUrl}/Event/${id}/TicketTypes`)
      .then((res) => {
        let ticketTypesArray = [];

        ticketTypesArray = res.data.ticketTypes;

        if (promocode == 1) {
          ticketTypesArray.map((tt) => {
            tt.price = tt.price - 5;
          });
        } else if (promocode == 2) {
          ticketTypesArray.map((tt) => {
            tt.price = tt.price - tt.price;
          });
        }

        setTicketTypes(ticketTypesArray);

        res.data.ticketTypes.map((tt) =>
          requestTicketTypeNumberOfAvailableTickets(tt.id, orderId)
        );

        res.data.ticketTypes.map((tt) => requestTicketTypeInfo(tt.id));
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestTicketTypeNumberOfAvailableTickets = (ticketTypeId, orderId) => {
    axios
      .get(
        `${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}`
      )
      .then((res) => {
        setTicketTypeNumberOfAvailability((current) => [
          ...current,
          {
            ticketTypeId: ticketTypeId,
            status: res.data.status,
            availableTickets: res.data.availableTickets,
          },
        ]);
      })
      .catch((error) => console.error(error.response.data));
  };

  const requestTicketTypeInfo = (ticketTypeId) => {
    axios
      .get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`)
      .then((res) => {
        setTicketTypeInfos((current) => [
          ...current,
          res.data.ticketTypeInfos[0],
        ]);
      })
      .catch((error) => console.error(error.response.data));
  };

  const onTicketTypeChange = (e, ticketTypeId) => {
    if (selectedTicketTypes.length < 1) {
      selectedTicketTypes[0] = {
        ticketTypeId: ticketTypeId,
        quantity: e.target.value,
      };
    } else {
      selectedTicketTypes.map((t) => {
        const testSelectedTicketTypes = selectedTicketTypes.filter(
          (f) => f.ticketTypeId === ticketTypeId
        );

        if (testSelectedTicketTypes.length === 1) {
          selectedTicketTypes.find(
            (f) => f.ticketTypeId === ticketTypeId
          ).quantity = e.target.value;
        } else {
          selectedTicketTypes[selectedTicketTypes.length] = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
          };
        }
      });
    }

    let totalQuantity = 0;
    let totalSum = 0;

    selectedTicketTypes.forEach((stt) => {
      totalQuantity = totalQuantity + stt.quantity * 1;
    });

    if (totalQuantity > 0) {
      setIsNotValid(false);
    } else {
      setIsNotValid(true);
    }

    selectedTicketTypes.forEach((stt) => {
      totalSum =
        totalSum +
        ticketTypes.find((tt) => tt.id === stt.ticketTypeId).price *
          stt.quantity;
    });

    setSum(totalSum);
  };

  const mapTicketsTypes = () => {
    let ticketType = [];
    if (ticketTypes.length > 0) {
      if (ticketTypes.findIndex((tt) => tt.eventId == eventId) === 0) {
        ticketType.unshift(ticketTypes.find((tt) => tt.eventId == eventId));
      } else {
        ticketType = ticketTypes.find((tt) => tt.eventId == eventId);
      }
    }

    return (
      ticketTypes &&
      ticketTypes.length > 0 &&
      ticketType &&
      ticketType.length > 0 &&
      ticketTypes.map((tt, index) => (
        <div key={index}>
          {ticketTypeInfos &&
            ticketTypeInfos.length > 0 &&
            ticketTypeInfos.length >= ticketType.length &&
            ticketTypeNumberOfAvailability.length >= ticketType.length &&
            ticketTypeNumberOfAvailability.find(
              (ttnoa) => ttnoa.ticketTypeId === tt.id
            ) &&
            ticketTypeNumberOfAvailability.find(
              (ttnoa) => ttnoa.ticketTypeId === tt.id
            ).status === 0 && ( //filter out locked by promo ticket types
              <div className={"row pt-3 pb-4"}>
                <div className="col-md-9">
                  <label className="fs-5">
                    {ticketTypeInfos.find(
                      (tti) => tti.ticketTypeId === tt.id
                    ) &&
                      ticketTypeInfos.find((tti) => tti.ticketTypeId === tt.id)
                        .name}
                  </label>
                  <br></br>
                  <br></br>
                  {displayMoreInfosArray[tt.id] && (
                    <p>
                      {ticketTypeInfos.find(
                        (tti) => tti.ticketTypeId === tt.id
                      ) &&
                        ticketTypeInfos.find(
                          (tti) => tti.ticketTypeId === tt.id
                        ).description}
                    </p>
                  )}
                  {displayMoreInfosArray[tt.id] ? (
                    <span
                      className={styles.spanMoreInfo}
                      onClick={() => setDisplayMoreInfos({ [tt.id]: false })}
                    >
                      <p>Weniger Info -</p>
                    </span>
                  ) : (
                    <span
                      className={styles.spanMoreInfo}
                      onClick={() => setDisplayMoreInfos({ [tt.id]: true })}
                    >
                      <p>Mehr Info +</p>
                    </span>
                  )}
                </div>
                <div className="col-md-2">
                  <label>
                    {tt.currency} {tt.price}.-
                  </label>
                </div>
                <div className="col-md-1">
                  <select
                    className={"form-select " + styles.customTextbox}
                    onChange={(e) => onTicketTypeChange(e, tt.id)}
                  >
                    {Array.from(
                      Array(
                        ticketTypeNumberOfAvailability.find(
                          (ttnoa) => ttnoa.ticketTypeId === tt.id
                        ) &&
                          ticketTypeNumberOfAvailability.find(
                            (ttnoa) => ttnoa.ticketTypeId === tt.id
                          ).availableTickets + 1
                      ),
                      (e, i) => {
                        return (
                          <option key={i} value={i}>
                            {i}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div className={"col-md-12 mt-4"}>
                  <div className={styles.hrEffect}></div>
                </div>
              </div>
            )}
        </div>
      ))
    );
  };

  const isPromocodeAvailable = () => {
    axios
      .get(`${baseUrl}/Promotion/Availability/?promotionCode=${promoCode}`)
      .then((res) => {
        if (res.data) {
          requestTicketType(eventId, orderId, 0);
          setPromoError(
            "Dieser Gutschein Code kann nicht auf ihre aktuelle Bestellung angewendet werden."
          );
        } else {
          if (
            promoCode === "SCW23TMC" ||
            promoCode === "SCW23AMAG" ||
            promoCode === "SCW2023TDA" ||
            promoCode === "AUTOZEITSCW23" ||
            promoCode === "SCW23AR" ||
            promoCode === "SCW23ACS" ||
            promoCode === "SCW23DRIVEIN" ||
            promoCode === "SCW23SPIRIT" ||
            promoCode === "SCW23LEGENDS" ||
            promoCode === "SCW23ai" ||
            promoCode === "SCW23BCC"
          ) {
            requestTicketType(eventId, orderId, 1);
            setPromoError("");
          } else if (
            promoCode === "SCW23CZ" ||
            promoCode === "BCCSCW23" ||
            promoCode === "SCW23JDCS" ||
            promoCode === "SCW23NH" ||
            promoCode === "hbXq3J2" ||
            promoCode === "N85HD8J" ||
            promoCode === "SCW23AUTOVOGEL"
          ) {
            requestTicketType(eventId, orderId, 2);
            setPromoError("");
          } else {
            requestTicketType(eventId, orderId, 1);
          }
        }
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const applyPromotionCode = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promoCode}`)
      .then((res) => {
        setPromoError("");
        requestTicketType(eventId, orderId, 0);
        history.push(`/${language}/2023/SwissClassicWorld/shipping`);

        if (res.data.tickets.length != 0) {
          sessionStorage.setItem("promotionSuccess", true);
          sessionStorage.setItem("promotionCode", promoCode);
        } else {
          sessionStorage.setItem("promotionSuccess", false);
        }
      })
      .catch((error) => {
        requestTicketType(eventId, orderId, 0);
        setPromoError(
          "Dieser Gutschein Code kann nicht auf ihre aktuelle Bestellung angewendet werden."
        );
      });
  };

  const addTicketToBasket = async () => {
    let addTicketTypes = [];

    addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

    console.log(addTicketTypes);

    await axios
      .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
        ticketsToAdd: addTicketTypes,
      })
      .then(() => {
        if (promoCode != "") {
          applyPromotionCode();
        } else {
          sessionStorage.setItem("promotionCode", "");
          sessionStorage.setItem("promotionSuccess", false);
          history.push(`/${language}/2023/SwissClassicWorld/shipping`);
        }
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const onSubmit = async () => {
    await addTicketToBasket();
  };

  return (
    <div>
      {/* loop the ticket types */}
      {loading ? (
        <Loading
          alignment="center"
          color="#d3d3d3"
          bgColor="#fff"
          position="fixed"
          top="50%"
          left="50%"
        />
      ) : (
        <>
          {/* header */}
          <div className={styles.headerDiv}>
            <div className="row">
              <div className="col-md-12 p-0">
                <Header language={language} />
              </div>
            </div>
          </div>

          {resources.translation && (
            <div className={"container pt-0 " + styles.wrapper}>
              <div className={"mt-5"}>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="fs-5">Zeit & Ort</h1>

                    <p className="m-0 fs-6">2. bis 4. Juni 2023</p>
                    <p className="m-0 fs-6">
                      Messe Luzern, Horwerstrasse 87, CH-6005 Luzern, Schweiz
                    </p>
                  </div>
                </div>
              </div>

              {/* promoCode */}
              <div className={"mt-5"}>
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="fs-5">Promocodes</h1>
                  </div>
                </div>
              </div>

              <div className={"row pt-3 pb-4 " + styles.boxBorder}>
                <div className="col-md-6">
                  <input
                    id="inputCode"
                    type="text"
                    name="promotionCode"
                    className={"form-control " + styles.customTextbox}
                    placeholder="Bitte hier Promocode eingeben"
                    onChange={(e) => setPromoCode(e.currentTarget.value)}
                    value={promoCode}
                  />
                  <span className="text-danger">{promoError}</span>
                </div>
                <div className="col-md-6 text-start">
                  <button
                    className={"btn me-4 " + styles.customButton}
                    onClick={() => isPromocodeAvailable()}
                    disabled={promoCode === ""}
                  >
                    {resources.translation.HomeRendeem}
                  </button>

                  <button
                    className={"btn " + styles.customButton2}
                    onClick={() => removePromotionCode()}
                    disabled={promoCode === ""}
                  >
                    {resources.translation.HomeDelete}
                  </button>
                </div>
              </div>

              <div className={"row"}>
                <div className={"col-md-12 mt-4"}>
                  <h1 className="fs-5">Tickets</h1>
                </div>
                <div className={"col-md-12 mt-4 mb-4"}>
                  <div className={styles.hrEffect}></div>
                </div>
              </div>

              {mapTicketsTypes()}

              <div className={"row pt-3 " + styles.boxBorder}>
                <div className="offset-md-9 col-md-3 text-end">
                  {resources.translation.HomeSum}{" "}
                  <span className="fw-bold">CHF {sum}.-</span>
                </div>
              </div>

              <div className={"row pt-3"}>
                <div className="offset-md-9 col-md-3 text-end">
                  <button
                    className={"btn " + styles.customButton}
                    onClick={onSubmit}
                    disabled={isNotValid}
                  >
                    {resources.translation.HomeAddToTheBasket}
                  </button>
                </div>
              </div>

              <div className={"row pt-4 ps-3 pe-0 container"}>
                <div className={styles.googleMap}>
                  <CustomMap
                    locations={[
                      {
                        lat: 47.0346543,
                        lng: 8.3042717,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}

          {/* footer */}
          <div className={"mt-5 " + styles.footerBgColor}>
            <div className={"container " + styles.wrapper}>
              <div className="row">
                <div className="col-md-12 text-center">
                  <Footer language={language} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
