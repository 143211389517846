import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "../../../components/Loading";

const Step30_Confirmation = () => {
  const [resources, setResources] = useState({});
  const [orderId] = useState(
    sessionStorage.getItem("SwissClassicalWorldOrderId")
  );
  const [order, setOrder] = useState({});
  const [totalPrice, setTotalPrice] = useState(
    sessionStorage.getItem("totalPrice")
  );
  const [organizerId, setOrganizerId] = useState("");

  const [token] = useState(sessionStorage.getItem("token"));

  // fetching resources
  const { language } = useParams();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    requestResources();
    requestFormSettings();
  }, [language]);

  const requestFormSettings = () => {
    axios.get(`swissclassicalworld2023/formsettings`).then((res) => {
      setOrganizerId(res.data.organizerId);
      confirmOrder();
    });
  };

  const requestResources = () => {
    axios
      .get(`swissclassicalworld2023/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.log(error.response.data));
  };

  // confirm the order
  const confirmOrder = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
      .then((res) => {
        setOrder(res.data);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const getReceiptOfOrder = async () => {
    await axios
      .get(`${baseUrl}/Order/${orderId}/Receipt?organizerId=${organizerId}`, {
        responseType: "blob",
      })
      .then((res) => {
        const data = window.URL.createObjectURL(res.data);
        var link = document.createElement("a");
        link.href = data;
        link.download = "Receipt.pdf";
        link.click();
      })
      .catch((error) => console.log(error.response.data));
  };

  const getPdfTicketsOfOrder = async () => {
    await axios
      .get(
        `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const data = window.URL.createObjectURL(res.data);
        var link = document.createElement("a");
        link.href = data;
        link.download = "Tickets.pdf";
        link.click();
      })
      .catch((error) => console.log(error.response.data));
  };

  return (
    <div>
      {/* header */}
      <div className={styles.headerDiv}>
        <div className="row">
          <div className="col-md-12">
            <Header language={language} />
          </div>
        </div>
      </div>

      {resources.translation && (
        <div className={"container pt-0 " + styles.wrapper}>
          <div className={"mt-5"}>
            <div className="row">
              <div className="col-md-12 mb-2">
                <h1 className="fs-3">
                  Vielen Dank für Ihre Ticket-Bestellung für die SWISS CLASSIC
                  WORLD. Wir freuen uns darauf, Sie vom 2. bis 4. Juni 2023 in
                  Luzern begrüssen zu dürfen!
                </h1>
              </div>
            </div>
            <div className="mb-3">
              <div className={"mb-4 col-md-6 " + styles.confirmBox}>
                <p className="mb-3 fs-6">Ihre Bestell-Nr. lautet {order.invoiceNumber && order.invoiceNumber} </p>
                <p className="mb-3 fs-6">Betrag: {order.totalPrice && order.totalPrice}</p>
                <p className="mb-3 fs-6">
                  Bitte notieren Sie Ihre Bestell-Nr. für allfällige
                  Support-Anfragen. <br></br>
                  Sie erhalten in Kurze eine Bestellbestätigung per E-Mail
                </p>
                <p className="mb-3 fs-6">
                  Laden Sie jetzt Ihr Ticket herunter und drucken Sie es aus.
                </p>
              </div>

              <div className="col-md-6 mb-4">
                <button
                  className={"col-md-12 fs-6 " + styles.confirmationButton}
                  onClick={() => getPdfTicketsOfOrder()}
                >
                  TICKET HERUNTERLADEN
                </button>
              </div>

              <div className="col-md-6 mb-4">
                <button
                  className={"col-md-12 fs-6 " + styles.confirmationButton}
                  onClick={() => getReceiptOfOrder()}
                >
                  QUITTUNG HERUNTERLADEN
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div className={"mt-5 " + styles.footerBgColor}>
        <div className={"container " + styles.wrapper}>
          <div className="row">
            <div className="col-md-12 text-center">
              <Footer language={language} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step30_Confirmation;
