import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// -- 2022 -------------------------------------------------------------------------------------- //

// Siemens Wild Trophy
import WildTrophyHome from "./Forms2022/SiemensWildTrophy/views/Home";
import WildTrophyConfirm from "./Forms2022/SiemensWildTrophy/views/Step10_Confirmation";

// Geologentag
import GeologentagHome from "./Forms2022/GeologenTag/views/Home";
import GeologentagShopEvents from "./Forms2022/GeologenTag/views/Step10_ShopEvents";
import GeologentagShopTickets from "./Forms2022/GeologenTag/views/Step20_ShopTickets";
import GeologentagPaymentForm from "./Forms2022/GeologenTag/views/Step30_PaymentForm";
import GeologentagConfirmation from "./Forms2022/GeologenTag/views/Step40_Confirmation";

// LightRagaz
import LightRagazHome from "./Forms2022/LightRagaz/views/Home";
import LightRagazShop from "./Forms2022/LightRagaz/views/Step10_ShopTickets";
import LightRagazForm from "./Forms2022/LightRagaz/views/Step20_Form";
import LightRagazPaymentForm from "./Forms2022/LightRagaz/views/Step30_PaymentForm";

// Turicum
import TuricumHome from "./Forms2022/TuricumGinLab/views/Home";

// Sommerfest
import Sommerfest from "./Forms2022/SiemensSommerfest/views/Home";
import SommerfestConfirm from "./Forms2022/SiemensSommerfest/views/Step10_Confirmation";


// -- 2023 -------------------------------------------------------------------------------------- //

// FUW
import FUWHome from "./Forms2023/FUW/views/Home";
import FUWShipping from "./Forms2023/FUW/views/Step10_Shipping";
import FUWParticipants from "./Forms2023/FUW/views/Step20_Participants";
import FUWWorkshops from "./Forms2023/FUW/views/Step30_Workshops";
import FUWConfirmation from "./Forms2023/FUW/views/Step50_Confirmation";

// SwissClassicalWorld
import SwissClassicalWorldHome from "./Forms2023/SwissClassicalWorld/views/Home";
import SwissClassicalWorldShipping from "./Forms2023/SwissClassicalWorld/views/Step10_Shipping";
import SwissClassicalWorldCheckout from "./Forms2023/SwissClassicalWorld/views/Step20_Checkout";
import SwissClassicalWorldConfirmation from "./Forms2023/SwissClassicalWorld/views/Step30_Confirmation";

// Expovina
import ExpovinaHome from "./Forms2023/Expovina/views/Home";
import ExpovinaAddress from "./Forms2023/Expovina/views/Step10_Address";
import ExpovinaCheckout from "./Forms2023/Expovina/views/Step20_Checkout";
import ExpovinaConfirmation from "./Forms2023/Expovina/views/Step30_Confirmation";


// -- Fallback page -- //

import PageNotFound from "./components/PageNotFound";


export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Switch>
                {/* -- 2022 -------------------------------------------------------------------------------------- */}

                {/* LightRagaz */}
                <Route path="/2022/LightRagaz/Home" component={LightRagazHome} />
                <Route path="/2022/LightRagaz/Shop/:eventId" component={LightRagazShop} />
                <Route path="/2022/LightRagaz/Form" component={LightRagazForm} />
                <Route path="/2022/LightRagaz/Payment" component={LightRagazPaymentForm} />

                {/* Turicum */}
                <Route path="/:language/2022/TuricumGinLab/Home" component={TuricumHome} />

                {/* GeologenTag */}
                <Route path="/:language/2022/Geologentag/Home" component={GeologentagHome} />
                <Route path="/:language/2022/Geologentag/Shop" component={GeologentagShopEvents} />
                <Route path="/:language/2022/Geologentag/Tickets/:currEventId" component={GeologentagShopTickets} />
                <Route path="/:language/2022/Geologentag/Payment" component={GeologentagPaymentForm} />
                <Route path="/:language/2022/Geologentag/Confirm" component={GeologentagConfirmation} />

                {/* Siemens WildTrophy */}
                <Route path="/:language/2022/WildTrophy/Home" component={WildTrophyHome} />
                <Route path="/:language/2022/WildTrophy/Confirm" component={WildTrophyConfirm} />

                {/* Siemens Sommerfest */}
                <Route path="/:language/2022/Sommerfest/Home" component={Sommerfest} />
                <Route path="/:language/2022/Sommerfest/Confirm" component={SommerfestConfirm} />

                {/* -- 2023 -------------------------------------------------------------------------------------- */}

                {/* FUW */}
                <Route path="/:language/2023/FUW/Home/:eventId" component={FUWHome} />
                <Route path="/:language/2023/FUW/Shipping/" component={FUWShipping} />
                <Route path="/:language/2023/FUW/Participants/" component={FUWParticipants} />
                <Route path="/:language/2023/FUW/Workshops/" component={FUWWorkshops} />
                <Route path="/:language/2023/FUW/Confirmation/" component={FUWConfirmation} />

                {/* SwissClassicalWorld */}
                <Route path="/:language/2023/SwissClassicWorld/Home/" component={SwissClassicalWorldHome} />
                <Route path="/:language/2023/SwissClassicWorld/Shipping/" component={SwissClassicalWorldShipping} />
                <Route path="/:language/2023/SwissClassicWorld/Checkout/" component={SwissClassicalWorldCheckout} />
                <Route path="/:language/2023/SwissClassicWorld/Confirmation/" component={SwissClassicalWorldConfirmation} />

                {/* Expovina */}
                <Route path="/:language/2023/Expovina/Home/" component={ExpovinaHome} />
                <Route path="/:language/2023/Expovina/Address/" component={ExpovinaAddress} />
                <Route path="/:language/2023/Expovina/Checkout/" component={ExpovinaCheckout} />
                <Route path="/:language/2023/Expovina/Confirmation/:orderId" component={ExpovinaConfirmation} />

                {/* -- Page Not Found -- */}
                <Route component={PageNotFound} />
            </Switch>
        );
    }
}
