import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import Reducer from "../../../utilities/Reducer";

import styles from "../css/style.module.css";
import Footer from "./components/Footer";
import Header from "./components/Header";

const Home = () => {
    const [eventId, setEventId] = useState();
    const [orderId, setOrderId] = useState();
    const [loading, setLoading] = useState(true);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [eventInfo, setEventInfo] = useState({});
    const [ticketTypeInfos, setTicketTypeInfos] = useState([]);
    const [ticketTypeNumberOfAvailability, setTicketTypeNumberOfAvailability] =
        useState([]);
    const [selectedTicketTypes, setSelectedTicketTypes] = useState([]);
    const [isNotValid, setIsNotValid] = useState(true);
    const [sum, setSum] = useState(0);
    const [displayMoreInfo, setDisplayMoreInfo] = useState({});

    // react hook for navigation
    let history = useHistory();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching resources
    const { language } = useParams();

    let languageId = 0;

    if (language === "de" || language === "DE") {
        languageId = 1;
    } else if (language === "fr" || language === "FR") {
        languageId = 2;
    } else if (language === "en" || language === "EN") {
        languageId = 3;
    } else if (language === "it" || language === "IT") {
        languageId = 4;
    } else {
        languageId = 0;
    }

    useEffect(() => {
        loadToken();

        let array = [];
        sessionStorage.setItem("AddedPromocodes", JSON.stringify(array));
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = async () => {
        await axios.get("token/get/Expovina").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`expovina2023/formsettings`).then((res) => {
            startOrder(res.data.posId, res.data.eventId);
            setEventId(res.data.eventId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`expovina2023/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId, eventId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("ExpovinaOrderId", res.data.id);

                setOrderId(res.data.id);
                setLoading(true);

                requestEvent(eventId);
                if (ticketTypes.length < 1) requestTicketType(eventId, res.data.id);

                setTimeout(() => setLoading(false), 500);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestEvent = async (id) => {
        await axios
            .get(`${baseUrl}/Event/${id}`)
            .then((res) => {
                sessionStorage.setItem("ExpovinaEvent", JSON.stringify(res.data));

                requestEventInfo(id);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestEventInfo = async (id) => {
        await axios
            .get(`${baseUrl}/Event/${id}/Infos`)
            .then((res) => {
                setEventInfo(
                    res.data.eventInfos.find((infos) => infos.languageId === languageId)
                );
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketType = async (id, orderId) => {
        await axios
            .get(`${baseUrl}/Event/${id}/TicketTypes`)
            .then((res) => {
                let ticketTypesArray = [];

                ticketTypesArray = res.data.ticketTypes;

                setTicketTypes(ticketTypesArray);

                res.data.ticketTypes.map((tt) =>
                    requestTicketTypeNumberOfAvailableTickets(tt.id, orderId)
                );

                res.data.ticketTypes.map((tt) => requestTicketTypeInfo(tt.id));
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketTypeNumberOfAvailableTickets = async (
        ticketTypeId,
        orderId
    ) => {
        await axios
            .get(
                `${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}`
            )
            .then((res) => {
                setTicketTypeNumberOfAvailability((current) => [
                    ...current,
                    {
                        ticketTypeId: ticketTypeId,
                        status: res.data.status,
                        availableTickets: res.data.availableTickets,
                    },
                ]);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketTypeInfo = async (ticketTypeId) => {
        await axios
            .get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`)
            .then((res) => {
                setTicketTypeInfos((current) => [
                    ...current,
                    res.data.ticketTypeInfos.find((tti) => tti.languageId === languageId),
                ]);
            })
            .catch((error) => console.error(error.response.data));
    };

    // const addTicketToBasket = async () => {
    //   await Promise.all(
    //     selectedTicketTypes.map(async (tt) => {
    //       if (tt.quantity > 0) {
    //         await axios
    //           .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
    //             ticketsToAdd: [
    //               {
    //                 ticketTypeId: tt.ticketTypeId,
    //                 quantity: tt.quantity,
    //               },
    //             ],
    //           })
    //           .catch((error) => {
    //             console.error(error.response.data);
    //           });
    //       }
    //     })
    //   ).then(() => {
    //     history.push(`/${language}/2023/expovina/address`);
    //   });
    // };

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                history.push(`/${language}/2023/expovina/address`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onSubmit = async () => {
        await addTicketToBasket();
    };

    const onTicketTypeChange = (e, ticketTypeId) => {
        if (selectedTicketTypes.length < 1) {
            selectedTicketTypes[0] = {
                ticketTypeId: ticketTypeId,
                quantity: e.target.value,
            };
        } else {
            selectedTicketTypes.map((t) => {
                const testSelectedTicketTypes = selectedTicketTypes.filter(
                    (f) => f.ticketTypeId === ticketTypeId
                );

                if (testSelectedTicketTypes.length === 1) {
                    selectedTicketTypes.find(
                        (f) => f.ticketTypeId === ticketTypeId
                    ).quantity = e.target.value;
                } else {
                    selectedTicketTypes[selectedTicketTypes.length] = {
                        ticketTypeId: ticketTypeId,
                        quantity: e.target.value,
                    };
                }
            });
        }

        let totalQuantity = 0;
        let totalSum = 0;

        selectedTicketTypes.forEach((stt) => {
            totalQuantity = totalQuantity + stt.quantity * 1;
        });

        if (totalQuantity > 0) {
            setIsNotValid(false);
        } else {
            setIsNotValid(true);
        }

        selectedTicketTypes.forEach((stt) => {
            totalSum =
                totalSum +
                ticketTypes.find((tt) => tt.id === stt.ticketTypeId).price *
                stt.quantity;
        });

        setSum(totalSum);
    };

    const mapTicketTypes = () => {
        let ticketTypesSorted = [];

        if (ticketTypes && ticketTypes.length > 0) {
            ticketTypesSorted = [...ticketTypes].sort(
                (a, b) => b.price - a.price || b.id - a.id
            );
        }

        return (
            ticketTypes &&
            ticketTypes.length > 0 &&
            ticketTypesSorted.map((tt, index) => (
                <div key={index}>
                    {ticketTypeInfos &&
                        ticketTypeInfos.length > 0 &&
                        ticketTypeInfos.length >= ticketTypesSorted.length &&
                        ticketTypeNumberOfAvailability &&
                        ticketTypeNumberOfAvailability.length >= ticketTypesSorted.length &&
                        ticketTypeNumberOfAvailability.find(
                            (ttnoa) => ttnoa.ticketTypeId === tt.id
                        ) &&
                        ticketTypeNumberOfAvailability.find(
                            (ttnoa) => ttnoa.ticketTypeId === tt.id
                        ).status === 0 && (
                            <div className="row pt-3 pb-3">
                                <div className="col-md-9 col-6">
                                    <label className="fs-6">
                                        {ticketTypeInfos.find(
                                            (tti) => tti.ticketTypeId === tt.id
                                        ) &&
                                            ticketTypeInfos.find((tti) => tti.ticketTypeId === tt.id)
                                                .name}
                                    </label>
                                    <br></br>
                                    <span
                                        className={"m-0 " + styles.description}
                                        onClick={() =>
                                            setDisplayMoreInfo({
                                                [tt.id]: !displayMoreInfo[tt.id],
                                            })
                                        }
                                    >
                                        {resources.translation && resources.translation.MoreInfos}
                                        {displayMoreInfo[tt.id] ? (
                                            <i className="bi bi-chevron-down ms-2"></i>
                                        ) : (
                                            <i className="bi bi-chevron-up ms-2"></i>
                                        )}
                                    </span>
                                    {displayMoreInfo[tt.id] && (
                                        <p className={"mb-0 " + styles.description}>
                                            {ticketTypeInfos.find(
                                                (tti) => tti.ticketTypeId === tt.id
                                            ) &&
                                                ticketTypeInfos.find(
                                                    (tti) => tti.ticketTypeId === tt.id
                                                ).description}
                                        </p>
                                    )}
                                </div>
                                <div className="col-md-2 col-3 text-center">
                                    <label>
                                        {tt.currency} {tt.price}.-
                                    </label>
                                </div>
                                <div className="col-md-1 col-3">
                                    <select
                                        className={"form-select " + styles.customTextbox}
                                        onChange={(e) => onTicketTypeChange(e, tt.id)}
                                    >
                                        {Array.from(
                                            Array(
                                                ticketTypeNumberOfAvailability.find(
                                                    (ttnoa) => ttnoa.ticketTypeId === tt.id
                                                ) &&
                                                ticketTypeNumberOfAvailability.find(
                                                    (ttnoa) => ttnoa.ticketTypeId === tt.id
                                                ).availableTickets + 1
                                            ),
                                            (e, i) => {
                                                return (
                                                    <option key={i} value={i}>
                                                        {i}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </div>
                            </div>
                        )}
                </div>
            ))
        );
    };

    return (
        <div className={"container " + styles.wrapper}>
            {loading ? (
                <Loading
                    alignment="center"
                    color="#e86e82"
                    bgColor="#fff"
                    position="fixed"
                    top="50%"
                />
            ) : (
                <>
                    <Header />
                    {resources.translation && (
                        <>
                            <div className="mb-5">
                                <h1 className="fs-4">{resources.translation.Title}</h1>
                                <p className="fs-6 fw-bold mb-2">
                                    {resources.translation.SubtitleBold}
                                </p>
                                <p className="fs-6 mb-2">{resources.translation.Subtitle}</p>
                                <p>{resources.translation.Subtitle2}<br></br>{resources.translation.Subtitle3}</p>
                            </div>
                            <div>
                                <h1 className="fs-6 mb-2 text-uppercase">
                                    {resources.translation.TicketsBuy}
                                </h1>
                                {mapTicketTypes()}
                            </div>
                            <div className="text-end mb-3">
                                <button
                                    className={styles.button}
                                    onClick={() => onSubmit()}
                                    disabled={isNotValid}
                                >
                                    {resources.translation.Next}
                                </button>
                            </div>
                        </>
                    )}
                    <Footer />
                </>
            )}
        </div>
    );
};

export default Home;
