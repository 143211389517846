import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import styles from "../css/style.module.css";
import Header from "./components/Header";
import Footer from "./components/Footer";

const Step30_Confirmation = () => {
  const [resources, setResources] = useState({});
  const [totalPrice, setTotalPrice] = useState(
    sessionStorage.getItem("totalPrice")
  );
  const [organizerId, setOrganizerId] = useState("");
  const [order, setOrder] = useState({});

  // fetching resources
  const { language } = useParams();

  const { orderId } = useParams();

  // base url
  let baseUrl = process.env.REACT_APP_BASEURL_API;

  useEffect(() => {
    loadToken();
  }, [language]);

  const loadToken = async () => {
    await axios.get("token/get/Expovina").then((res) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

      requestResources();
      requestFormSettings();
    });
  };

  const requestFormSettings = () => {
    axios.get(`expovina2023/formsettings`).then((res) => {
      setOrganizerId(res.data.organizerId);
      confirmOrder();
    });
  };

  const requestResources = () => {
    axios
      .get(`expovina2023/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.error(error.response.data));
  };

  // confirm the order
  const confirmOrder = () => {
    axios
      .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
      .then((res) => {
        setOrder(res.data);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const getReceiptOfOrder = async () => {
    await axios
      .get(`${baseUrl}/Order/${orderId}/Receipt?organizerId=${organizerId}`, {
        responseType: "blob",
      })
      .then((res) => {
        const data = window.URL.createObjectURL(res.data);
        var link = document.createElement("a");
        link.href = data;
        link.download = "Receipt.pdf";
        link.click();
      })
      .catch((error) => console.error(error.response.data));
  };

  const getPdfTicketsOfOrder = async () => {
    await axios
      .get(
        `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const data = window.URL.createObjectURL(res.data);
        var link = document.createElement("a");
        link.href = data;
        link.download = "Tickets.pdf";
        link.click();
      })
      .catch((error) => console.error(error.response.data));
  };

  return (
    <div>
      {resources.translation && (
        <div className={"container pt-0 " + styles.wrapper}>
          <Header />
          <div className={"mt-5"}>
            <div className="mb-3">
              <div className={"mb-4 col-md-12 " + styles.confirmBox}>
                <h1 className="fs-5">{resources.translation.Confirmation1}</h1>
                <p className="mb-0 fs-6 fw-bold">
                  {resources.translation.Confirmation2} {order && order.invoiceNumber && order.invoiceNumber}{" "}
                </p>
                <p className="mb-3 fs-6">
                  {resources.translation.Confirmation3} {order && order.totalPrice && order.totalPrice} {order && order.currency && order.currency}
                </p>
                <p className="mb-3 fs-6">
                  {resources.translation.Confirmation4}
                  <br></br>
                  {resources.translation.Confirmation5}
                </p>
                <p className="mb-3 fs-6">
                  {resources.translation.Confirmation6}
                </p>
              </div>

              <div className="col-md-5 mb-4">
                <button
                  className={"col-md-12 fs-6 " + styles.confirmationButton}
                  onClick={() => getPdfTicketsOfOrder()}
                >
                  {resources.translation.TicketDownload}
                </button>
              </div>

              <div className="col-md-5 mb-4">
                <button
                  className={"col-md-12 fs-6 " + styles.confirmationButton}
                  onClick={() => getReceiptOfOrder()}
                >
                  {resources.translation.ReceiptDownload}
                </button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Step30_Confirmation;
