import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "../css/style.module.css";

const Confirmation = () => {
  const [resources, setResources] = useState({});

  // fetching resources
  const { language } = useParams();

  useEffect(() => {
    requestResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const requestResources = () => {
    axios.get(`wildtrophy2022/resources/${language}`).then((res) => {
      setResources(res.data);
    });
  };

  return (
    <div className={styles.pageContainer + " " + styles.fontFace}>
      <section className={styles.section} id="section">
        <div className={styles.boxMain + " row"}>
          <div className={styles.firstHalf}>
            <div>
              <h1 className="text text-start text-light">
                {resources.translation && resources.translation.ThanksConfirm}
              </h1>
              <p className="text text-start mt-4 text-light">
                {resources.translation &&
                  resources.translation.DescriptionConfirm}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Confirmation;
