import axios from "axios";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { useParams } from "react-router-dom";

import "../css/calendar.css";
import styles from "../css/style.module.css";

import Footer from "./components/Footer";
import logo from "../images/logo.png";

const Home = () => {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(new Date());
  const [currentDate] = useState(new Date());
  const [showTime, setShowTime] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventInfo, setEventInfo] = useState([]);
  const [ticketsSold, setTicketsSold] = useState([]);

  // fetching resources
  const [resources, setResources] = useState({});

  const { language } = useParams();

  let baseUrl = process.env.REACT_APP_BASEURL_API;
  let baseUrlShop = process.env.REACT_APP_BASEURL_SHOP;

  useEffect(() => {
    requestResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const requestResources = () => {
    axios
      .get(`turicumginlab2022/resources/${language}`)
      .then((res) => {
        setResources(res.data);
      })
      .catch((error) => console.error(error.response.data));
  };

  useEffect(() => {
    loadToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadToken = () => {
    axios.get("token/get/Turicumginlab").then((res) => {
      sessionStorage.setItem("token", res.data);
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

      requestFormSettings();
    });
  };

  const requestFormSettings = () => {
    axios.get(`turicumginlab2022/formsettings`).then((res) => {
      startOrder(res.data.posId);
      requestEvents(res.data.organizerId);
    });
  };

  // starting the order
  const startOrder = (posId) => {
    let orderBody = {
      affiliateShopId: null,
      currency: "CHF",
      tenantId: 1,
      pointOfSaleId: posId,
      abbreviation: "",
    };

    axios
      .post(`${baseUrl}/ShopBasket/Order`, orderBody)
      .then((res) => {
        sessionStorage.setItem("orderId", res.data.id);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  // fetching the events (to display it ont he sidebar)
  const requestEvents = (id) => {
    // get current date
    let date = new Date().toISOString().substring(0, 10);

    axios
      .get(`${baseUrl}/Events?organizerId=${id}&filterText=&fromDate=${date}`)
      .then((res) => {
        setEvents(res.data.events);

        if (eventInfo.length < 1) {
          res.data.events.map((event) => requestEventInfo(event.id));
        }
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const requestEventInfo = (id) => {
    axios
      .get(`${baseUrl}/Event/${id}/Infos`)
      .then((res) => {
        setEventInfo((current) => [...current, res.data.eventInfos[0]]);
        requestTicketsSold(id);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const requestTicketsSold = (eventId) => {
    axios
      .get(`${baseUrl}/Event/${eventId}/TicketsSold`)
      .then((res) => {
        let obj = {
          id: eventId,
          ticketsSold: res.data,
        };

        setTicketsSold((current) => [...current, obj]);
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };

  const onClickTime = (e) => {
    let eventTicketsSold = 0;
    let eventInfoName = "";

    if (eventInfo) {
      eventInfoName = eventInfo.find((info) => info.eventId === e.id).name;
    }

    if (ticketsSold.length > 0) {
      eventTicketsSold = ticketsSold.find(
        (sold) => sold.id === e.id
      ).ticketsSold;
    }

    return (
      <div className="text-center">
        <p className="text text-light fs-6 fw-bold mb-0">
          {e.openDoor.substring(11, 16)}
        </p>
        {e.maxTickets - eventTicketsSold !== 0 ? (
          <p className="text text-light fs-6 mb-0">
            {e.maxTickets - eventTicketsSold}{" "}
            {resources.translation && resources.translation.Of} {e.maxTickets}{" "}
            {resources.translation && resources.translation.TicketsAvailable}
          </p>
        ) : (
          <p className="text text-light fs-6 mb-0">
            {resources.translation && resources.translation.SoldOut}
          </p>
        )}

        <a
          className={styles.buttonSidebar + " btn"}
          href={`${baseUrlShop}/de/Event/${eventInfoName}/${e.id}`}
        >
          <h1 className={styles.sidebarName + " text text-light m-0"}>
            {resources.translation && resources.translation.Register}
          </h1>
        </a>
      </div>
    );
  };

  const showSidebar = () => {
    setShow(true);
    setShowTime(true);
  };

  // sidebar components (sidebar, times)
  const sidebar = (date) => {
    return (
      <nav className={styles.sidebar + " sidebar-dark p-3"}>
        <ul className={styles.sideList}>
          <button
            className={styles.sidebarButton}
            onClick={() => setShow(false)}
          >
            ×
          </button>
          <li>
            <p className="text text-light m-1 fs-1 fw-bold">{date}</p>
            <p className="text text-light m-1 fw-bold fs-1"></p>
            <hr className={styles.sidehr}></hr>
          </li>
          <li className="timeButton">
            <div className="container">{showTime && times(date)}</div>
          </li>
        </ul>
      </nav>
    );
  };

  const times = (date) => {
    return (
      events.length &&
      events.map((e, index) => (
        <div key={index}>
          {e.openDoor.substring(0, 10).includes(date) && onClickTime(e)}
        </div>
      ))
    );
  };

  date.setDate(date.getDate() + 1);
  let sidebarDate = date.toISOString().substring(0, 10);

  return (
    <div className={styles.pageContainer}>
      {show && sidebar(sidebarDate)}
      <div className={styles.container + " container"}>
        <div className={styles.flexContainer + " m-3"}>
          <div className="mb-4">
            <div className="text-center mb-5">
              <a href="https://www.turicum-distillery.com/gin-lab">
                <img
                  className={styles.logo + " text-center"}
                  src={logo}
                  alt="Turicum Gin Lab logo"
                ></img>
              </a>
            </div>
            <h2 className="text text-light fw-bold fs-2 text-center">
              {resources.translation && resources.translation.Title}
            </h2>
          </div>
          <div className={styles.calendarContainer + " mb-5"}>
            <Calendar
              minDate={currentDate}
              value={date}
              onChange={setDate}
              onClickDay={showSidebar}
            />
          </div>
          <div className="mt-5 mb-5">
            <h2 className="text text-light fs-6 text-center">
              {resources.translation && resources.translation.Description}
            </h2>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
